import {
	Box,
	Text,
	TableContainer,
	Table,
	Thead,
	Tr,
	Th,
	Tbody,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import getBrands from '../../api/getBrands';
import SingleBrand from './SingleBrand';
const Brands = () => {
	const [brands, setBrands] = useState([]);
	const [state, setState] = useState(false);

	const getData = async () => {
		try {
			const res = await getBrands();
			setBrands(res.data.brandRequests);
		} catch (error) {}
	};

	useEffect(() => {
		getData();
	}, [state]);

	return (
		<>
			<Box
				// w={{ base: '100%', lg: '86vw' }}
				h={'89vh'}
				bg='white'
				mx={{ base: 1, lg: '10px' }}
				borderRadius='md'
				boxShadow={'rgba(0, 0, 0, 0.24) 0px 3px 8px'}
				overflowX='hidden'
				overflowY='scroll'
			>
				{/* <Text
					p={4}
					textAlign={'center'}
					fontSize={25}
					fontWeight={600}
					bg='#32bac9'
					color='white'
				>
					Agents
				</Text> */}
				<TableContainer>
					<Table variant={'striped'} overflow='scroll' mt={3}>
						<Thead>
							<Tr>
								<Th textAlign={'center'} fontSize={'1.1rem'}>
									First Name
								</Th>
								<Th textAlign={'center'} fontSize={'1.1rem'}>
									Last Name
								</Th>
								<Th textAlign={'center'} fontSize={'1.1rem'}>
									Email
								</Th>
								<Th textAlign={'center'} fontSize={'1.1rem'}>
									Mobile no.
								</Th>
								<Th textAlign={'center'} fontSize={'1.1rem'}>
									Company name
								</Th>
								<Th textAlign={'center'} fontSize={'1.1rem'}>
									Company Website
								</Th>
								<Th textAlign={'center'} fontSize={'1.1rem'}>
									Business Type
								</Th>
								<Th
									textAlign={'center'}
									fontSize={'1.1rem'}
								></Th>
								<Th
									textAlign={'center'}
									fontSize={'1.1rem'}
								></Th>
							</Tr>
						</Thead>
						<Tbody>
							{brands.map((data, index) => {
								return (
									<SingleBrand
										data={data}
										key={index}
										changeState={setState}
									/>
								);
							})}
						</Tbody>
					</Table>
				</TableContainer>
			</Box>
		</>
	);
};
export default Brands;
