import {
	Box,
	Table,
	TableContainer,
	Tbody,
	Th,
	Thead,
	Tr,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import GetAllUsers from '../../api/GetAllUsers';
import SingleUser from './SingleUser';

const Users = () => {
	const [userList, setUserList] = useState([]);
	const [reload, setReload] = useState(true);

	useEffect(() => {
		const getUsers = async () => {
			const users = async () => {
				try {
					const response = await GetAllUsers();
					setUserList(response.data.users);
					setReload(false);
				} catch (error) {
					console.log('error is ', error);
				}
			};
			users();
		};

		getUsers();
	}, [reload]);

	return (
		<>
			<Box
				// w={{ base: '100%', lg: '86vw' }}
				h={'89vh'}
				bg='white'
				mx={{ base: 1, lg: '10px' }}
				borderRadius='md'
				boxShadow={'rgba(0, 0, 0, 0.24) 0px 3px 8px'}
				overflowX='hidden'
				overflowY='scroll'
			>
				{/* <Text
					p={4}
					textAlign={'center'}
					fontSize={25}
					fontWeight={600}
					bg='#32bac9'
					color='white'
				>
					Users
				</Text> */}
				<TableContainer>
					<Table variant={'striped'} overflow='scroll' mt={3}>
						<Thead>
							<Tr>
								<Th textAlign={'center'} fontSize={'1.1rem'}>
									Name
								</Th>
								<Th textAlign={'center'} fontSize={'1.1rem'}>
									Email
								</Th>
								<Th
									textAlign={'center'}
									fontSize={'1.1rem'}
								></Th>
							</Tr>
						</Thead>
						<Tbody>
							{userList.map((data, index) => {
								return <SingleUser data={data} key={index} setReload={setReload} />;
							})}
						</Tbody>
					</Table>
				</TableContainer>
			</Box>
		</>
	);
};
export default Users;
