import React, { useContext, useEffect, useState } from 'react';
import getRefereshToken from '../api/getRefreshToken';

const AuthContext = React.createContext();

const AuthContextProvider = ({ children }) => {
	const [loginStatus, setLoginStatus] = useState(
		localStorage.getItem('loginStatus') === 'true' ? true : false
	);
	const [token, setToken] = useState(localStorage.getItem('token'));

	useEffect(() => {
		// const refresh = async () => {
		// 	try {
		// 		const res = await getRefereshToken(
		// 			localStorage.getItem('token')
		// 		);
		// 		console.log(res);
		// 	} catch (error) {
		// 		console.log('this is what happned : ', error);
		// 	}
		// };
		// refresh();
	}, []);

	// context values
	const value = {
		loginStatus: loginStatus,
		token: token,
		setToken: (val) => {
			setToken(val);
			localStorage.setItem('token', val);
		},
		setLoginStatus: (val) => {
			setLoginStatus((prev) => val);
			localStorage.setItem('loginStatus', true);
		},
	};

	return (
		<AuthContext.Provider value={value}>{children}</AuthContext.Provider>
	);
};

const AccessAuthContext = () => {
	return useContext(AuthContext);
};

export { AuthContext, AuthContextProvider, AccessAuthContext };
