import { CheckIcon, DeleteIcon, Icon } from "@chakra-ui/icons";
import {
	Td,
	Tr,
	Input,
	AlertDialog,
	useDisclosure,
	AlertDialogOverlay,
	AlertDialogContent,
	AlertDialogHeader,
	AlertDialogBody,
	AlertDialogFooter,
	Button,
	useToast,
	Spinner,
} from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import { FiEdit } from "react-icons/fi";
import { AiFillSave } from "react-icons/ai";
import { BsFillFileEarmarkPdfFill } from "react-icons/bs";
import RequestsEditModal from "./RequestsEditModal";
import GetUserInfo from "../../api/GetUserInfo";
import DeletePackageRequest from "../../api/DeletePackageRequest";
import PdfPage from "./PdfPage";
import axios from "axios";
import { uploadToCloud } from "../../api/uploadToCloud";
import GetPackageById from "../../api/GetPackageById";

const RequestsTableData = ({ data, changeState }) => {
	console.log("in table row : ", data);
	const [isEditing, setIsEditing] = useState(false);
	const [conform, setConform] = useState(
		data.paymentStatus.status === "Confirmed" ? true : false
	);
	const [openEditModal, setOpenEditModal] = useState(false);
	const cancleRef = useRef();
	const [name, setName] = useState("");
	const [email, setEmail] = useState("");
	const [phone, setPhone] = useState("");
	const [loading, setLoading] = useState(false);
	const [pdf, setPdf] = useState(data.pdf === undefined ? "" : data.pdf);
	const toast = useToast();
	const [should, setShould] = useState(false);
	const [pdfLoading, setPdfLoading] = useState(false);
	const [price, setPrice] = useState(null);
	const [resorts, setResorts] = useState(null);
	const [inclusion, setInclusion] = useState(null);

	const { isOpen, onOpen, onClose } = useDisclosure();
	const [package_data, set_package_data] = useState(null);

	useEffect(() => {
		const getUserDetails = async () => {
			try {
				const response = await GetUserInfo(data.RequestedBy);
				setName(response.data.message.name);
				setEmail(response.data.message.email);
				setPhone(response.data.message.phone);
				const packageData = await GetPackageById(data.packageId);
				set_package_data(packageData.data);
				setPrice(
					packageData.data.package[0] === undefined
						? ""
						: packageData.data.package[0].startingPrice *
								parseInt(data.numberOfPeople)
				);
			} catch (error) {
				console.log("error occured ", error);
			}
		};
		if (data?.name) {
			setName(data.name);
			setEmail(data.requestEmail);
			setPhone(data.phoneNumber);
		} else {
			getUserDetails();
		}
	}, []);

	const handleDeleteRequest = async () => {
		setLoading(true);
		try {
			const res = await DeletePackageRequest(data._id);
			console.log("Delete => ", res);
			setLoading(false);
			changeState((prev) => !prev);
			onClose();
		} catch (error) {
			setLoading(false);
		}
	};

	const setPaymentStatusToConformed = async (value) => {
		console.log("value of pdf in the function is : ", value);

		const urlVal = await uploadToCloud(value);

		console.log("pdf url is ", urlVal);
		setPdf(urlVal[0]);
		try {
			const res = await axios.patch(
				"https://backend.planmyleisure.com/package/update-request-package",
				{
					packageId: data._id,
					paymentStatus: { status: "Confirmed" },
					pdf: urlVal[0],
				}
			);
			console.log("response is : ", res);
			setPdfLoading(false);
		} catch (error) {
			console.log("error is : ", error);
			setPdfLoading(false);
		}
	};

	return (
		<>
			{conform ? (
				data.pdf === "" || data.pdf === undefined ? (
					<PdfPage
						data={{
							name: name,
							packageId: data.packageId,
							numberOfPeople: data.numberOfPeople,
							requestId: data._id,
							requestedBy: data.RequestedBy,
							flightDetails: data.flightDetails,
							itinerary: data.itinerary,
							location: data.location,
							mealPlan: data.mealPlan,
							price: price,
							resort: data.resorts,
							roomType: data.roomType,
							transfers: data.transfers,
							updatedAt: data.updatedAt,
							packageData: package_data,
							startDate: data.startDate,
							endDate: data.endDate,
							complimentary: data.complimentory,
							activities: data.activities,
						}}
						setPdf={setPaymentStatusToConformed}
						should={should}
						setPdfLoading={setPdfLoading}
					/>
				) : (
					<></>
				)
			) : (
				<></>
			)}
			{/* {console.log('data for this is : ', data.RequestedBy)} */}
			{/* delete modal */}
			<AlertDialog
				isOpen={isOpen}
				onClose={onClose}
				leastDestructiveRef={cancleRef}
			>
				<AlertDialogOverlay>
					<AlertDialogContent>
						<AlertDialogHeader fontSize={"lg"} fontWeight={"bold"}>
							Delete Request
						</AlertDialogHeader>
						<AlertDialogBody>
							Are you sure? You can't undo this action afterwards.
						</AlertDialogBody>
						<AlertDialogFooter>
							<Button ref={cancleRef} onClick={onClose}>
								Cancel
							</Button>
							<Button
								colorScheme="red"
								isLoading={loading}
								onClick={handleDeleteRequest}
								ml={3}
							>
								Delete
							</Button>
						</AlertDialogFooter>
					</AlertDialogContent>
				</AlertDialogOverlay>
			</AlertDialog>
			<RequestsEditModal
				state={openEditModal}
				changeState={setOpenEditModal}
				data={data}
				addResorts={setResorts}
				addInclusion={setInclusion}
				reFetch={changeState}
			/>
			<Tr>
				<Td textAlign={"center"}> {name === "" ? "-" : name} </Td>
				<Td textAlign={"center"}> {email === "" ? "-" : email}</Td>
				<Td textAlign={"center"}> {phone === "" ? "-" : phone}</Td>
				<Td textAlign={"center"}>{data.packageId ? data.packageId : "-"}</Td>

				<Td textAlign={"center"}>
					{data.paymentType.parts.length === 0
						? "-"
						: data.paymentType.parts
								.filter((data) => {
									if (data.status === true) {
										return true;
									} else {
										return false;
									}
								})
								.map((data) => data.amount)
								.join(", ")}
				</Td>
				<Td textAlign={"center"}>
					{isEditing ? (
						<Input
							type="number"
							border="none"
							borderBottom={"2px solid gray"}
							borderRadius={0}
							_focus={{
								border: "none",
								borderBottom: "2px solid gray",
							}}
						/>
					) : (
						<></>
					)}
				</Td>
				{/* pdf icons column */}
				<Td textAlign={"center"}>
					{conform ? (
						pdfLoading ? (
							<Spinner />
						) : (
							<a href={pdf} download={true} target="blank">
								<Icon
									h="23px"
									w="23px"
									cursor={"pointer"}
									as={BsFillFileEarmarkPdfFill}
									color="#f00"
									onClick={() => {
										// setConform(false);
									}}
								/>
							</a>
						)
					) : (
						<CheckIcon
							h="25px"
							w="25px"
							cursor="pointer"
							p="5px"
							borderRadius={"5px"}
							bg="green.600"
							color="white"
							onClick={() => {
								setPdfLoading(true);
								setConform(true);
								setShould(true);
								setPaymentStatusToConformed();
							}}
						/>
					)}
				</Td>
				<Td textAlign={"center"}>
					{isEditing ? (
						<Icon
							as={AiFillSave}
							h="23px"
							w="23px"
							color="green"
							cursor={"pointer"}
							onClick={() => {
								setIsEditing(false);
							}}
						/>
					) : (
						<Icon
							as={FiEdit}
							color="gray.600"
							cursor={"pointer"}
							onClick={() => {
								// setIsEditing(true);
								setOpenEditModal(true);
							}}
						/>
					)}
				</Td>
				<Td textAlign={"center"}>
					<DeleteIcon
						cursor={"pointer"}
						color="red"
						onClick={() => {
							onOpen();
						}}
					/>
				</Td>
			</Tr>
		</>
	);
};

export default RequestsTableData;
