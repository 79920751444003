import axios from 'axios';

const getBrands = () => {
	const token = localStorage.getItem('token');
	return axios.get(
		'https://backend.planmyleisure.com/brand/brand-requests',
		{
			headers: { Authorization: token },
		}
	);
};

export default getBrands;
