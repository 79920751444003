import { AddIcon, CloseIcon, Icon } from '@chakra-ui/icons';
import { Box, Button, Image, Input, Text } from '@chakra-ui/react';
import { useEffect, useRef, useState } from 'react';
import { BsFillPlayFill } from 'react-icons/bs';
import getBannerApi from '../../api/banner/getBannerApi';
import updateBannerApi from '../../api/banner/updateBannerApi';

const BannerImage = () => {
	const inputRef = useRef();
	const [bannerImage, setBannerImage] = useState(null);
	const [quote, setQuote] = useState(null);
	const [changed_quote, set_changed_quote] = useState(null);
	const [image_present, set_image_present] = useState(true);
	const [changed_image, set_changed_image] = useState(null);
	const [imageObject, setImageObject] = useState(null);
	const [is_something_changed, set_is_something_changed] = useState(false);
	const [loading, setLoading] = useState(false);
	const [id, setId] = useState(null);
	const [is_image_changed, set_is_image_changed] = useState(false);
	const [type, set_type] = useState(null);

	useEffect(() => {}, []);

	const handleClick = () => {
		inputRef.current.click();
	};

	const handleImageSelect = (e) => {
		set_type(
			e.target.files[0].type.split('/')[0] === 'video' ? 'video' : 'image'
		);
		setImageObject((prev) => e.target.files[0]);
		// set_changed_image((prev) => URL.createObjectURL(e.target.files[0]));
		set_changed_image((prev) => {
			return {
				url: URL.createObjectURL(e.target.files[0]),
				resource_type: e.target.files[0].type.split('/')[0],
			};
		});
		set_image_present(true);
	};

	useEffect(() => {
		const getData = async () => {
			const res = await getBannerApi();
			console.log('res for banner : ', res.data.bannerImages[0]);
			setQuote(res.data.bannerImages[0].quote);
			setBannerImage(res.data.bannerImages[0].imageUrl);
			set_changed_image(res.data.bannerImages[0].imageUrl);
			set_changed_quote(res.data.bannerImages[0].quote);
			setId(res.data.bannerImages[0]._id);
			set_type(
				res.data.bannerImages[0].imageUrl.resource_type === 'video'
					? 'video'
					: 'image'
			);
		};

		getData();
	}, []);

	const handleImageRemove = () => {
		set_is_something_changed(true);
		set_is_image_changed(true);
		set_image_present(false);
	};

	const handleCancel = () => {
		set_changed_quote(quote);
		set_image_present(true);
		set_changed_image(bannerImage);
		set_is_something_changed(false);
		set_is_image_changed(false);
	};

	const createImagelink = async () => {
		try {
			const formData = new FormData();
			formData.append('file', imageObject);
			formData.append(
				'upload_preset',
				imageObject.type.split('/')[0] === 'video'
					? process.env.REACT_APP_CLOUD_PRESET_VIDEO
					: process.env.REACT_APP_CLOUD_PRESET_IMAGE
			);
			formData.append('cloud_name', 'dqimeuotl');
			formData.append('manifest_transformation', 'q_auto:eco');

			let response = await fetch(
				`https://api.cloudinary.com/v1_1/${process.env.REACT_APP_CLOUD_NAME}/upload?tx=q_auto:eco`,
				{ method: 'post', body: formData }
			);

			response = await response.json();
			return response;
		} catch (error) {
			console.log('error occurred ', error);
		}
	};

	const handleUpdate = async () => {
		setLoading(true);

		try {
			if (is_image_changed === true) {
				const imagelink = await createImagelink();
				const res = await updateBannerApi(id, changed_quote, imagelink);
				console.log('with image response is : ', res);
				set_is_something_changed(false);
			} else {
				// console.log('image is not changed');
				const res = await updateBannerApi(
					id,
					changed_quote,
					changed_image
				);
				console.log('response is : ', res);
				set_is_something_changed(false);
			}

			setLoading(false);
		} catch (error) {
			setLoading(false);
		}
	};

	return (
		<>
			<Box
				h={'calc(89vh - 50px)'}
				bg='white'
				borderRadius='0 8px 8px 8px'
				overflowY='scroll'
				pl={'30px'}
				pr={5}
				pt={'100px'}
			>
				<Box>
					<Text
						fontSize={'20px'}
						fontWeight={600}
						letterSpacing={1.1}
					>
						Image
					</Text>
					{image_present ? (
						<Box
							display={'inline-block'}
							position='relative'
							zIndex={2}
							mt='10px'
						>
							{type === 'video' ? (
								<Box height={'200px'} position={'relative'}>
									<Icon
										as={BsFillPlayFill}
										position='absolute'
										fontSize={'60px'}
										top='50%'
										left='50%'
										transform={'translate(-50%,-50%)'}
										fill='rgba(0,0,0,.5)'
									/>
									<video
										style={{
											height: '100%',
											width: '100%',
											objectFit: 'cover',
										}}
									>
										<source src={changed_image.url} />
									</video>
								</Box>
							) : (
								<Image
									src={
										changed_image === null
											? ''
											: changed_image.url === undefined
											? changed_image
											: changed_image.url
									}
									height='200px'
								/>
							)}
							<Box
								position={'absolute'}
								top='0'
								right={0}
								zIndex={3}
								bg='lightblue'
								transform={'translate(50%,-50%)'}
								borderRadius='full'
								h='25px'
								w='25px'
								display={'flex'}
								justifyContent='center'
								alignItems={'center'}
								onClick={handleImageRemove}
								cursor='pointer'
							>
								<CloseIcon
									color='rgba(0,0,0,.5)'
									fontSize={10}
								/>
							</Box>
						</Box>
					) : (
						<Box
							display={'inline-flex'}
							alignItems='center'
							gap='10px'
							bg='lightblue'
							px='15px'
							py='5px'
							borderRadius={'5px'}
							mt='25px'
							cursor='pointer'
							onClick={handleClick}
						>
							<AddIcon color='rgba(0,0,0,.5)' />
							<Text color='rgba(0,0,0,.5)'>Add Image</Text>
							<Input
								type='file'
								accept='image/*, video/*'
								display={'none'}
								ref={inputRef}
								onChange={handleImageSelect}
							/>
						</Box>
					)}
				</Box>
				<Box mt='30px'>
					<Text
						fontSize={'20px'}
						fontWeight={600}
						letterSpacing={1.1}
					>
						Quote
					</Text>
					<Input
						mt='10px'
						w='60%'
						type='texts'
						value={changed_quote}
						onChange={(e) => {
							set_is_something_changed(true);
							set_changed_quote(e.target.value);
						}}
					/>
				</Box>
				<Box
					mt='50px'
					display={is_something_changed ? 'flex' : 'none'}
					justifyContent='space-between'
					px='50px'
				>
					<Button onClick={handleCancel}>Cancel</Button>
					<Button onClick={handleUpdate} isLoading={loading}>
						Update
					</Button>
				</Box>
			</Box>
		</>
	);
};

export default BannerImage;
