import axios from 'axios';

const UpdateStatusApi = (id, status) => {
	return axios.patch(
		'https://backend.planmyleisure.com/lead/update-status',
		{
			id,
			status,
		}
	);
};

export default UpdateStatusApi;
