import {
	Box,
	Button,
	Icon,
	Image,
	ListItem,
	Table,
	Tbody,
	Td,
	Text,
	Th,
	Thead,
	Tr,
	UnorderedList,
} from "@chakra-ui/react";
import html2pdf from "html2pdf.js";
// import parse from "html-react-parser";
import { useEffect, useRef, useState } from "react";
import logo from "../../logo.png";
import heroImg from "../../assets/images/banner.jpeg";
import { StarIcon } from "@chakra-ui/icons";
import { BsCalendarWeek } from "react-icons/bs";
import { RiHotelFill } from "react-icons/ri";
import { MdPeopleAlt } from "react-icons/md";
import img2 from "../../assets/images/header2.jpeg";
import GetPackageById from "../../api/GetPackageById";
import { uploadToCloud } from "../../api/uploadToCloud";
import axios from "axios";

const PdfPage = ({ data, setPdf, should, setPdfLoading }) => {
	console.log("in pdf page we got this : ", data);

	const ref = useRef();
	const [dLink, setDLink] = useState("");
	const [resort, setResort] = useState("");
	const [price, setPrice] = useState();
	console.log(data.startDate, data.endDate);

	const s_date = new Date(data.startDate);
	const e_date = new Date(data.endDate);
	const timeDiff = Math.abs(e_date.getTime() - s_date.getTime());
	let number_of_nights = Math.ceil(timeDiff / (1000 * 3600 * 24));

	const getAllRequiredData = async () => {
		try {
			const res = await GetPackageById(data.packageId);
			console.log("resssss is ", res);
			// setResort(res.data.package.resorts[0]);
			setPrice(res.data.package.startingPrice);
			genpdf();
		} catch (error) {
			console.log("error is ", error);
		}
	};

	const getPackageRequestData = async () => {
		try {
			console.log("requested by :  ", data.requestedBy);
			const res = await axios.get(
				`https://backend.planmyleisure.com/package/get-package-request-by-user/${data.requestedBy}`
			);
			// console.log('res is for it is : ', res);
		} catch (error) {
			console.log("error is ", error);
		}
	};

	useEffect(() => {
		if (should === true) {
			console.log("called genpdf");
			getAllRequiredData();
			getPackageRequestData();
		}
	}, [should]);

	const genpdf = async () => {
		const worker = await html2pdf()
			.from(ref.current)
			.toPdf()
			.output("blob")
			.then((data) => {
				// setDLink(val);
				// setPdf(val);
				return data;
			});

		console.log("worker value is : ", worker);
		setPdf(worker);
	};

	const width = "795px";
	const height = "1123px";
	const h1 = "35px";
	const h2 = "24px";
	const p_small = "14px";
	const p_medium = "16px";
	const bg_color = "rgba(14, 135, 246, 0.25)";
	const text_color = "rgb(9, 8, 65)"; // dark blue
	const normal_text_color = "gray.500"; // for common text

	return (
		<Box>
			<Box display="none">
				<Box ref={ref} width={width}>
					{/* hero */}
					{/* page - 1 */}
					<Box
						h={height}
						borderBottom="1px solid black"
						position={"relative"}
						bg={bg_color}
					>
						<Box
							height={"300px"}
							bgImage={heroImg}
							bgSize="cover"
							bgPos="50% 50%"
						>
							<Box
								h="100%"
								w="100%"
								bg="linear-gradient(rgba(0,0,0,.3) 30px, transparent )"
								position={"relative"}
							>
								<Image
									src={logo}
									h="150px"
									w="300px"
									position={"absolute"}
									top="30px"
									left="50%"
									transform={"translateX(-50%)"}
								/>
							</Box>
						</Box>
						{/* section 2 */}
						<Box
							px="70px"
							pt="50px"
							// bg='rgba(14, 135, 246, 0.25)'
							pb="90px"
						>
							<Text fontSize={h2} fontWeight={400}>
								{data.name}
							</Text>
							<Text fontSize={h1} fontWeight={600} color={text_color}>
								{data?.packageData
									? data.packageData === null
										? ""
										: data.packageData.package[0]?.packageTitle
									: ""}
							</Text>
							<Box
								display={"flex"}
								alignItems="flex-end"
								gap="5px"
								color={normal_text_color}
								fontSize={p_small}
								position="relative"
								h="30px"
							>
								<Text
									display={"inline-block"}
									position="relative"
									bottom={"3px"}
								>
									{data.packageData === null
										? ""
										: data.packageData.package[0].destination}
									,{" "}
								</Text>
								<Text
									display={"inline-block"}
									position="relative"
									bottom={"3px"}
								>
									{data.packageData === null ||
									data.packageData.package[0] === undefined
										? ""
										: data.packageData.package[0].star}
								</Text>
								<StarIcon
									display={"inline-block"}
									zIndex={1000}
									fontSize={p_small}
								/>
							</Box>
							<Box mt="30px">
								<Box display={"flex"} alignItems="center" gap="10px">
									<Icon
										as={MdPeopleAlt}
										fontSize={p_medium}
										color={normal_text_color}
									/>
									<Text fontSize={p_medium} position="relative" bottom="8px">
										{data.numberOfPeople} People
									</Text>
								</Box>
								<Box display={"flex"} alignItems="center" gap="10px" pt="5px">
									<Icon
										as={RiHotelFill}
										fontSize={p_medium}
										color={normal_text_color}
									/>
									<Text fontSize={p_medium} position="relative" bottom="8px">
										{data.resort[0] === undefined
											? ""
											: data.resort[0].values.length === 1
											? data.resort[0].values
											: data.resort[0].values.join(" & ")}
									</Text>
								</Box>
								<Box display={"flex"} alignItems="center" gap="10px" pt="5px">
									<Icon
										as={BsCalendarWeek}
										fontSize={p_medium}
										color={normal_text_color}
									/>
									<Text fontSize={p_medium} position="relative" bottom="8px">
										{/* Departure - Outside India, Jul 04,2022 */}
										{data.flightDetails[0]}
									</Text>
								</Box>
								{/* line */}
								<Box w="100%" h="2px" bg={text_color} mt="50px"></Box>
								<Box
									mt="50px"
									display={"flex"}
									justifyContent="space-between"
									alignItems={"center"}
								>
									<Box>
										<Text fontSize={h2} fontWeight={400}>
											Total cost including taxes
										</Text>
										<Text fontSize={h1} fontWeight={600} color={text_color}>
											₹ {parseInt(data.price) * parseInt(data.numberOfPeople)}
										</Text>
									</Box>
									<Box
										px="20px"
										py="10px"
										borderRadius={"10px"}
										bg={bg_color}
										display={"inline-block"}
										color={text_color}
										textAlign={"center"}
										fontSize={p_medium}
									>
										<Text position="relative" bottom="8px">
											QUOTED ON
										</Text>
										<Text position="relative" bottom="8px">
											{`${data.updatedAt.split("T")[0].split("-")[2]}/${
												data.updatedAt.split("T")[0].split("-")[1]
											}/${data.updatedAt.split("T")[0].split("-")[0]}`}
										</Text>
									</Box>
								</Box>
							</Box>
						</Box>
						<Box
							position={"absolute"}
							h="fit-content"
							bottom={0}
							left={0}
							right={0}
							display={"flex"}
							justifyContent="center"
							alignItems={"center"}
						>
							<Image src={logo} h="100px" />
						</Box>
					</Box>
					{/* page - 1 ends  */}
					{/* page - 2 */}
					<Box bg={bg_color} h={height} overflow="hidden" position={"relative"}>
						<Box
							h="300px"
							w="100%"
							bgImage={img2}
							bgSize="cover"
							bgPos={"50% 50%"}
						></Box>
						<Box px="70px" pt="50px">
							<Box
								display={"flex"}
								justifyContent="space-between"
								alignItems={"center"}
							>
								<Text
									fontSize={h1}
									fontWeight={600}
									position="relative"
									bottom="8px"
								>
									Trip Inclusion
								</Text>
								<Text
									fontSize={p_medium}
									fontWeight={500}
									color={text_color}
									bg={bg_color}
									px="20px"
									pt="0px"
									pb="10px"
									borderRadius={"10px"}
									position="relative"
									bottom={"-8px"}
								>
									{data.numberOfPeople} people,
									{Math.ceil(data.numberOfPeople / 2)} Room,
									{number_of_nights}
									nights
								</Text>
							</Box>
							{/* room type */}
							<Box w="100%" borderRadius="15px" bg="white" mt="50px">
								<Table>
									<Thead>
										<Tr>
											<Th fontSize={p_medium} h="50px">
												Room type
											</Th>
											<Th fontSize={p_medium} textAlign="center" h="50px">
												Check-in
											</Th>
											<Th fontSize={p_medium} textAlign="center" h="50px">
												Check-out
											</Th>
										</Tr>
									</Thead>
									<Tbody>
										<Tr>
											<Td fontSize={p_small} position="relative" bottom="8px">
												{data.roomType}
											</Td>
											<Td
												position="relative"
												bottom="8px"
												fontSize={p_small}
												textAlign="center"
											>
												{data.startDate}
											</Td>
											<Td
												position="relative"
												bottom="8px"
												fontSize={p_small}
												textAlign="center"
											>
												{data.endDate}
											</Td>
										</Tr>
									</Tbody>
								</Table>
							</Box>
							<Box w="100%" borderRadius="15px" bg="white" mt="50px">
								<Table>
									<Thead>
										<Tr>
											<Th fontSize={p_medium} h="50px">
												Meal Plan
											</Th>
										</Tr>
									</Thead>
									<Tbody>
										<Tr>
											<Td fontSize={p_small} position="relative" bottom="8px">
												{data.mealPlan}
											</Td>
										</Tr>
									</Tbody>
								</Table>
							</Box>

							<Box w="100%" borderRadius="15px" bg="white" mt="50px">
								<Table>
									<Thead>
										<Tr>
											<Th fontSize={p_medium} h="50px">
												Transfer
											</Th>
										</Tr>
									</Thead>
									<Tbody>
										<Tr>
											<Td fontSize={p_small} position="relative" bottom="8px">
												{data.transfers}
											</Td>
										</Tr>
									</Tbody>
								</Table>
							</Box>

							<Text
								textAlign={"center"}
								mt="50px"
								fontSize={h2}
								color={text_color}
							>
								Please ensure your international flight arrives in Malé <br />{" "}
								on or before 15:00
							</Text>
							<Box
								position={"absolute"}
								h="fit-content"
								bottom={0}
								left={0}
								right={0}
								display={"flex"}
								justifyContent="center"
								alignItems={"center"}
							>
								<Image src={logo} h="100px" />
							</Box>
						</Box>
					</Box>
					{/* page - 2 ends */}
					{/* <Box h='200px' bg='black'></Box> */}
					{/* page - 3 */}
					<Box
						px="70px"
						pt="50px"
						bg={bg_color}
						h={height}
						overflow="hidden"
						position={"relative"}
					>
						<Box w="100%" borderRadius="15px" bg="white" mt="50px">
							<Table>
								<Thead>
									<Tr>
										<Th fontSize={p_medium} h="50px">
											Itinerary
										</Th>
									</Tr>
								</Thead>
								<Tbody>
									<Tr>
										<Td fontSize={p_small} position="relative" bottom="8px">
											<td
												dangerouslySetInnerHTML={{ __html: data.itinerary }}
											/>
										</Td>
									</Tr>
								</Tbody>
							</Table>
						</Box>
						<Box w="100%" borderRadius="15px" bg="white" mt="50px">
							<Table>
								<Thead>
									<Tr>
										<Th fontSize={p_medium} h="50px">
											Activities
										</Th>
									</Tr>
								</Thead>
								<Tbody>
									<Tr>
										<Td fontSize={p_small} position="relative" bottom="8px">
											{data.activities}
										</Td>
									</Tr>
								</Tbody>
							</Table>
						</Box>
						<Box w="100%" borderRadius="15px" bg="white" mt="50px">
							<Table>
								<Thead>
									<Tr>
										<Th fontSize={p_medium} h="50px">
											Complimentary
										</Th>
									</Tr>
								</Thead>
								<Tbody>
									<Tr>
										<Td fontSize={p_small} position="relative" bottom="8px">
											{data.complimentary}
										</Td>
									</Tr>
								</Tbody>
							</Table>
						</Box>

						{/* <Box
							mt='50px'
							display={'flex'}
							flexDir='column'
							alignItems={'center'}
							fontSize={p_medium}
						>
							<Text>
								Total cost including taxes, dated on (updated
								date)
							</Text>
							<Text fontSize={h2} fontWeight={600}>
								₹total price
							</Text>
							<Text w='70%' textAlign={'center'}>
								The taxes and fees component includes: All
								government taxes levied for your bookings.
								PlanMyLeisure service fee for booking and
								concierge support. All currency conversion
								charges wherever applicable.
							</Text>
							<Text mt='20px'>
								Proceed in agreement with our Terms & Conditions
							</Text>
							<Button mt='20px' bg='green.400' color='#fff'>
								Pay and Book your vacation
							</Button>
							<Box
								position={'absolute'}
								h='fit-content'
								bottom={0}
								left={0}
								right={0}
								display={'flex'}
								justifyContent='center'
								alignItems={'center'}
							>
								<Image src={logo} h='100px' />
							</Box>
						</Box> */}
						<Box
							position={"absolute"}
							h="fit-content"
							bottom={0}
							left={0}
							right={0}
							display={"flex"}
							justifyContent="center"
							alignItems={"center"}
						>
							<Image src={logo} h="100px" />
						</Box>
					</Box>
					{/* page - 3 ends */}

					{/* page - 4 */}
					<Box
						px="70px"
						pt="100px"
						bg={bg_color}
						h={height}
						overflow="hidden"
						position={"relative"}
					>
						<Text fontSize={h1} fontWeight={600}>
							Terms and Conditions
						</Text>
						<Text mt="20px" fontSize={p_medium} fontWeight={600}>
							Applicability of The Agreement
						</Text>
						<Text fontSize={p_small}>
							This agreement ("user agreement") incorporates the terms and
							conditions for Plan My Leisure. (PML) and its brand PML to provide
							services to the person (s) ("the User") intending to purchase or
							inquiring for any products and/ or services of PML by using PML
							websites or using any other customer interface channels of PML
							which includes its sales persons, offices, call centers,
							advertisements, information campaigns etc. Both User and PML are
							individually referred as 'party' to the agreement and collective
							referred to as 'parties'.
						</Text>
						<Text mt="20px" fontSize={p_medium} fontWeight={600}>
							User's Responsibility of Cognizance
						</Text>
						<Text fontSize={p_small}>
							The Users availing services from PML shall be deemed to have read,
							understood and expressly accepted the terms and conditions of this
							agreement, which shall govern the desired transaction or provision
							of such services by PML for all purposes, and shall be binding on
							the User. All rights and liabilities of the User and/or PML with
							respect to any services to be provided by PML shall be restricted
							to the scope of this agreement.
						</Text>
						<Text fontSize={p_small}>
							PML reserves the right, in its sole discretion, to terminate the
							access to any or all PML websites or its other sales channels and
							the related services or any portion thereof at any time, without
							notice, for general maintenance or any reason what so ever.
						</Text>
						<Text fontSize={p_small}>
							In addition to this Agreement, there are certain terms of service
							(TOS) specific to the services rendered/ products provided by PML
							like the air tickets, holiday packages etc. Such TOS will be
							provided/ updated by PML which shall be deemed to be a part of
							this Agreement and in the event of a conflict between such TOS and
							this Agreement, the terms of this Agreement shall prevail. The
							User shall be required to read and accept the relevant TOS for the
							service/ product availed by the User. Additionally, the Service
							Provider itself may provide terms and guidelines that govern
							particular features, offers or the operating rules and policies
							applicable to each Service (for example, flights, hotel
							reservations, packages, etc.). The User shall be responsible for
							ensuring compliance with the terms and guidelines or operating
							rules and policies of the Service Provider with whom the User
							elects to deal, including terms and conditions set forth in a
							Service Providers' fare rules, contract of carriage or other
							rules.
						</Text>
						<Text fontSize={p_small}>
							PML Services are offered to the User conditioned on acceptance
							without modification of all the terms, conditions and notices
							contained in this Agreement and the TOS, as may be applicable from
							time to time. For the removal of doubts, it is clarified that
							availing of the Services by the User constitutes an
							acknowledgement and acceptance by the User of this Agreement and
							the TOS. If the User does not agree with any part of such terms,
							conditions and notices, the User must not avail PML Services.
						</Text>
						<Text fontSize={p_small}>
							In the event that any of the terms, conditions, and notices
							contained herein conflict with the Additional Terms or other terms
							and guidelines contained within any other PML document, then these
							terms shall control.
						</Text>
						<Box
							position={"absolute"}
							h="fit-content"
							bottom={0}
							left={0}
							right={0}
							display={"flex"}
							justifyContent="center"
							alignItems={"center"}
						>
							<Image src={logo} h="100px" />
						</Box>
					</Box>
					<Box
						px="70px"
						pt="100px"
						bg={bg_color}
						h={height}
						overflow="hidden"
						position={"relative"}
					>
						<Text mt="20px" fontSize={p_medium} fontWeight={600}>
							Third Party Account Information
						</Text>
						<Text fontSize={p_small}>
							By using the Account Access service in PML websites, the User
							authorizes PML and its agents to access third party sites,
							including that of Banks and other payment gateways, designated by
							them or on their behalf for retrieving requested information.
							While registering, the User will choose a password and is
							responsible for maintaining the confidentiality of the password
							and the account.
						</Text>
						<Text fontSize={p_small}>
							The User is fully responsible for all activities that occur while
							using their password or account. It is the duty of the User to
							notify PML immediately in writing of any unauthorized use of their
							password or account or any other breach of security. PML will not
							be liable for any loss that may be incurred by the User as a
							result of unauthorized use of his password or account, either with
							or without his knowledge. The User shall not use anyone else's
							password at any time.
						</Text>
						<Text mt="20px" fontSize={p_medium} fontWeight={600}>
							Confidentiality
						</Text>
						<Text fontSize={p_small}>
							Any information which is specifically mentioned by PML as
							Confidential shall be maintained confidentially by the user and
							shall not be disclosed unless as required by law or to serve the
							purpose of this agreement and the obligations of both the parties
							therein.
						</Text>
						<Text mt="20px" fontSize={p_medium} fontWeight={600}>
							Website And Mobile App Usage
						</Text>
						<Text fontSize={p_small}>
							Your use of any information or materials on the website and mobile
							app is entirely at your own risk, for which PML shall not
							undertake any liability. It shall be your own accountability to
							ensure that any products, services or information available
							through our website or app, meet your explicit specifications.
						</Text>
						<Text fontSize={p_small}>
							You are restrained from altering, duplicating, distributing,
							transmitting, reproducing, publishing, licensing or selling any
							information, software, products or services acquired from this
							website or app. Duplication of content from the website and app is
							forbidden which is in accordance with the copyright notice and
							forms a part of the Terms of Use. Additionally, PML reserves
							private rights to modify, revise, and delete any of the contents
							without giving prior notice to any person.
						</Text>
						<Text mt="20px" fontSize={p_medium} fontWeight={600}>
							Usage Of The Mobile Number Of The User By PML
						</Text>
						<Text fontSize={p_small}>
							PML may send booking confirmation, itinerary information,
							cancellation, payment confirmation, refund status, schedule change
							or any such other information relevant for the transaction, via
							SMS or by voice call on the contact number given by the User at
							the time of booking; PML may also contact the User by voice call,
							SMS or email in case the User couldn't or hasn't concluded the
							booking, for any reason what so ever, to know the preference of
							the User for concluding the booking and also to help the User for
							the same. The User hereby unconditionally consents that such
							communications via SMS and/ or voice call by PML is (a) upon the
							request and authorization of the User, (b) "transactional" and not
							an "unsolicited commercial communication" as per the guidelines of
							Telecom Regulation Authority of India (TRAI) and (c) in compliance
							with the relevant guidelines of TRAI or such other authority in
							India and abroad. The User will indemnify PML against all types of
							losses and damages incurred by PML due to any action taken by
							TRAI, Access Providers (as per TRAI regulations) or any other
							authority due to any erroneous compliant raised by the User on PML
							with respect to the intimations mentioned above or due to a wrong
							number or email id being provided by the User for any reason
							whatsoever.
						</Text>
						<Box
							position={"absolute"}
							h="fit-content"
							bottom={0}
							left={0}
							right={0}
							display={"flex"}
							justifyContent="center"
							alignItems={"center"}
						>
							<Image src={logo} h="100px" />
						</Box>
					</Box>
					<Box
						px="70px"
						pt="100px"
						bg={bg_color}
						h={height}
						overflow="hidden"
						position={"relative"}
					>
						<Text mt="20px" fontSize={p_medium} fontWeight={600}>
							Onus Of The User
						</Text>
						<Text fontSize={p_small}>
							PML is responsible only for the transactions that are done by the
							User through PML . PML will not be responsible for screening,
							censoring or otherwise controlling transactions, including whether
							the transaction is legal and valid as per the laws of the land of
							the User.
						</Text>
						<Text fontSize={p_small}>
							The accuracy of the data and bookings have to be cross verified by
							the user. The user warrants that they will abide by all such
							additional procedures and guidelines, as modified from time to
							time, in connection with the use of the services. The User further
							warrants that they will comply with all applicable laws and
							regulations regarding use of the services with respect to the
							jurisdiction concerned for each transaction.
						</Text>
						<Text mt="20px" fontSize={p_medium} fontWeight={600}>
							Insurance
						</Text>
						<Text fontSize={p_small}>
							Unless explicitly provided by PML in any specific service or
							deliverable, obtaining sufficient insurance coverage is the
							obligation/option of the user and PML doesn't accept any claims
							arising out of such scenarios.
						</Text>
						<Text fontSize={p_small}>
							Insurance, if any provided as a part of the service/ product by
							PML shall be as per the terms and conditions of the insuring
							company. The User shall contact the insurance company directly for
							any claims or disputes and PML shall not provide any express or
							implied undertakings for acceptance of the claims by the insurance
							company.
						</Text>
						<Text mt="20px" fontSize={p_medium} fontWeight={600}>
							Force Majure Circumstances
						</Text>
						<Text fontSize={p_small}>
							The user agrees that there can be exceptional circumstances where
							the service operators like the airlines, hotels, the respective
							transportation providers or concerns may be unable to honor the
							confirmed bookings due to various reasons like climatic
							conditions, labor unrest, insolvency, business exigencies,
							government decisions, operational and technical issues, route and
							flight cancellations etc. If PML is informed in advance of such
							situations where dishonor of bookings may happen, it will make its
							best efforts to provide similar alternative to its customers or
							refund the booking amount after reasonable service charges, if
							supported and refunded by that respective service operators. The
							user agrees that PML being an agent for facilitating the booking
							services shall not be responsible for any such circumstances and
							the customers have to contact that service provider directly for
							any further resolutions and refunds.
						</Text>
						<Text fontSize={p_small}>
							The User agrees that in situations due to any technical or other
							failure in PML , services committed earlier may not be provided or
							may involve substantial modification. In such cases, PML shall
							refund the entire amount received from the customer for availing
							such services minus the applicable cancellation, refund or other
							charges, which shall completely discharge any and all liabilities
							of PML against such non-provision of services or deficiencies.
							Additional liabilities, if any, shall be borne by the User.
						</Text>
						<Text fontSize={p_small}>
							PML shall not be liable for delays or inabilities in performance
							or nonperformance in whole or in part of its obligations due to
							any causes that are not due to its acts or omissions and are
							beyond its reasonable control, such as acts of God, fire, strikes,
							embargo, acts of government, acts of terrorism or other similar
							causes, problems at airlines, rails, buses, hotels or transporters
							end. In such event, the user affected will be promptly given
							notice as the situation permits.
						</Text>
						<Box
							position={"absolute"}
							h="fit-content"
							bottom={0}
							left={0}
							right={0}
							display={"flex"}
							justifyContent="center"
							alignItems={"center"}
						>
							<Image src={logo} h="100px" />
						</Box>
					</Box>
					<Box
						px="70px"
						pt="100px"
						bg={bg_color}
						h={height}
						overflow="hidden"
						position={"relative"}
					>
						<Text fontSize={p_small}>
							Without prejudice to whatever is stated above, the maximum
							liability on part of PML arising under any circumstances, in
							respect of any services offered on the site, shall be limited to
							the refund of total amount received from the customer for availing
							the services less any cancellation, refund or others charges, as
							may be applicable. In no case the liability shall include any
							loss, damage or additional expense whatsoever beyond the amount
							charged by PML for its services.
						</Text>
						<Text fontSize={p_small}>
							In no event shall PML and/or its suppliers be liable for any
							direct, indirect, punitive, incidental, special, consequential
							damages or any damages whatsoever including, without limitation,
							damages for loss of use, data or profits, arising out of or in any
							way connected with the use or performance of the PML website(s) or
							any other channel . Neither shall PML be responsible for the delay
							or inability to use the PML websites or related services, the
							provision of or failure to provide services, or for any
							information, software, products, services and related graphics
							obtained through the PML website(s), or otherwise arising out of
							the use of the PML website(s), whether based on contract, tort,
							negligence, strict liability or otherwise. PML is not responsible
							for any errors, omissions or representations on any of its pages
							or on any links or on any of the linked website pages.
						</Text>
						<Text mt="20px" fontSize={p_medium} fontWeight={600}>
							Safety Of Data Downloaded
						</Text>
						<Text fontSize={p_small}>
							The User understands and agrees that any material and/or data
							downloaded or otherwise obtained through the use of the Service is
							done entirely at their own discretion and risk and they will be
							solely responsible for any damage to their computer systems or
							loss of data that results from the download of such material
							and/or data.
						</Text>
						<Text fontSize={p_small}>
							Nevertheless, PML will always make its best endeavors to ensure
							that the content on its websites or other information channels are
							free of any virus or such other malwares.
						</Text>
						<Text mt="20px" fontSize={p_medium} fontWeight={600}>
							Feedback From Customer And Solicitation
						</Text>
						<Text fontSize={p_small}>
							The User is aware that PML provides various services like hotel
							bookings, car rentals, holidays and would like to learn about
							them, to enhance his / her travel experience. The User hereby
							specifically authorizes PML to contact the User with offers on
							various services offered by it through direct mailers, e-mailers,
							telephone calls, short messaging services (SMS) or any other
							medium, from time to time. In case that the customer chooses not
							to be contacted, he /she shall write to PML for specific exclusion
							at info@planmyleisure.com or provide his / her preferences to the
							respective service provider. The customers are advised to read and
							understand the privacy policy of PML on its website in accordance
							of which PML contacts, solicits the user or shares the user's
							information.
						</Text>
						<Text mt="20px" fontSize={p_medium} fontWeight={600}>
							Proprietary Rights
						</Text>
						<Text fontSize={p_small}>
							PML may provide the User with contents such as sound, photographs,
							graphics, video or other material contained in sponsor
							advertisements or information. This material may be protected by
							copyrights, trademarks, or other intellectual property rights and
							laws.
						</Text>
						<Text fontSize={p_small}>
							The User may use this material only as expressly authorized by PML
							and shall not copy, transmit or create derivative works of such
							material without express authorization.
						</Text>
						<Text fontSize={p_small}>
							The User acknowledges and agrees that he/she shall not upload
							post, reproduce, or distribute any content on or through the
							Services that is protected by copyright or other proprietary right
							of a third party, without obtaining the written permission of the
							owner of such right.
						</Text>
						<Box
							position={"absolute"}
							h="fit-content"
							bottom={0}
							left={0}
							right={0}
							display={"flex"}
							justifyContent="center"
							alignItems={"center"}
						>
							<Image src={logo} h="100px" />
						</Box>
					</Box>
					<Box
						px="70px"
						pt="100px"
						bg={bg_color}
						h={height}
						overflow="hidden"
						position={"relative"}
					>
						<Text fontSize={p_small}>
							Any copyrighted or other proprietary content distributed with the
							consent of the owner must contain the appropriate copyright or
							other proprietary rights notice. The unauthorized submission or
							distribution of copyrighted or other proprietary content is
							illegal and could subject the User to personal liability or
							criminal prosecution.
						</Text>
						<Text mt="20px" fontSize={p_medium} fontWeight={600}>
							Visa Obligations of the User
						</Text>
						<Text fontSize={p_small}>
							The travel bookings done by PML are subject to the applicable
							requirements of Visa which are to be obtained by the individual
							traveler. PML is not responsible for any issues, including
							inability to travel, arising out of such Visa requirements and is
							also not liable to refund for the untraveled bookings due to any
							such reason.
						</Text>
						<Text mt="20px" fontSize={p_medium} fontWeight={600}>
							Indemnification
						</Text>
						<Text fontSize={p_small}>
							The User agrees to indemnify, defend and hold harmless PML and/or
							its affiliates, their websites and their respective lawful
							successors and assigns from and against any and all losses,
							liabilities, claims, damages, costs and expenses (including
							reasonable legal fees and disbursements in connection therewith
							and interest chargeable thereon) asserted against or incurred by
							PML and/or its affiliates, partner websites and their respective
							lawful successors and assigns that arise out of, result from, or
							may be payable by virtue of, any breach or non-performance of any
							representation, warranty, covenant or agreement made or obligation
							to be performed by the User pursuant to this agreement.
						</Text>
						<Text fontSize={p_small}>
							The user shall be solely and exclusively liable for any breach of
							any country specific rules and regulations or general code of
							conduct and PML cannot be held responsible for the same.
						</Text>
						<Text mt="20px" fontSize={p_small} fontWeight={600}>
							PML at its sole discretion reserves the right to not to accept any
							customer order without assigning any reason thereof. Any contract
							to provide any service by PML is not complete until full money
							towards the service is received from the customer and accepted by
							PML.
						</Text>
						<Text fontSize={p_small}>
							Without prejudice to the other remedies available to PML under
							this agreement, the TOS or under applicable law, PML may limit the
							user's activity, or end the user's listing, warn other users of
							the user's actions, immediately temporarily/indefinitely suspend
							or terminate the user's registration, and/or refuse to provide the
							user with access to the website if:
						</Text>
						<ul>
							<li fontSize={p_small}>
								The user is in breach of this agreement, the TOS and/or the
								documents it incorporates by reference
							</li>
							<li fontSize={p_small}>
								PML is unable to verify or authenticate any information provided
								by the user; or
							</li>
							<li fontSize={p_small}>
								PML believes that the user's actions may infringe on any third
								party rights or breach any applicable law or otherwise result in
								any liability for the user, other users of the website and/or
								PML.
							</li>
						</ul>
						<Text fontSize={p_small}>
							PML may at any time in its sole discretion reinstate suspended
							users. Once the user have been indefinitely suspended the user
							shall not register or attempt to register with PML or use the
							website in any manner whatsoever until such time that the user is
							reinstated by PML.
						</Text>
						<Text fontSize={p_small}>
							Notwithstanding the foregoing, if the user breaches this
							agreement, the TOS or the documents it incorporates by reference,
							PML reserves the right to recover any amounts due and owing by the
							user to PML and/or the service provider and to take strict legal
							action as PML deems necessary.
						</Text>
						<Box
							position={"absolute"}
							h="fit-content"
							bottom={0}
							left={0}
							right={0}
							display={"flex"}
							justifyContent="center"
							alignItems={"center"}
						>
							<Image src={logo} h="100px" />
						</Box>
					</Box>
					<Box
						px="70px"
						pt="100px"
						bg={bg_color}
						h={height}
						overflow="hidden"
						position={"relative"}
					>
						<Text mt="20px" fontSize={p_medium} fontWeight={600}>
							Right To Cancellation In Case Of Invalid User Information
						</Text>
						<Text fontSize={p_small}>
							The User expressly undertakes to provide to PML only correct and
							valid information while requesting for any services under this
							agreement, and not to make any misrepresentation of facts at all.
							Any default on part of the User would vitiate this agreement and
							shall disentitle the User from availing the services from PML.
						</Text>
						<Text fontSize={p_small}>
							In case PML discovers or has reasons to believe at any time during
							or after receiving a request for services from the User that the
							request for services is either unauthorized or the information
							provide PML in its sole discretion shall have the unrestricted
							right to take any steps against the User(s), including
							cancellation of the bookings, etc. without any prior intimation to
							the User. In such an event, PML shall not be responsible or liable
							for any loss or damage that may be caused to the User or any of
							them as a consequence of such cancellation of booking or services.
						</Text>

						<Text fontSize={p_small}>
							The User unequivocally indemnifies PML of any such claim or
							liability and shall not hold PML responsible for any loss or
							damage arising out of measures taken by PML for safeguarding its
							own interest and that of its genuine customers. This would also
							include PML denying/cancelling any bookings on account of
							suspected fraud transactions.
						</Text>
						<Text mt="20px" fontSize={p_medium} fontWeight={600}>
							Severability
						</Text>
						<Text fontSize={p_small}>
							If any provision of this agreement is determined to be invalid or
							unenforceable in whole or in part, such invalidity or
							unenforceability shall attach only to such provision or part of
							such provision and the remaining part of such provision and all
							other provisions of this Agreement shall continue to be in full
							force and effect.
						</Text>
						<Text mt="20px" fontSize={p_medium} fontWeight={600}>
							Headings
						</Text>
						<Text fontSize={p_small}>
							The headings and subheadings herein are included for convenience
							and identification only and are not intended to describe,
							interpret, define or limit the scope, extent or intent of this
							agreement, terms and conditions, notices, or the right to use this
							website by the User contained herein or any other section or pages
							of PML Websites or its partner websites or any provision hereof in
							any manner whatsoever.
						</Text>
						<Text fontSize={p_small}>
							In the event that any of the terms, conditions, and notices
							contained herein conflict with the Additional Terms or other terms
							and guidelines contained within any particular PML website, then
							these terms shall control.
						</Text>
						<Text mt="20px" fontSize={p_medium} fontWeight={600}>
							Relationship
						</Text>
						<Text fontSize={p_small}>
							None of the provisions of any agreement, terms and conditions,
							notices, or the right to use this website by the User contained
							herein or any other section or pages of PML Websites or its
							partner websites, shall be deemed to constitute a partnership
							between the User and PML and no party shall have any authority to
							bind or shall be deemed to be the agent of the other in any way.
						</Text>
						<Box
							position={"absolute"}
							h="fit-content"
							bottom={0}
							left={0}
							right={0}
							display={"flex"}
							justifyContent="center"
							alignItems={"center"}
						>
							<Image src={logo} h="100px" />
						</Box>
					</Box>
					<Box
						px="70px"
						pt="100px"
						bg={bg_color}
						h={height}
						overflow="hidden"
						position={"relative"}
					>
						<Text mt="20px" fontSize={p_medium} fontWeight={600}>
							Updation Of The Information By PML
						</Text>
						<Text fontSize={p_small}>
							User acknowledges that PML provides services with reasonable
							diligence and care. It endeavors its best to ensure that User does
							not face any inconvenience. However, at some times, the
							information, software, products, and services included in or
							available through the PML websites or other sales channels and ad
							materials may include inaccuracies or typographical errors which
							will be immediately corrected as soon as PML notices them. Changes
							are/may be periodically made/added to the information provided
							such. PML may make improvements and/or changes in the PML websites
							at any time without any notice to the User. Any advice received
							except through an authorized representative of PML via the PML
							websites should not be relied upon for any decisions.
						</Text>

						<Text mt="20px" fontSize={p_medium} fontWeight={600}>
							Modification Of These Terms Of Use
						</Text>
						<Text fontSize={p_small}>
							PML reserves the right to change the terms, conditions, and
							notices under which the PML websites are offered, including but
							not limited to the charges. The User is responsible for regularly
							reviewing these terms and conditions.
						</Text>
						<Text mt="20px" fontSize={p_medium} fontWeight={600}>
							Jurisdiction
						</Text>
						<Text fontSize={p_small}>
							PML hereby expressly disclaims any implied warranties imputed by
							the laws of any jurisdiction or country other than those where it
							is operating its offices. PML considers itself and intends to be
							subject to the jurisdiction only of the courts of Chennai, India.
						</Text>
						<Text mt="20px" fontSize={p_medium} fontWeight={600}>
							Responsibilities Of User Vis-a-Vis The Agreement
						</Text>
						<Text fontSize={p_small}>
							The User expressly agrees that use of the services is at their
							sole risk. To the extent PML acts only as a booking agent on
							behalf of third party service providers, it shall not have any
							liability whatsoever for any aspect of the standards of services
							provided by the service providers. In no circumstances shall PML
							be liable for the services provided by the service provider. The
							services are provided on an "as is" and "as available" basis. PML
							may change the features or functionality of the services at any
							time, in their sole discretion, without notice. PML expressly
							disclaims all warranties of any kind, whether express or implied,
							including, but not limited to the implied warranties of
							merchantability, fitness for a particular purpose and
							non-infringement. No advice or information, whether oral or
							written, which the User obtains from PML or through the services
							shall create any warranty not expressly made herein or in the
							terms and conditions of the services. If the User does not agree
							with any of the terms above, they are advised not to read the
							material on any of the PML pages or otherwise use any of the
							contents, pages, information or any other material provided by
							PML. The sole and exclusive remedy of the User in case of
							disagreement, in whole or in part, of the user agreement, is to
							discontinue using the services after notifying PML in writing.
						</Text>
						<Text mt="20px" fontSize={p_medium} fontWeight={600}>
							Usage Of Customer's Holiday Pictures
						</Text>
						<Text fontSize={p_small}>
							Any picture or video shared by the customers during or post the
							trip in the support groups or emails, will be used in the social
							media platform to engage the audience. However, if you are not
							comfortable with the same, kindly drop a mail to
							info@planmyleisure.com and your request will immediately be looked
							into.
						</Text>

						<Box
							position={"absolute"}
							h="fit-content"
							bottom={0}
							left={0}
							right={0}
							display={"flex"}
							justifyContent="center"
							alignItems={"center"}
						>
							<Image src={logo} h="100px" />
						</Box>
					</Box>
					{/* page - 4 ends */}

					<Box
						px="70px"
						pt="100px"
						bg={bg_color}
						h={height}
						overflow="hidden"
						position={"relative"}
					>
						<Text fontSize={h1} fontWeight={600}>
							Cancellation Policy
						</Text>
						<Text mt="20px" fontSize={p_medium} fontWeight={600}>
							General
						</Text>
						<Text fontSize={p_small}>
							The cancellation policy is effective for all vacations crafted by
							PML from 01 September 2019. PML customers eligible for refunds
							will receive the refund amount within 90 working days from the
							date of cancellation or when the supplier(s) processes the refund,
							whichever is later. For refunds related to on-trip cancellations,
							customers will receive the refund amount within 90 working days
							from the date of their return or when the supplier(s) processes
							the refund, whichever is later. For queries/clarifications, please
							reach out to info@planmyleisure.com .
						</Text>
						<Text fontSize={p_small}>
							The Refund amount depicted is subjected to change based on
							international exchange rates , refunds received from suppliers and
							payments received from customers till date. Any change in refund
							amount will be communicated to customers by their respective
							account owners.
						</Text>
						<Text mt="20px" fontSize={p_medium} fontWeight={600}>
							Flights
						</Text>
						<ul>
							<li fontSize={p_small}>
								On cancelling flights marked as “Non-Refundable” on the final
								travel vouchers, customers will be eligible for a zero refund.
							</li>
							<li fontSize={p_small}>
								For Flights marked as “Refundable” on the final travel vouchers,
								customers will receive a refund as per the details mentioned
								under the “Cancellation Policy” section of the product and also
								in the final itinerary shared over the email.
							</li>
							<li fontSize={p_small}>
								The total refunds for flights may include components which vary
								as per the international exchange rates.
							</li>
							<li fontSize={p_small}>
								PML will not be responsible for grounded/cancelled/delayed
								flights. Any cancellation requests for these flights will have
								to be placed with the respective airlines. Realization of
								refunds would be subject to processing by the respective airline
								carrier.
							</li>
							<li fontSize={p_small}>
								The onus is on the customer to ensure that his/her passport has
								a minimum of 1-year validity and is in good condition. PML is
								not liable to refund a customer who is not allowed to board the
								flight because of invalid passports (validity expired, damaged
								passports).
							</li>
							<li fontSize={p_small}>
								Customers are expected to reach the airport ahead of their
								boarding time (at least 2 hours prior to boarding time). PML is
								not responsible to refund customers (for cases wherein airport
								transfers are not planned by us) who miss their flights owing to
								delayed arrival at the airport.
							</li>
							<li fontSize={p_small}>
								For cases wherein airport transfers are planned by PML, flight
								cancellations due to delayed transfers owing to unforeseen
								circumstances specific to a region will not be borne by PML.
							</li>
						</ul>
						<Box
							position={"absolute"}
							h="fit-content"
							bottom={0}
							left={0}
							right={0}
							display={"flex"}
							justifyContent="center"
							alignItems={"center"}
						>
							<Image src={logo} h="100px" />
						</Box>
					</Box>
					<Box
						px="70px"
						pt="100px"
						bg={bg_color}
						h={height}
						overflow="hidden"
						position={"relative"}
					>
						<ul>
							<li fontSize={p_small}>
								Details about baggage limitations (cabin and check-in) will be
								furnished as part of the final travel vouchers. Additional costs
								owing to breached baggage limits will have to be paid by the
								customer at the time of check-in.
							</li>
							<li fontSize={p_small}>
								Certain flight carriers (LCC like Ryanair, Vueling, Voltea etc.)
								have a mandatory web check-in policy. Failure to comply with
								this could result in an additional cost to be paid at the
								airport. PML is not liable to refund customers in such
								circumstances. PML will set meal preferences for customers with
								airline carriers upon request.
							</li>
							<li fontSize={p_small}>
								PML will set meal preferences for customers with airline
								carriers upon request. However, PML has no control over the
								availability and quality of meals served on the flight. This
								will be controlled completely by the airline carrier.
							</li>
						</ul>
						<Text mt="20px" fontSize={p_medium} fontWeight={600}>
							Hotels
						</Text>
						<ul>
							<li fontSize={p_small}>
								On cancelling hotels which have been marked as “Non-Refundable”
								on the final travel vouchers, the customer will be eligible for
								a zero refund.
							</li>
							<li fontSize={p_small}>
								For hotels which have been marked as “Refundable” on the final
								travel vouchers, refunds and their timelines will be applicable
								as mentioned under the “Cancellation Policy” section of the
								product and in the final itinerary shared over email.
							</li>
							<li fontSize={p_small}>
								The total refunds for hotels may include components which vary
								with international exchange rates.
							</li>
							<li fontSize={p_small}>
								While PML strives to provide the best hotels with world-class
								amenities, we cannot be held responsible for factors such as
								hotel staff behaviour, cleanliness and quality of accommodation.
								Additional costs owing to on-trip room upgrades and additional
								amenities will be borne by the customer. All hotels changed
								on-trip (Hotels booked per itinerary cancelled and new hotels
								booked) will entail a 100% cancellation fee.
							</li>
							<li fontSize={p_small}>
								Entertaining early check-in or late check-out requests is solely
								based on the discretion of the hotel. PML will not be able to
								process cancellation requests owing to non-availability of these
								requests.
							</li>
						</ul>
						<Text mt="20px" fontSize={p_medium} fontWeight={600}>
							Activities
						</Text>
						<ul>
							<li fontSize={p_small}>
								On cancelling activities marked as “Non-Refundable” on the final
								travel vouchers, the customer will be eligible for a zero
								refund.
							</li>
							<li fontSize={p_small}>
								For activities, which have been marked as “Refundable” on the
								final travel vouchers, refunds and their timelines will be
								applicable as mentioned under the “Cancellation Policy” section
								of the product and in the final itinerary shared over email.
							</li>
							<li fontSize={p_small}>
								The total refund for activities may include components which
								vary with international exchange rates.
							</li>
						</ul>
						<Box
							position={"absolute"}
							h="fit-content"
							bottom={0}
							left={0}
							right={0}
							display={"flex"}
							justifyContent="center"
							alignItems={"center"}
						>
							<Image src={logo} h="100px" />
						</Box>
					</Box>
					<Box
						px="70px"
						pt="100px"
						bg={bg_color}
						h={height}
						overflow="hidden"
						position={"relative"}
					>
						<Text mt="20px" fontSize={p_medium} fontWeight={600}>
							Transfers
						</Text>
						<ul>
							<li fontSize={p_small}>
								For all transfers, refunds and their timelines will be
								applicable as mentioned under the “Cancellation Policy” section
								of the product and in the final itinerary shared over email.
							</li>
							<li fontSize={p_small}>
								The total refunds for transfers may include components which
								vary with international exchange rates.
							</li>
						</ul>
						<Text mt="20px" fontSize={p_medium} fontWeight={600}>
							Visa & Insurance
						</Text>
						<ul>
							<li fontSize={p_small}>
								PML acts as a facilitator for processing Visa applications. We
								will guide customers on Visa formalities & Visa documentation
								for specific destinations. The discretion to grant/reject Visa
								rests solely with the concerned embassy and PML will not be
								responsible for rejection of any applications. The visa fee is
								non-refundable in case of rejected visa applications.
							</li>
							<li fontSize={p_small}>
								While we strive to provide a seamless Visa experience to the
								customers, PML will not be held responsible for unforeseen
								changes to Visa formalities levied by the embassy during the
								document submission and processing phase.
							</li>
							<li fontSize={p_small}>
								Insurance once applied is subject to 100% cancellation fee and
								is non-refundable.
							</li>
						</ul>
						<Box
							position={"absolute"}
							h="fit-content"
							bottom={0}
							left={0}
							right={0}
							display={"flex"}
							justifyContent="center"
							alignItems={"center"}
						>
							<Image src={logo} h="100px" />
						</Box>
					</Box>

					{/* page - 5 */}
					{/* <Box
						px='70px'
						pt='50px'
						bg={bg_color}
						h={height}
						overflow='hidden'
						position={'relative'}
					>
						<Text fontSize={h1} fontWeight={600}>
							Room Amenities
						</Text>
						<Box
							display={'grid'}
							gridTemplateColumns='1fr 1fr'
							fontSize={p_medium}
						>
							<Box>Free Wifi</Box>
							<Box>Gym</Box>
							<Box>Boutique</Box>
							<Box>Spa</Box>
							<Box>Baby Sitting</Box>
							<Box>Kids Club</Box>
							<Box>Restaurant</Box>
							<Box>Mini Bar</Box>
							<Box>Indoor Games</Box>
							<Box>Outdoor Games</Box>
							<Box>Karaoke</Box>
						</Box>
						<Box
							mt='50px'
							display={'flex'}
							flexDir='column'
							alignItems={'center'}
							fontSize={p_medium}
						>
							<Text>
								Total cost including taxes, dated on 28/05/2022
							</Text>
							<Text fontSize={h2} fontWeight={600}>
								₹160098
							</Text>
							<Text w='70%' textAlign={'center'}>
								The taxes and fees component includes: All
								government taxes levied for your bookings.
								Pickyourtrail service fee for booking and
								concierge support. All currency conversion
								charges wherever applicable.
							</Text>
							<Text mt='20px'>
								Proceed in agreement with our Terms & Conditions
							</Text>
							<Button mt='20px' bg='green.400' color='#fff'>
								Pay and Book your vacation
							</Button>
							<Box
								position={'absolute'}
								h='fit-content'
								bottom={0}
								left={0}
								right={0}
								display={'flex'}
								justifyContent='center'
								alignItems={'center'}
							>
								<Image src={logo} h='100px' />
							</Box>
						</Box>
					</Box> */}
				</Box>
			</Box>
			{/* <Button onClick={genpdf}>Download</Button>
			<a href={dLink} download>
				pdf
			</a> */}
		</Box>
	);
};

export default PdfPage;
