import axios from 'axios';

const updateImgSlider = ({ id, title, image, city, location }) => {
	// console.log(id, title, image, city, location);
	return axios.patch(
		'https://backend.planmyleisure.com/blog/update-hero-image',
		{
			heroImageId: id,
			title,
			city,
			location,
			imageUrl: [image],
		}
	);
};

export default updateImgSlider;
