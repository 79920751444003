import { CheckIcon } from '@chakra-ui/icons';
import { Td, Tr } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import GetPackageById from '../../api/GetPackageById';
import GetUserInfo from '../../api/GetUserInfo';

const CancelledRequests = ({ data, changeState }) => {
	const [name, setName] = useState(null);
	const [email, setEmail] = useState(null);
	const [phone, setPhone] = useState(null);
	const [package_data, set_package_data] = useState(null);
	const [price, setPrice] = useState(null);

	useEffect(() => {
		const getUserDetails = async () => {
			try {
				const response = await GetUserInfo(data.RequestedBy);
				setName(response.data.message.name);
				setEmail(response.data.message.email);
				setPhone(response.data.message.phone);
				const packageData = await GetPackageById(data.packageId);
				setPrice(
					packageData.data.package[0].startingPrice *
					parseInt(data.numberOfPeople)
				);
			} catch (error) {
				console.log('error occured ', error);
			}
		};

		getUserDetails();
	}, []);

	console.log("data",data)
	return (
		<Tr>
			<Td textAlign={'center'}> {name === '' ? '-' : name} </Td>
			<Td textAlign={'center'}> {email === '' ? '-' : email}</Td>
			<Td textAlign={'center'}> {phone === '' ? '-' : phone}</Td>
			<Td textAlign={'center'}>
				{data?.packageId ? data.packageId : '-'}
			</Td>
			<Td textAlign={'center'}>
				{data?.paymentType === undefined
					? data?.paymentStatus === 'Done'
						? price
						: ''
					:data?.paymentType?.normal?.amount
					// data?.paymentType
					// 	.split(',')[0]
					// 	.split(':')[1]
					// 	.split('-')
					// 	.filter((dataaaa) => {
					// 		return dataaaa.split('_').length === 2;
					// 	})
					// 	.map((val) => val.split('_')[0])
					// 	.join(', ')
				}
			</Td>
		</Tr>
	);
};

export default CancelledRequests;
