import {
	Box,
	Table,
	TableContainer,
	Tbody,
	Text,
	Th,
	Thead,
	Tr,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import GetPackageRequests from "../../api/GetPackageRequests";
import CancelledRequests from "./CancelledRequests";
import ConfirmCancellation from "./ConfirmCancellation";
import RequestsTableData from "./RequestsTableData";

const Requests = () => {
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState([]);
	const [state, setState] = useState(false);
	const [selected, set_selected] = useState("requested");

	useEffect(() => {
		setLoading(true);
		const Requests = async () => {
			try {
				const response = await GetPackageRequests();
				console.log("response in requests : ", response);
				setLoading(false);
				setData(response.data.requests);
			} catch (error) {
				console.log("some error occurred", error);
				setLoading(false);
			}
		};
		Requests();
	}, [state]);

	return (
		<Box h={"89vh"} mx={{ base: 1, lg: "10px" }} borderRadius="md">
			<Box display={"inline-flex"} bg="transparent" h="50px">
				<Text
					fontSize={"20px"}
					fontWeight={600}
					bg={selected === "requested" ? "white" : "gray.200"}
					color={selected === "requested" ? "black" : "gray.400"}
					display={"flex"}
					alignItems="center"
					justifyContent={"center"}
					px="20px"
					borderRadius={"15px 15px 0 0"}
					cursor="pointer"
					onClick={() => {
						set_selected("requested");
					}}
					position="relative"
					_after={
						selected === "requested"
							? {
									content: '""',
									position: "absolute",
									height: "0px",
									width: "0px",
									left: "100%",
									bottom: "0",
									borderBottom: "5px solid white",
									borderRight: "5px solid transparent",
									zIndex: "1000",
							  }
							: {}
					}
				>
					Requests
				</Text>
				<Text
					fontSize={"20px"}
					fontWeight={600}
					bg={selected === "booked" ? "white" : "gray.200"}
					color={selected === "booked" ? "black" : "gray.400"}
					display={"flex"}
					alignItems="center"
					justifyContent={"center"}
					px="20px"
					borderRadius={"15px 15px 0 0"}
					cursor="pointer"
					onClick={() => {
						set_selected("booked");
					}}
					position="relative"
					_after={
						selected === "booked"
							? {
									content: '""',
									position: "absolute",
									height: "0px",
									width: "0px",
									// background: 'green',
									left: "100%",
									bottom: "0",
									borderBottom: "5px solid white",
									borderRight: "5px solid transparent",
							  }
							: {}
					}
					_before={
						selected === "booked"
							? {
									content: '""',
									position: "absolute",
									height: "0px",
									width: "0px",
									// background: 'green',
									right: "100%",
									bottom: "0",
									borderBottom: "5px solid white",
									borderLeft: "5px solid transparent",
							  }
							: {}
					}
				>
					Booked
				</Text>
				<Text
					fontSize={"20px"}
					fontWeight={600}
					bg={selected === "cancel request" ? "white" : "gray.200"}
					color={selected === "cancel request" ? "black" : "gray.400"}
					display={"flex"}
					alignItems="center"
					justifyContent={"center"}
					px="20px"
					borderRadius={"15px 15px 0 0"}
					cursor="pointer"
					onClick={() => {
						set_selected("cancel request");
					}}
					position="relative"
					_after={
						selected === "cancel request"
							? {
									content: '""',
									position: "absolute",
									height: "0px",
									width: "0px",
									// background: 'green',
									left: "100%",
									bottom: "0",
									borderBottom: "5px solid white",
									borderRight: "5px solid transparent",
							  }
							: {}
					}
					_before={
						selected === "cancel request"
							? {
									content: '""',
									position: "absolute",
									height: "0px",
									width: "0px",
									// background: 'green',
									right: "100%",
									bottom: "0",
									borderBottom: "5px solid white",
									borderLeft: "5px solid transparent",
							  }
							: {}
					}
				>
					Cancel Requests
				</Text>
				<Text
					fontSize={"20px"}
					fontWeight={600}
					bg={selected === "cancelled" ? "white" : "gray.200"}
					color={selected === "cancelled" ? "black" : "gray.400"}
					display={"flex"}
					alignItems="center"
					justifyContent={"center"}
					px="20px"
					borderRadius={"15px 15px 0 0"}
					cursor="pointer"
					onClick={() => {
						set_selected("cancelled");
					}}
					position="relative"
					_after={
						selected === "cancelled"
							? {
									content: '""',
									position: "absolute",
									height: "0px",
									width: "0px",
									// background: 'green',
									left: "100%",
									bottom: "0",
									borderBottom: "5px solid white",
									borderRight: "5px solid transparent",
							  }
							: {}
					}
					_before={
						selected === "cancelled"
							? {
									content: '""',
									position: "absolute",
									height: "0px",
									width: "0px",
									// background: 'green',
									right: "100%",
									bottom: "0",
									borderBottom: "5px solid white",
									borderLeft: "5px solid transparent",
							  }
							: {}
					}
				>
					Cancelled
				</Text>
			</Box>

			{selected === "requested" ? (
				<Box
					bg="white"
					overflowX="hidden"
					overflowY="scroll"
					h="calc(89vh - 50px)"
				>
					<TableContainer>
						<Table variant={"striped"} overflow="scroll" mt={3}>
							<Thead>
								<Tr>
									<Th textAlign={"center"} fontSize={"1.1rem"}>
										Name
									</Th>
									<Th textAlign={"center"} fontSize={"1.1rem"}>
										Email
									</Th>
									<Th textAlign={"center"} fontSize={"1.1rem"}>
										Phone no.
									</Th>
									<Th textAlign={"center"} fontSize={"1.1rem"}>
										Package id
									</Th>
									<Th textAlign={"center"} fontSize={"1.1rem"}>
										Payment
									</Th>
									<Th textAlign={"center"} fontSize={"1.1rem"}>
										Remarks
									</Th>
									<Th textAlign={"center"} fontSize={"1.1rem"}>
										Confirm
									</Th>
									<Th textAlign={"center"} fontSize={"1.1rem"}></Th>
									<Th textAlign={"center"} fontSize={"1.1rem"}></Th>
								</Tr>
							</Thead>
							<Tbody>
								{loading ? (
									<></>
								) : (
									<>
										{data.reverse().map((data, index) => {
											return data.status === "RequestedCancellation" ? (
												<></>
											) : data.status === "Cancelled" ? (
												<></>
											) : data?.paymentStatus ? (
												data.paymentStatus.status === "Confirmed" ||
												data.paymentStatus.status === "Requested" ? (
													<RequestsTableData
														data={data}
														key={index}
														changeState={setState}
													/>
												) : (
													<></>
												)
											) : (
												<></>
											);
										})}
									</>
								)}
							</Tbody>
						</Table>
					</TableContainer>
				</Box>
			) : (
				<></>
			)}

			{selected === "booked" ? (
				<Box
					bg="white"
					overflowX="hidden"
					overflowY="scroll"
					h="calc(89vh - 50px)"
				>
					<TableContainer>
						<Table variant={"striped"} overflow="scroll" mt={3}>
							<Thead>
								<Tr>
									<Th textAlign={"center"} fontSize={"1.1rem"}>
										Name
									</Th>
									<Th textAlign={"center"} fontSize={"1.1rem"}>
										Email
									</Th>
									<Th textAlign={"center"} fontSize={"1.1rem"}>
										Phone no.
									</Th>
									<Th textAlign={"center"} fontSize={"1.1rem"}>
										Package id
									</Th>
									<Th textAlign={"center"} fontSize={"1.1rem"}>
										Payment
									</Th>
									<Th textAlign={"center"} fontSize={"1.1rem"}>
										Remarks
									</Th>
									<Th textAlign={"center"} fontSize={"1.1rem"}>
										Confirm
									</Th>
									<Th textAlign={"center"} fontSize={"1.1rem"}></Th>
									<Th textAlign={"center"} fontSize={"1.1rem"}></Th>
								</Tr>
							</Thead>
							<Tbody>
								{loading ? (
									<></>
								) : (
									<>
										{data.map((data, index) => {
											return data.status === "Cancelled" ? (
												<></>
											) : data.status === "RequestedCancellation" ? (
												<></>
											) : data?.paymentStatus ? (
												data.paymentStatus.status !== "Done" ? (
													<></>
												) : (
													<RequestsTableData
														data={data}
														key={index}
														changeState={setState}
													/>
												)
											) : (
												<></>
											);
										})}
									</>
								)}
							</Tbody>
						</Table>
					</TableContainer>
				</Box>
			) : (
				<></>
			)}
			{selected === "cancel request" ? (
				<Box
					bg="white"
					overflowX="hidden"
					overflowY="scroll"
					h="calc(89vh - 50px)"
				>
					<TableContainer>
						<Table variant={"striped"} overflow="scroll" mt={3}>
							<Thead>
								<Tr>
									<Th textAlign={"center"} fontSize={"1.1rem"}>
										Name
									</Th>
									<Th textAlign={"center"} fontSize={"1.1rem"}>
										Email
									</Th>
									<Th textAlign={"center"} fontSize={"1.1rem"}>
										Phone no.
									</Th>
									<Th textAlign={"center"} fontSize={"1.1rem"}>
										Package id
									</Th>
									<Th textAlign={"center"} fontSize={"1.1rem"}>
										Payment
									</Th>
									<Th textAlign={"center"} fontSize={"1.1rem"}>
										Cancel
									</Th>
								</Tr>
							</Thead>
							<Tbody>
								{loading ? (
									<></>
								) : (
									<>
										{data.map((data, index) => {
											return data.status !== "RequestedCancellation" ? (
												<></>
											) : (
												<ConfirmCancellation
													data={data}
													key={index}
													changeState={setState}
												/>
											);
										})}
									</>
								)}
							</Tbody>
						</Table>
					</TableContainer>
				</Box>
			) : (
				<></>
			)}
			{selected === "cancelled" ? (
				<Box
					bg="white"
					overflowX="hidden"
					overflowY="scroll"
					h="calc(89vh - 50px)"
				>
					<TableContainer>
						<Table variant={"striped"} overflow="scroll" mt={3}>
							<Thead>
								<Tr>
									<Th textAlign={"center"} fontSize={"1.1rem"}>
										Name
									</Th>
									<Th textAlign={"center"} fontSize={"1.1rem"}>
										Email
									</Th>
									<Th textAlign={"center"} fontSize={"1.1rem"}>
										Phone no.
									</Th>
									<Th textAlign={"center"} fontSize={"1.1rem"}>
										Package id
									</Th>
									<Th textAlign={"center"} fontSize={"1.1rem"}>
										Payment
									</Th>
								</Tr>
							</Thead>
							<Tbody>
								{loading ? (
									<></>
								) : (
									<>
										{data.map((data, index) => {
											return data.status === "Cancelled" ? (
												<CancelledRequests
													data={data}
													key={index}
													changeState={setState}
												/>
											) : (
												<></>
											);
										})}
									</>
								)}
							</Tbody>
						</Table>
					</TableContainer>
				</Box>
			) : (
				<></>
			)}
		</Box>
	);
};

export default Requests;
