import { AddIcon } from '@chakra-ui/icons';
import {
	Box,
	Table,
	TableContainer,
	Tbody,
	Text,
	Th,
	Thead,
	Tr,
	Button,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import getHeroImages from '../../api/imageSliderApi/getHeroImages';
import NewSliderModal from './NewSliderModal';
import SingleImageSliderElement from './SingleImageSliderElement';

const ImageSlider = () => {
	const [data, setData] = useState([]);
	const [state, setState] = useState(false);
	const [del, setDel] = useState(false);
	const dummy = [
		{ image: 'some image', quote: 'some quote' },
		{ image: 'some image', quote: 'some quote' },
		{ image: 'some image', quote: 'some quote' },
		{ image: 'some image', quote: 'some quote' },
	];

	useEffect(() => {
		const heroImages = async () => {
			try {
				const response = await getHeroImages();
				setData(response.data.heroImages);
			} catch (error) {
				console.log('error occurred : ', error);
			}
		};

		heroImages();
	}, [state, del]);

	return (
		<>
			<NewSliderModal state={state} setState={setState} />
			<Box
				// w={{ base: '100%', lg: '86vw' }}
				h={'calc(89vh - 50px)'}
				bg='white'
				borderRadius='0 8px 8px 8px'
				// boxShadow={'rgba(0, 0, 0, 0.24) 0px 3px 8px'}
				overflowY='scroll'
				pl={5}
				pr={5}
				pt={5}
			>
				<Box
					display={'flex'}
					justifyContent='space-between'
					alignItems='center'
					mb={10}
				>
					<Text fontSize={30} fontWeight={500}></Text>
					<Button
						display={'flex'}
						gap={3}
						color='gray.600'
						onClick={() => {
							setState(true);
						}}
					>
						<AddIcon /> <Text>New</Text>
					</Button>
				</Box>

				<TableContainer>
					<Table variant={'striped'} overflow='scroll' mt={3}>
						<Thead>
							<Tr>
								<Th textAlign={'center'} fontSize='1.1rem'>
									Image
								</Th>
								<Th textAlign={'center'} fontSize='1.1rem'>
									quote
								</Th>
								<Th textAlign={'center'} fontSize='1.1rem'></Th>
							</Tr>
						</Thead>
						<Tbody>
							{data.map((data, index) => {
								return (
									<SingleImageSliderElement
										data={data}
										key={index}
										state={del}
										setState={setDel}
									/>
								);
							})}
						</Tbody>
					</Table>
				</TableContainer>
			</Box>
		</>
	);
};
export default ImageSlider;
