import { DeleteIcon } from "@chakra-ui/icons";
import {
  Box,
  Td,
  Tr,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  Button,
  useDisclosure,
} from "@chakra-ui/react";
import { useRef, useState } from "react";
import ShowUserData from "./ShowUserData";
import DeletePackageRequest from "../../api/DeletePackageRequest";
import axios from "axios";

const SingleUser = ({ data, changeState, setReload }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [state, setState] = useState(false);
  const [loading, setLoading] = useState(false);
  const cancleRef = useRef();


  const token = localStorage.getItem('token')

  const handleDeleteRequest = async () => {
    setLoading(true);
    try {
      const res = await axios.delete(`https://backend.planmyleisure.com/user/delete/${data._id}`, {
        headers:{
          Authorization: token
        }
      });
      console.log("Delete => ", res);
      setLoading(false);
      setReload(true)
      changeState((prev) => !prev);
      onClose();
    } catch (error) {
      setLoading(false);
      setReload(true)
    }
  };

  return (
    <>
      <AlertDialog
        isOpen={isOpen}
        onClose={onClose}
        leastDestructiveRef={cancleRef}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize={"lg"} fontWeight={"bold"}>
              Delete User
            </AlertDialogHeader>
            <AlertDialogBody>
              Are you sure? You can't undo this action afterwards.
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button ref={cancleRef} onClick={onClose}>
                Cancel
              </Button>
              <Button colorScheme="red" onClick={handleDeleteRequest} ml={3}>
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
      <ShowUserData data={data} state={state} changeState={setState} />
      <Tr
        _hover={{ cursor: "pointer" }}
        onClick={() => {
          setState(true);
        }}
      >
        <Td textAlign={"center"}>{data.name}</Td>
        <Td textAlign={"center"}>{data.email}</Td>
        <Td textAlign={"center"}>
          <Box
            h="30px"
            w="30px"
            display={"inline-flex"}
            alignItems="center"
            justifyContent={"center"}
            _hover={{
              background: "lightgray",
              borderRadius: "5px",
            }}
            onClick={() => {
              onOpen();
            }}
          >
            <DeleteIcon
              color="red"
              cursor={"pointer"}
            />
          </Box>
        </Td>
      </Tr>
    </>
  );
};

export default SingleUser;
