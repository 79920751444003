import { useRef, useState, useEffect } from "react";
import { AddIcon, CloseIcon, EditIcon } from "@chakra-ui/icons";
import {
  Box,
  Text,
  Input,
  Textarea,
  Button,
  Checkbox,
  Select,
  useToast,
  Image,
  RadioGroup,
  Radio,
  Stack,
  Icon,
  Popover,
  PopoverTrigger,
  IconButton,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  PopoverHeader,
  PopoverBody,
  useDisclosure,
} from "@chakra-ui/react";
import ReactQuill from "react-quill";
import { useNavigate } from "react-router-dom";
import CreateSinglePackageApi from "../../../api/CreateSinglePackageApi";
import UploadImageApi from "../../../api/UploadImageApi";
import axios from "axios";
import { BsFillPlayFill } from "react-icons/bs";
import { AiFillStar, AiOutlineStar } from "react-icons/ai";
import "./CreatePackages.css";

const CreatePackage = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const imageRef = useRef();
  const [stars, setStars] = useState("");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [numberOfImageSelected, setNumberOfImageSelected] = useState(0);
  const [isImageSelected, setIsImageSelected] = useState(false);
  const [uploadedImage, setUploadedImage] = useState([]);
  const [imageObject, setImageObject] = useState([]);
  const [packageTitle, setPackageTitle] = useState("");
  const [category, setCategory] = useState("");
  const [packageDetail1, setPackageDetail1] = useState("");
  const [packageDetail2, setPackageDetail2] = useState("");
  const [packageDetail3, setPackageDetail3] = useState("");
  const [packageDetail4, setPackageDetail4] = useState("");
  const [resort, setResort] = useState([]);
  const [resortText, setResortText] = useState("");
  const [bpStart, setBPStart] = useState(null);
  const [bpEnd, setBPEnd] = useState(null);
  const [packageId, setPackageId] = useState("");
  const [destination, setDestination] = useState("");
  const [isImage, setIsImage] = useState(null);

  const [spStart, setSPStart] = useState(null);
  const [spEnd, setSPEnd] = useState(null);

  const [startingPrice, setStartingPrice] = useState("");
  const [image, setImage] = useState([]);
  // inclusions options
  // hotel
  const [opt1, setOpt1] = useState("");
  // transfers
  const [opt2, setOpt2] = useState("");
  const [transferVal, setTransferVal] = useState("");
  // meal
  const [opt3, setOpt3] = useState("");
  const [mealVal, setMealVal] = useState("");
  // flights
  const [opt4, setOpt4] = useState("");
  // activities
  const [opt5, setOpt5] = useState("");
  const [activity, setActivity] = useState("");

  const [loading, setLoading] = useState(false);

  const [roomType, setRoomType] = useState("");
  const [itinerary, setItineary] = useState("");
  const [flight, setFlight] = useState("");

  useEffect(() => {
    axios
      .get("https://backend.planmyleisure.com/package/get-packages")
      .then((res) => setPackageId(`PML${res.data.packagesCount + 1}`));
  }, []);

  //handleBody
  const handleBody = (e) => {
    console.log(e);
    setItineary(e);
    setActivity(e);
  };

  const handleImageSubmit = (e) => {
    setIsImage(e.target.files[0].type.split("/")[0] === "video" ? false : true);

    let fileList = [...e.target.files];

    if (fileList.length + uploadedImage.length > 4) {
      toast({
        title: "Error",
        description: "4 image are allowed at max",
        status: "error",
      });
    } else {
      setUploadedImage((prev) => {
        let val = fileList.map((item) => {
          return {
            url: URL.createObjectURL(item),
            type: item.type.split("/")[0] === "video" ? false : true,
          };
        });
        prev.push(...val);
        console.log("prev is ", prev);
        return [...prev];
      });
      setImageObject((prev) => {
        prev.push(...fileList);
        return [...prev];
      });

      setIsImageSelected(true);
    }
  };

  // by Randeep
  const uploadImageToCloud = async () => {
    console.log("I am in image upload Function");

    const imageLinks = [];

    for (let i = 0; i < imageObject.length; i++) {
      try {
        const file = imageObject[i];
        const formData = new FormData();
        formData.append("file", file);
        formData.append(
          "upload_preset",
          file.type.split("/")[0] === "video"
            ? process.env.REACT_APP_CLOUD_PRESET_VIDEO
            : process.env.REACT_APP_CLOUD_PRESET_IMAGE
        );
        formData.append("cloud_name", process.env.REACT_APP_CLOUD_NAME);
        try {
          let response = await fetch(
            `https://api.cloudinary.com/v1_1/${process.env.REACT_APP_CLOUD_NAME}/upload`,
            {
              method: "post",
              body: formData,
            }
          );

          response = await response.json();
          console.log("cloud object we got : ", response);
          imageLinks.push(response);
          // .then((response) => response.json())
          // .then((data) => {
          // 	console.log(data);
          // 	imageLinks.append(data);
          // });
        } catch (error) {
          console.log("something went wrong");
          console.log(error);
        }
        // imageLinks.push(response.data);
      } catch (error) {
        console.log(error);
      }
    }
    return imageLinks;
  };

  const mandate = new Map();
  mandate.set("Package Title", packageTitle);

  mandate.set("Category", category);
  mandate.set("Package Detail1", packageDetail1);
  mandate.set("Starting Price", startingPrice);
  mandate.set("Booking Start", bpStart);
  mandate.set("Booking End", bpEnd);
  mandate.set("Stay Start", spStart);
  mandate.set("Stay End", spEnd);
  mandate.set("Upload Image", isImageSelected);

  const handleSubmitForm = async () => {
    console.log("resort is : ", resort);
    console.log("packageId is : ", packageId);
    if (opt4 === "Flights") {
      mandate.set("Flights", flight);
    }
    setLoading(true);
    if (
      packageTitle &&
      category &&
      packageDetail1 &&
      startingPrice &&
      bpStart &&
      bpEnd &&
      spStart &&
      spEnd &&
      isImageSelected
    ) {
      // first let upload the images :
      let imagelinklist = await uploadImageToCloud();
      console.log("image links we get : ", imagelinklist);

      try {
        // time to submit the data
        const response = await CreateSinglePackageApi(
          packageId,
          destination,
          packageTitle,
          category,
          packageDetail1,
          packageDetail2,
          packageDetail3,
          packageDetail4,
          { start: bpStart, end: bpEnd },
          { start: spStart, end: spEnd },
          opt1 + "," + opt2 + "," + opt3 + mealVal + "," + opt4 + "," + opt5,
          startingPrice,
          imagelinklist,
          resort,
          roomType,
          itinerary,
          flight,
          stars,
          activity,
          transferVal
        );
        console.log(response);
        navigate("/dashboard/packages/");
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    } else {
      let description = "Please fill ";

      mandate.forEach((value, key) => {
        console.log(key);
        if (!value || value === "") description += key + ", ";
      });
      // if (uploadedImage.length <= 0) description += "Upload Image, ";

      toast({
        title: "Error",
        description: description,
        status: "error",
        duration: 6000,
        isClosable: true,
      });
      setLoading(false);
    }
  };

  const handleImageRemoval = (index) => {
    let temp = uploadedImage;
    let temp1 = imageObject;
    temp.splice(index, 1);
    temp1.splice(index, 1);
    console.log(temp);
    console.log(temp1);
    setUploadedImage((prev) => [...temp]);
    setImageObject((prev) => [...temp1]);
    if (uploadedImage.length == 0) {
      setIsImageSelected(false);
    }
  };

  return (
    <>
      <Box
        // w={{ base: '100%', lg: '86vw' }}
        h={"89vh"}
        bg="white"
        mx={{ base: 1, lg: "10px" }}
        borderRadius="md"
        boxShadow={"rgba(0, 0, 0, 0.24) 0px 3px 8px"}
        overflowY="scroll"
        pl={5}
        pr={5}
        pt={5}
        pb={10}
      >
        <Box>
          <Box
            display={"flex"}
            justifyContent="space-between"
            alignItems="center"
            mb={10}
          >
            <Text fontSize={30} fontWeight={500}>
              Create Packages
            </Text>
          </Box>
          {/* input Fields */}
          <Box
            pl="40px"
            pr="50px"
            display={"grid"}
            gridTemplateColumns="repeat(2,1fr)"
            gap="10px 40px"
          >
            <Box>
              <Text fontSize={20}>Package Id</Text>
              <Input
                value={packageId}
                placeholder="Enter Package Id"
                type="text"
                w="400px"
                ml={"10px"}
                readOnly
              />
            </Box>
            <Box>
              <Text fontSize={20}>Destination</Text>
              <Input
                value={destination}
                placeholder="Enter Destination"
                type="text"
                w="400px"
                ml={"10px"}
                onChange={(e) => {
                  setDestination(e.target.value);
                }}
              />
            </Box>
            <Box mt="20px">
              <Text fontSize={20}>Title</Text>
              <Input
                value={packageTitle}
                placeholder="Enter Title"
                w="400px"
                type="text"
                ml={"10px"}
                onChange={(e) => setPackageTitle(e.target.value)}
              />
            </Box>
            <Box mt="20px">
              <Text fontSize={20} display="flex">
                Category
              </Text>
              <Select
                placeholder="Select option"
                ml={"10px"}
                w="300px"
                value={category}
                onChange={(e) => {
                  setCategory(e.target.value);
                }}
              >
                <option value="Leisure">Leisure</option>
                <option value="Adventure">Adventure</option>
              </Select>
            </Box>
            <Box mt="20px">
              <Text fontSize={20}>Package Detail 1 </Text>
              <Textarea
                ml={"10px"}
                w="400px"
                placeholder="Enter Package Detail 1"
                value={packageDetail1}
                onChange={(e) => setPackageDetail1(e.target.value)}
              />
            </Box>
            <Box mt="20px">
              <Text fontSize={20}>Package Detail 2 </Text>
              <Textarea
                ml={"10px"}
                w="400px"
                placeholder="Enter Package Detail 2"
                value={packageDetail2}
                onChange={(e) => setPackageDetail2(e.target.value)}
              />
            </Box>
            <Box mt="10px" mb="20px">
              <Text fontSize={20}>Package Detail 3 </Text>
              <Textarea
                ml={"10px"}
                placeholder="Enter Package Detail 3"
                value={packageDetail3}
                w="400px"
                onChange={(e) => setPackageDetail3(e.target.value)}
              />
            </Box>
            <Box mt="10px" mb="20px">
              <Text fontSize={20}>Package Detail 4 </Text>
              <Textarea
                ml={"10px"}
                placeholder="Enter Package Detail 4"
                value={packageDetail4}
                w="400px"
                onChange={(e) => setPackageDetail4(e.target.value)}
              />
            </Box>
            <Box mt="20px" mb="20px" ml="">
              <Text fontSize={20}>Booking Period </Text>
              <Box
                display={"flex"}
                alignItems="center"
                gap="10px"
                position={"relative"}
                left="20px"
                mb="20px"
                w="300px"
              >
                <Text whiteSpace={"nowrap"}>Start Date</Text>
                <Input
                  w="200px"
                  type="date"
                  value={bpStart}
                  onChange={(e) => {
                    setBPStart(e.target.value);
                  }}
                />
              </Box>
              <Box
                display={"flex"}
                alignItems="center"
                gap="10px"
                position={"relative"}
                left="20px"
              >
                <Text whiteSpace={"nowrap"}>End Date</Text>
                <Input
                  w="200px"
                  type="date"
                  value={bpEnd}
                  onChange={(e) => {
                    setBPEnd(e.target.value);
                  }}
                />
              </Box>
            </Box>
            <Box mt="20px" mb="20px">
              <Text fontSize={20}>Stay Period </Text>
              <Box
                display={"flex"}
                alignItems="center"
                gap="10px"
                position={"relative"}
                left="20px"
                mb="20px"
                w="300px"
              >
                <Text whiteSpace={"nowrap"}>Start Date</Text>
                <Input
                  w="200px"
                  type="date"
                  value={spStart}
                  onChange={(e) => {
                    setSPStart(e.target.value);
                  }}
                />
              </Box>
              <Box
                display={"flex"}
                alignItems="center"
                gap="10px"
                position={"relative"}
                left="20px"
              >
                <Text whiteSpace={"nowrap"}>End Date</Text>
                <Input
                  w="200px"
                  type="date"
                  value={spEnd}
                  onChange={(e) => {
                    setSPEnd(e.target.value);
                  }}
                />
              </Box>
            </Box>
            <Box mt="30px" mb="20px" ml="">
              <Text fontSize={20}>Starting Price </Text>
              <Input
                type="number"
                h="40px"
                w="300px"
                ml={"10px"}
                placeholder="Enter Price"
                value={startingPrice}
                onChange={(e) => setStartingPrice(e.target.value)}
              />
            </Box>
            <Box mt="30px" mb="20px">
              <Text fontSize={20}>Stars</Text>
              <Popover isOpen={isOpen} onClose={onClose}>
                <PopoverTrigger>
                  <Text
                    w="300px"
                    h="2.5rem"
                    pl="10px"
                    ml="10px"
                    border={"1px solid rgba(0,0,0,.1)"}
                    borderRadius="5px"
                    display={"flex"}
                    alignItems="center"
                    cursor="pointer"
                    onClick={onOpen}
                  >
                    {stars === ""
                      ? "Select Option"
                      : [...Array(stars)].map((e, i) => {
                          return (
                            <Icon
                              display={"inline-block"}
                              as={AiFillStar}
                              fill="gold"
                            />
                          );
                        })}
                  </Text>
                </PopoverTrigger>
                <PopoverContent w="400px">
                  <PopoverBody p={0}>
                    <Box
                      px="10px"
                      py="3px"
                      _hover={{
                        background: "rgba(0,0,0,.1)",
                      }}
                      cursor="pointer"
                      onClick={() => {
                        setStars(1);
                        onClose();
                      }}
                    >
                      <Icon as={AiFillStar} fill="gold" />
                    </Box>
                    <Box
                      px="10px"
                      py="3px"
                      _hover={{
                        background: "rgba(0,0,0,.1)",
                      }}
                      cursor="pointer"
                      onClick={() => {
                        setStars(2);
                        onClose();
                      }}
                    >
                      <Icon as={AiFillStar} fill="gold" />
                      <Icon as={AiFillStar} fill="gold" />
                    </Box>
                    <Box
                      px="10px"
                      py="3px"
                      _hover={{
                        background: "rgba(0,0,0,.1)",
                      }}
                      cursor="pointer"
                      onClick={() => {
                        setStars(3);
                        onClose();
                      }}
                    >
                      <Icon as={AiFillStar} fill="gold" />
                      <Icon as={AiFillStar} fill="gold" />
                      <Icon as={AiFillStar} fill="gold" />
                    </Box>
                    <Box
                      px="10px"
                      py="3px"
                      _hover={{
                        background: "rgba(0,0,0,.1)",
                      }}
                      cursor="pointer"
                      onClick={() => {
                        setStars(4);
                        onClose();
                      }}
                    >
                      <Icon as={AiFillStar} fill="gold" />
                      <Icon as={AiFillStar} fill="gold" />
                      <Icon as={AiFillStar} fill="gold" />
                      <Icon as={AiFillStar} fill="gold" />
                    </Box>
                    <Box
                      px="10px"
                      py="3px"
                      _hover={{
                        background: "rgba(0,0,0,.1)",
                      }}
                      cursor="pointer"
                      onClick={() => {
                        setStars(5);
                        onClose();
                      }}
                    >
                      <Icon as={AiFillStar} fill="gold" />
                      <Icon as={AiFillStar} fill="gold" />
                      <Icon as={AiFillStar} fill="gold" />
                      <Icon as={AiFillStar} fill="gold" />
                      <Icon as={AiFillStar} fill="gold" />
                    </Box>
                  </PopoverBody>
                </PopoverContent>
              </Popover>
            </Box>
          </Box>
          {/* <br></br> */}
          <Box mt="20px" mb="20px" ml="40px">
            <Text as="div" fontSize={20}>
              Inclusion
              <Box display={"flex"} flexDir="column">
                <Checkbox
                  value="Hotel"
                  onChange={(e) => {
                    if (e.target.checked) {
                      setOpt1(e.target.value);
                    } else {
                      setOpt1("");
                    }
                  }}
                >
                  Hotel/Resorts
                </Checkbox>
                {opt1 === "Hotel" ? (
                  <Box
                    pl="40px"
                    pr="50px"
                    display={"grid"}
                    gridTemplateColumns="repeat(2,1fr)"
                    gap="20px 200px"
                  >
                    <Box mt="30px" mb="20px">
                      <Text fontSize={18}>Hotel/Resorts</Text>
                      <Box display={"flex"} gap="10px">
                        <Input
                          type="text"
                          h="40px"
                          w="200px"
                          value={resortText}
                          onChange={(e) => {
                            setResortText(e.target.value);
                          }}
                        />
                      </Box>
                      <Box>
                        {resort.map((data, index) => {
                          return (
                            <Box
                              mt="10px"
                              key={index}
                              display="inline-flex"
                              alignItems={"center"}
                              gap="10px"
                              px="10px"
                              bg="rgba(0,0,0,.2)"
                              ml="10px"
                              borderRadius={"5px"}
                            >
                              <Text>{data}</Text>
                              <CloseIcon
                                fontSize={"12px"}
                                cursor="pointer"
                                color="gray.100"
                                onClick={() => {
                                  setResort((prev) => {
                                    prev.splice(index, 1);
                                    return [...prev];
                                  });
                                }}
                              />
                            </Box>
                          );
                        })}
                      </Box>
                    </Box>
                    {/* room type */}
                    <Box mt="30px" mb="20px">
                      <Text fontSize={18}>Room Type</Text>
                      <Box display={"flex"} gap="10px">
                        <Input
                          type="text"
                          h="40px"
                          w="200px"
                          value={roomType}
                          onChange={(e) => {
                            setRoomType(e.target.value);
                          }}
                        />
                        <Button
                          height={"40px"}
                          // isDisabled={resort.length === 5 ? true : false}
                          onClick={() => {
                            setResort((prev) => {
                              prev.push(resortText);
                              return [...prev];
                            });
                            setResortText("");
                            // setRoomType("");
                          }}
                        >
                          Add
                        </Button>
                      </Box>
                    </Box>
                  </Box>
                ) : (
                  <></>
                )}

                <Checkbox
                  value="Transfers"
                  onChange={(e) => {
                    if (e.target.checked) {
                      setOpt2(e.target.value);
                    } else {
                      setOpt2("");
                    }
                  }}
                >
                  Transfers
                </Checkbox>
                {opt2 === "Transfers" ? (
                  <Box mt="30px" mb="20px" ml="40px">
                    <Text fontSize={18}>Transfer</Text>
                    <Input
                      type="text"
                      h="40px"
                      w="200px"
                      onChange={(e) => {
                        setTransferVal(e.target.value);
                      }}
                      value={transferVal}
                    />
                  </Box>
                ) : (
                  <></>
                )}

                <Checkbox
                  value="Meal"
                  onChange={(e) => {
                    if (e.target.checked) {
                      setOpt3(e.target.value);
                    } else {
                      setOpt3("");
                      setMealVal("");
                    }
                  }}
                >
                  Meal
                </Checkbox>
                {opt3 === "Meal" ? (
                  <RadioGroup onChange={setMealVal} value={mealVal}>
                    <Stack position={"relative"} left="20px" mb="10px">
                      <Radio value="-BB">BB</Radio>
                      <Radio value="-HB">HB</Radio>
                      <Radio value="-FB">FB</Radio>
                      <Radio value="-AI">AI</Radio>
                    </Stack>
                  </RadioGroup>
                ) : (
                  <></>
                )}
                <Checkbox
                  value="Flights"
                  onChange={(e) => {
                    if (e.target.checked) {
                      setOpt4(e.target.value);
                      mandate.set("Flight", flight);
                    } else {
                      setOpt4("");
                      mandate.delete("Flight");
                    }
                  }}
                >
                  Flights
                </Checkbox>
                {opt4 === "Flights" ? (
                  <Box
                    pl="40px"
                    pr="50px"
                    display={"grid"}
                    gridTemplateColumns="repeat(2,1fr)"
                    gap="20px 200px"
                  >
                    <Box mt="30px" mb="20px">
                      <Text fontSize={18}>Flight Details</Text>
                      <Input
                        type="text"
                        h="40px"
                        w="200px"
                        onChange={(e) => {
                          setFlight(e.target.value);
                        }}
                        value={flight}
                      />
                    </Box>
                    {/* starting price */}
                    {/* <Box mt="30px" mb="20px">
                      <Text fontSize={18}>Starting Price </Text>
                      <Input
                        type="number"
                        h="40px"
                        w="200px"
                        ml={"10px"}
                        value={startingPrice}
                        onChange={(e) => setStartingPrice(e.target.value)}
                      />
                    </Box> */}
                  </Box>
                ) : (
                  <></>
                )}

                <Checkbox
                  value="Activities"
                  onChange={(e) => {
                    if (e.target.checked) {
                      setOpt5(e.target.value);
                    } else {
                      setOpt5("");
                    }
                  }}
                >
                  Activities
                </Checkbox>
                {opt5 === "Activities" ? (
                  <Box mt="30px" mb="20px" ml="40px" width={"600px"}>
                    <Text fontSize={18}>Activity</Text>
                    <ReactQuill
                      placeholder="create new activity"
                      modules={CreatePackage.modules}
                      formats={CreatePackage.formate}
                      onChange={setActivity}
                      value={activity}
                    />
                  </Box>
                ) : (
                  <></>
                )}
              </Box>
            </Text>
          </Box>

          <Box
            mt="30px"
            mb="20px"
            ml="40px"
            gridColumnStart={1}
            gridColumnEnd={3}
          >
            <Text fontSize={20}> Upload Image </Text>
            {!isImageSelected ? (
              <Box
                bg="lightblue"
                display={"inline-block"}
                ml="10px"
                color="gray.600"
                px="10px"
                py="5px"
                borderRadius="md"
                cursor={"pointer"}
                onClick={() => {
                  imageRef.current.click();
                }}
              >
                <Input
                  type="file"
                  display={"none"}
                  ref={imageRef}
                  accept="image/*, video/*"
                  multiple
                  onChange={handleImageSubmit}
                />
                Upload Image
              </Box>
            ) : (
              <></>
            )}
            {isImageSelected ? (
              <Box display={"flex"} alignItems="end" gap="10px">
                {uploadedImage.map((item, index) => {
                  return (
                    <Box
                      position={"relative"}
                      display={"inline-flex"}
                      bg="lightgray"
                      ml="20px"
                      mt="20px"
                      alignItems={"center"}
                      gap={3}
                      key={item}
                    >
                      {!item.type ? (
                        <Box w="150px" h="100px" position={"relative"}>
                          <Icon
                            as={BsFillPlayFill}
                            position="absolute"
                            fontSize={"50px"}
                            left="50px"
                            top="20px"
                            fill="rgba(0,0,0,.5)"
                          />
                          <video>
                            <source src={item.url} />
                          </video>
                        </Box>
                      ) : (
                        <Image src={item.url} height="100px" />
                      )}
                      <CloseIcon
                        p="10px"
                        h="30px"
                        w="30px"
                        position={"absolute"}
                        top={0}
                        right={0}
                        transform={"translate(+50%, -50%)"}
                        bg="#32bac9"
                        borderRadius={"full"}
                        cursor="pointer"
                        color="white"
                        onClick={() => {
                          handleImageRemoval(index);
                        }}
                      />
                    </Box>
                  );
                })}
                {uploadedImage.length < 4 ? (
                  <Box
                    h="50px"
                    w="50px"
                    bg="lightgray"
                    display={"flex"}
                    justifyContent="center"
                    alignItems={"center"}
                    cursor="pointer"
                    onClick={() => {
                      imageRef.current.click();
                    }}
                  >
                    <Input
                      type="file"
                      display={"none"}
                      ref={imageRef}
                      accept="image/*, video/*"
                      multiple
                      onChange={handleImageSubmit}
                    />
                    <AddIcon color="gray.600" />
                  </Box>
                ) : (
                  <></>
                )}
              </Box>
            ) : (
              <></>
            )}
          </Box>
        </Box>
        <Box mt="30px" mb="20px" ml="40px" width={"600px"}>
          <Text fontSize={20}>Itinerary</Text>
          <ReactQuill
            placeholder="create new itinerary"
            modules={CreatePackage.modules}
            formats={CreatePackage.formate}
            onChange={setItineary}
            value={itinerary}
          />
        </Box>
        <Box
          display={"flex"}
          justifyContent="space-between"
          px="30px"
          pt="50px"
          gap={5}
        >
          <Box flexGrow={2}></Box>
          <Button
            bg="red.600"
            color="white"
            _hover={{
              backgroundColor: "red.600",
            }}
            onClick={() => {
              navigate("/dashboard/packages/");
            }}
          >
            Cancel
          </Button>
          <Button
            bg="green.600"
            color="white"
            _hover={{
              backgroundColor: "green.600",
            }}
            onClick={handleSubmitForm}
            isLoading={loading}
          >
            Save
          </Button>
        </Box>
      </Box>
    </>
  );
};

CreatePackage.modules = {
  toolbar: [
    [{ header: "1" }, { header: "2" }, { header: [3, 4, 5, 6] }, { font: [] }],
    // [{ size: [] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [{ list: "ordered" }, { list: "bullet" }],
    ["link"],
    // ['clean'],
    // ['code-block'],
  ],
};

CreatePackage.formate = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "link",
  "image",
  "video",
  "code-block",
];

export default CreatePackage;
