import axios from 'axios';

const deleteAgent = (agentId) => {
	const token = localStorage.getItem('token');
	return axios.delete(
		'https://backend.planmyleisure.com/agent/delete-agent-by-id',
		{ data: { agentId: agentId }, headers: { Authorization: token } }
	);
};

export default deleteAgent;
