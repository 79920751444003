import axios from 'axios';

const NewBlogApi = (blogHeading, blogBody, readTime, category, imageUrl) => {
	const byteSize = (str) => new Blob([str]).size;
	console.log('blog heading :', byteSize(blogHeading));
	console.log('blog body :', byteSize(blogBody));
	console.log('read Time :', byteSize(readTime));
	console.log('category :', category);
	console.log('image url :', imageUrl);

	return axios.post('https://backend.planmyleisure.com/blog/create-blog', {
		blogHeading: blogHeading,
		blogBody: { blogBody },
		readTime: readTime,
		category: category,
		imageUrl: imageUrl,
	});
};

export default NewBlogApi;
