import axios from 'axios';

const DeleteLeadApi = (id) => {
	console.log('id : ', id);
	return axios.delete(
		`https://backend.planmyleisure.com/lead/delete-lead/${id.id}`
	);
};

export default DeleteLeadApi;
