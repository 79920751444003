import { CloseIcon, DeleteIcon, EditIcon } from '@chakra-ui/icons';
import {
	Box,
	Text,
	Button,
	Icon,
	TableContainer,
	Table,
	Tr,
	Th,
	Thead,
	Tbody,
	Menu,
	MenuButton,
	MenuList,
	MenuItem,
	useDisclosure,
	Modal,
	ModalOverlay,
	ModalContent,
	useToast,
	Input,
	Td,
} from '@chakra-ui/react';
import { upload } from '@testing-library/user-event/dist/upload';
import { useEffect, useRef, useState } from 'react';
import { HiUpload } from 'react-icons/hi';
import { IoMdAdd, IoMdThermometer } from 'react-icons/io';
import { useNavigate, useSearchParams } from 'react-router-dom';
import GetPackagesList from '../../api/GetPackagesList';
import DeletePackageApi from '../../api/DeletePackageApi';
import './packages.css';
import Pkg from './Pkg';

const ActivePackages = () => {
	const navigate = useNavigate();
	const selectFile = useRef();
	const toast = useToast();
	const [packages, setPackages] = useState([]);
	const [loading, setLoading] = useState(true);
	const { isOpen, onOpen, onClose } = useDisclosure();
	const [state, setState] = useState(true);

	useEffect(() => {
		const getPackages = async () => {
			const count = 10;
			const page = 1;

			try {
				const response = await GetPackagesList(count, page);
			console.log( 'packages', response.data.packages.length)
				setPackages(
					response.data.packages.filter((data) => {
						return data.isArchived ? false : true;
					})
				);
				setLoading(false);
			} catch (error) {
				console.log(error);
				setLoading(false);
			}
		};

		getPackages();
	}, [state]);

	const handleUploadBtn = () => {
		onOpen();
	};

	const handleSelectedFile = (e) => {
		console.log(e.target.files);
	};

	const uploadFile = (file) => {
		const name = file.name.split('.');
		if (name[name.length - 1] !== 'xls') {
			console.log('wrong file');
			toast({
				position: 'top-right',
				title: 'Error',
				description: 'please upload excel files only',
				status: 'error',
				duration: 6000,
				isClosable: true,
			});
		} else {
			console.log('right file time to upload');
		}
	};

	const handleDropedFile = (e) => {
		let dt = e.dataTransfer;
		let files = dt.files;
		[...files].forEach(uploadFile);
	};

	return (
		<>
			{/* modal to upload the excel file */}
			<Modal isOpen={isOpen} onClose={onClose} size='full'>
				<ModalOverlay />
				<ModalContent
					bg='transparent'
					display={'flex'}
					justifyContent='center'
					alignItems='center'
				>
					<Box
						w='500px'
						h='fit-content'
						bg='#f5f5f5'
						// top='50%'
						// left='50%'
						// transform={'translate(-50%,-50%)'}
						borderRadius='xl'
						pt={'30px'}
						pl='20px'
						pb='20px'
						pr='20px'
						position={'relative'}
					>
						{/* close button */}
						<Box
							position={'absolute'}
							right={'30px'}
							display='inline-block'
							cursor={'pointer'}
							onClick={() => {
								onClose();
							}}
						>
							<CloseIcon />
						</Box>
						{/* heading */}
						<Text fontSize={20} textAlign='center' mb='20px'>
							{' '}
							Upload Excel
						</Text>
						<Box></Box>
						<Box
							w='100%'
							h='300px'
							bg='lightblue'
							display={'flex'}
							alignItems='center'
							justifyContent={'center'}
							borderRadius='md'
							color={'gray.600'}
							cursor='pointer'
							onDragEnter={(e) => {
								e.preventDefault();
								e.target.classList.add('highlight');
							}}
							onDragLeave={(e) => {
								e.preventDefault();
								e.target.classList.remove('highlight');
							}}
							onDragOver={(e) => {
								e.preventDefault();
								e.target.classList.add('highlight');
							}}
							onDrop={(e) => {
								e.preventDefault();
								e.target.classList.remove('highlight');
								handleDropedFile(e);
							}}
							onClick={(e) => {
								selectFile.current.click();
							}}
						>
							<Input
								type='file'
								display={'none'}
								ref={selectFile}
								onChange={handleSelectedFile}
							/>
							Drop file here or click to browse
						</Box>
					</Box>
				</ModalContent>
			</Modal>
			<Box
				// w={{ base: '100%', lg: '86vw' }}
				// h={'89vh'}
				// bg='white'
				// mx={{ base: 1, lg: '10px' }}
				borderRadius='0 15px 15px 15px'
				// boxShadow={'rgba(0, 0, 0, 0.24) 0px 3px 8px'}
				// overflowY='scroll'
				// pl={5}
				// pr={5}
				// pt={5}
				bg='white'
				overflowX='hidden'
				overflowY='scroll'
				h='calc(89vh - 50px)'
				pt={5}
			>
				<Box
					display={'flex'}
					justifyContent='space-between'
					alignItems='center'
					mb={10}
					mx={5}
				>
					<Text fontSize={30} fontWeight={500}></Text>
					<Button display={'flex'} gap={3} color='gray.600'>
						<Menu>
							<MenuButton> + Add Packages </MenuButton>
							<MenuList>
								<MenuItem
									display={'flex'}
									gap={2}
									onClick={handleUploadBtn}
								>
									<Icon as={HiUpload} /> Upload
								</MenuItem>
								<MenuItem
									display={'flex'}
									gap={2}
									onClick={() => {
										navigate('/dashboard/packages/create');
									}}
								>
									<Icon as={IoMdAdd} /> Create
								</MenuItem>
							</MenuList>
						</Menu>
					</Button>
				</Box>

				<TableContainer variant={'striped'} overflow='scroll' mt={3}>
					<Table>
						<Thead>
							<Tr>
								<Th textAlign={'center'} fontSize={'1.1rem'}>
									Package Id
								</Th>
								<Th textAlign={'center'} fontSize={'1.1rem'}>
									Destination
								</Th>
								<Th textAlign={'center'} fontSize={'1.1rem'}>
									Title
								</Th>
								<Th
									textAlign={'center'}
									fontSize={'1.1rem'}
								></Th>
								<Th
									textAlign={'center'}
									fontSize={'1.1rem'}
								></Th>
							</Tr>
						</Thead>
						{loading ? (
							<></>
						) : (
							<Tbody>
								{packages.map((item, index) => {
									return (
										<Pkg
											item={item}
											key={index}
											changeState={setState}
										/>
									);
								})}
							</Tbody>
						)}
					</Table>
				</TableContainer>
			</Box>
		</>
	);
};

export default ActivePackages;
