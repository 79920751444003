import { DeleteIcon } from '@chakra-ui/icons';
import { Icon, Td, Tr } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { MdUnarchive } from 'react-icons/md';
import ArcPkgDelete from './ArcPkgDelete';
import ArcPkgUnarc from './ArcPkgUnarc';

const ArcPkg = ({ item, changeState }) => {
	const [del, set_del] = useState(false);
	const [unarc, set_unarc] = useState(false);

	useEffect(() => {
		if (del === false) {
			console.log('changing state');
			changeState((prev) => !prev);
		}
		if (unarc === false) {
			console.log('changing state');
			changeState((prev) => !prev);
		}
	}, [del, unarc]);

	return (
		<>
			<ArcPkgDelete
				item={item}
				state={del}
				changeState={set_del}
				reload={changeState}
			/>
			<ArcPkgUnarc
				item={item}
				state={unarc}
				changeState={set_unarc}
				reload={changeState}
			/>
			<Tr>
				<Td textAlign={'center'}>{item.packageId}</Td>
				<Td textAlign={'center'}>{item.packageTitle}</Td>
				<Td textAlign={'center'} display='flex' alignItems={'center'}>
					<Icon
						fontSize={'22px'}
						color='green.400'
						as={MdUnarchive}
						p={0}
						cursor='pointer'
						onClick={() => {
							set_unarc(true);
						}}
					/>
				</Td>
				<Td>
					<DeleteIcon
						fontSize={'18px'}
						color='red'
						cursor={'pointer'}
						onClick={() => {
							set_del(true);
						}}
					/>
				</Td>
			</Tr>
		</>
	);
};

export default ArcPkg;
