import axios from 'axios';

const updateFactsApi = (factId, imageUrl) => {
	// console.log('update facts api got ', factId, imageUrl);
	return axios.patch('https://backend.planmyleisure.com/blog/update-fact', {
		factId,
		imageUrl,
	});
};

export default updateFactsApi;
