import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { AccessAuthContext } from '../context/authContext';
import Agents from '../dashboard/agents/Agents';
import Brands from '../dashboard/brands/Brands';
import Dashboard from '../dashboard/Dashboard';
import Image from '../dashboard/imageSlider/Image';
import ImageSlider from '../dashboard/imageSlider/ImageSlider';
import Leads from '../dashboard/leads/Leads';
import Packages from '../dashboard/Packages';
import CreatePackage from '../dashboard/Packages/createPackage/CreatePackage';
import UpdatePackage from '../dashboard/Packages/updatePackage/UpdatePackage';
import EditPost from '../dashboard/posts/EditPost';
import PostList from '../dashboard/posts/postList/postList';
import Posts from '../dashboard/posts/Posts';
import PdfPage from '../dashboard/requests/PdfPage';
import Requests from '../dashboard/requests/Requests';
import Users from '../dashboard/users/Users';
import Login from '../login/Login';

const AllRoutes = () => {
	const { loginStatus } = AccessAuthContext();

	return (
		<Routes>
			<Route path='/pdf' element={<PdfPage />} />
			<Route
				path='/'
				element={
					loginStatus ? (
						<Navigate to='/dashboard/' replace />
					) : (
						<Navigate to='/login' replace />
					)
				}
			/>
			<Route
				path='/login'
				element={
					loginStatus ? (
						<Navigate to='/dashboard/' replace />
					) : (
						<Login />
					)
				}
			/>
			<Route
				path='/dashboard/'
				element={
					loginStatus ? (
						<Dashboard />
					) : (
						<Navigate to='/login' replace />
					)
				}
			>
				<Route path='agents' element={<Agents />} />
				<Route path='brands' element={<Brands />} />
				<Route path='image-slider' element={<Image />} />
				<Route
					index
					element={
						loginStatus ? (
							<Leads />
						) : (
							<Navigate to='/login' replace />
						)
					}
				/>
				<Route path='requests' element={<Requests />} />
				<Route path='users' element={<Users />} />
				<Route path='blog'>
					<Route
						index
						element={
							loginStatus ? (
								<PostList />
							) : (
								<Navigate to='/login' replace />
							)
						}
					/>
					<Route path='new' element={<Posts />} />
					<Route path='edit' element={<EditPost />} />
				</Route>
				<Route path='packages'>
					<Route index element={<Packages />} />
					<Route path='create' element={<CreatePackage />} />
					<Route path='update' element={<UpdatePackage />} />
				</Route>
			</Route>
			{/*  */}
		</Routes>
	);
};

export default AllRoutes;
