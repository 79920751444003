import axios from 'axios';

const logout = async () => {
	localStorage.clear();

	// reloading so that user get transferd to login page
	document.location.reload();
};

export default logout;
