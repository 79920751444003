import axios from 'axios';

const EditBlogApi = (id, blogTitle, blogBody, readTime, category, imageUrl) => {
	console.log(id, blogTitle, blogBody, readTime, category, imageUrl);

	return axios.patch('https://backend.planmyleisure.com/blog/update-blog', {
		id,
		blogHeading: blogTitle,
		blogBody: { blogBody },
		readTime,
		category,
		imageUrl,
	});
};

export default EditBlogApi;
