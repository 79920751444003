import { CloseIcon } from '@chakra-ui/icons';
import {
	Box,
	Modal,
	ModalContent,
	ModalOverlay,
	useDisclosure,
	Text,
	Input,
	Button,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';

const EditBrand = ({ state, setState, data }) => {
	const [fname, setFname] = useState(data.fname);
	const [lname, setLname] = useState(data.lname);
	const [email, setEmail] = useState(data.email);
	const [phone, setPhone] = useState(data.mobile);
	const [cname, setCname] = useState(data.cname);
	const [cwebsite, setCwebsite] = useState(data.cwebsite);
	const [business, setBusiness] = useState(data.business);
	const { isOpen, onOpen, onClose } = useDisclosure();

	useEffect(() => {
		if (state) {
			onOpen();
		}
	}, [state]);

	return (
		<Modal isOpen={isOpen} onClose={onClose} size='full'>
			<ModalOverlay />
			<ModalContent bg='transparent' position={'relative'}>
				<Box
					bg='#fff'
					w='600px'
					h='fit-content'
					pos={'absolute'}
					left='50%'
					top='50%'
					transform={'translate(-50%,-50%)'}
					px='25px'
					py='30px'
					borderRadius={'10px'}
				>
					<Box
						display={'flex'}
						alignItems='center'
						justifyContent={'space-between'}
						cursor='pointer'
						onClick={() => {
							setState(false);
							onClose();
						}}
					>
						<Text fontSize={20} fontWeight={600}>
							Edit Agent
						</Text>
						<CloseIcon />
					</Box>
					<Box>
						<Box pt='20px'>
							<Text>First Name</Text>
							<Input
								type='text'
								value={fname}
								onChange={(e) => {
									setFname(e.target.value);
								}}
							/>
						</Box>
						<Box pt='20px'>
							<Text>Last Name</Text>
							<Input
								type='text'
								value={lname}
								onChange={(e) => {
									setLname(e.target.value);
								}}
							/>
						</Box>
						<Box pt='20px'>
							<Text>Email</Text>
							<Input
								type='email'
								value={email}
								onChange={(e) => {
									setEmail(e.target.value);
								}}
							/>
						</Box>
						<Box pt='20px'>
							<Text>Mobile no.</Text>
							<Input
								type='number'
								value={phone}
								onChange={(e) => {
									setPhone(e.target.value);
								}}
							/>
						</Box>
						<Box pt='20px'>
							<Text>Company Name</Text>
							<Input
								type='text'
								value={cname}
								onChange={(e) => {
									setCname(e.target.value);
								}}
							/>
						</Box>
						<Box pt='20px'>
							<Text>Company Website</Text>
							<Input
								type='text'
								value={cwebsite}
								onChange={(e) => {
									setCwebsite(e.target.value);
								}}
							/>
						</Box>
						<Box pt='20px'>
							<Text>Business Type</Text>
							<Input
								type='text'
								value={business}
								onChange={(e) => {
									setBusiness(e.target.value);
								}}
							/>
						</Box>
					</Box>
					<Box pt='30px' display={'flex'} justifyContent='end'>
						<Button colorScheme={'green'}>Save</Button>
					</Box>
				</Box>
			</ModalContent>
		</Modal>
	);
};
export default EditBrand;
