import { CloseIcon } from "@chakra-ui/icons";
import {
  Box,
  Modal,
  ModalOverlay,
  ModalContent,
  useDisclosure,
  Text,
  Input,
  Button,
  Checkbox,
  RadioGroup,
  useToast,
  Stack,
  Radio,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import GetPackageById from "../../api/GetPackageById";
import updateRequestPackage from "../../api/updateRequestPackage";
import ReactQuill from "react-quill";

const RequestsEditModal = ({
  state,
  changeState,
  data,
  addResorts,
  addInclusion,
  reFetch,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [checkedResorts, setCheckedResorts] = useState(0);
  const [startDate, setStartDate] = useState(
    data.startDate === undefined ? "" : data.startDate.split("T")[0]
  );
  const [endDate, setEndDate] = useState(
    data.startDate === undefined ? "" : data?.endDate?.split("T")[0]
  );
  const [numberOfPeople, setNumberOfPeople] = useState(data.numberOfPeople);
  const [loading, setLoading] = useState(false);

  const [roomType, setRoomType] = useState("");
  const [mealPlan, setMealPlan] = useState(
    data.mealPlan === undefined ? "" : data.mealPlan
  );
  const [transfers, setTransfers] = useState(
    data.transfers === undefined ? "" : data.transfers
  );
  const [itinerary, setItinerary] = useState("");
  const [price, setPrice] = useState("");
  const [totalPrice, setTotalPrice] = useState("");
  const [flight, setFlight] = useState("");

  const [opt1, setOpt1] = useState("");
  const [opt2, setOpt2] = useState("");
  const [opt3, setOpt3] = useState("");
  const [opt4, setOpt4] = useState("");
  const [opt5, setOpt5] = useState("");
  const [mealVal, setMealVal] = useState("");
  const [opt6, setOpt6] = useState("");
  const [resorts, setResorts] = useState([]);
  const [resortText, setResortText] = useState("");
  const [amount, setAmount] = useState("");
  const [instalment, setInstalment] = useState("");
  const [parts, setParts] = useState(
    data.paymentType.parts.length === 0
      ? []
      : data.paymentType.parts.map((data) => data.amount)
  );

  const [complimentary, setComplimentary] = useState(
    data.complimentory === undefined ? "" : data.complimentory
  );
  const [activities, set_activities] = useState(
    data.activities === undefined ? "" : data.activities
  );

  const [arr, setArr] = useState(resorts.map((val) => false));
  const toast = useToast();

  const getPkgData = async () => {
    try {
      const res = await GetPackageById(data.packageId);
      console.log("res data is", res);

      setRoomType(
        data.roomType === undefined
          ? res.data.package[0].roomType
          : data.roomType
      );
      setPrice(
        data.price === undefined
          ? res.data.package[0].startingPrice
          : data.price
      );

      
      setFlight(data.flightDetails[0]);
      // setResorts(res.data.package.resorts);
      setOpt1(
        data.inclusion === undefined
          ? res.data.package[0].inclusion.split(",")[0]
          : data.inclusion.split(",")[0]
      );
      setOpt2(
        data.inclusion === undefined
          ? res.data.package[0].inclusion.split(",")[1]
          : data.inclusion.splice(",")[1]
      );
      setOpt3(
        data.inclusion === undefined
          ? res.data.package[0].inclusion.split(",")[2].split("-")[0]
          : data.inclusion.split(",")[2].split("-")[0]
      );
      setMealVal(
        data.inclusion === undefined
          ? "-" + res.data.package[0].inclusion.split(",")[2].split("-")[1]
          : "-" + data.inclusion.split(",")[2].split("-")[1]
      );
      addInclusion(
        data.inclusion === undefined ? res.data.package[0].inclusion : ""
      );
      setOpt4(
        data.inclusion === undefined
          ? res.data.package[0].inclusion.split(",")[3]
          : data.inclusion.split(",")[3]
      );
      setOpt5(
        activities === undefined || activities === "" ? "" : "Activities"
      );

      setItinerary(
        data.itinerary === undefined
          ? res.data.package[0].itinerary
          : data.itinerary
      );

      setComplimentary(
        data.complimentory === undefined
          ? res.data.package[0].complimentory
          : data.complimentory
      );

      set_activities(
        data.activities === undefined
          ? res.data.package[0].activities
          : data.activities
      );
      

      setResorts(
        data.resorts[0] === undefined || data.resorts.length === 0
          ? res.data.package[0].resorts.values
          : data.resorts[0].values
      );
      // addResorts(
      //   data.resorts[0] === undefined
      //     ? res.data.package[0].resorts.values
      //     : data.resorts[0].values
      // );
      setOpt6(
        complimentary === undefined || complimentary === ""
          ? ""
          : "Complimentary"
      );

    } catch (error) { }
  };

  useEffect(() => {
    if (state) {
      onOpen();
      console.log("data for this modal is : ", data);
      getPkgData();
    }
  }, [state]);

  const closeEditModal = () => {
    changeState(false);
    onClose();
  };

  const handleChange = (val) => {
    console.log("handleing change");
    console.log("arrary is ", arr);
    console.log("change from ", val);
    if (arr[val]) {
      setArr((prev) => {
        prev[val] = false;
        return [...prev];
      });
      setCheckedResorts((prev) => prev - 1);
    } else {
      if (checkedResorts === 3) {
      } else {
        setArr((prev) => {
          prev[val] = true;
          return [...prev];
        });
        setCheckedResorts((prev) => prev + 1);
      }
    }
    console.log("after change : ", arr);
  };

  const handleSubmit = async () => {
    console.log(resorts);
    let sum = 0;
    if (parts.length !== 0) {
      parts.forEach((item) => {
        sum += parseInt(item);
      });
    }
    console.log("sum", sum);

    if (parts.length !== 0 && sum !== price*numberOfPeople) {
      toast({
        title: "Error",
        description: "Part Prices does not sum up to Total Price",
        status: "error",
        duration: 6000,
        isClosable: true,
      });
    } else {
      setLoading(true);
      try {
        const res = await updateRequestPackage(
          data._id,
          startDate,
          endDate,
          numberOfPeople,
          data.location,
          resorts,
          roomType,
          mealPlan,
          transfers,
          opt1 +
          "," +
          opt2 +
          "," +
          opt3 +
          mealVal +
          "," +
          opt4 +
          "," +
          opt5 +
          "," +
          opt6,
          itinerary,
          price,
          flight,
          data.paymentStatus,
          {
            parts: parts.map((data) => {
              return { amount: data, status: false };
            }),
            normal: { amount: price, status: false },
          },
          complimentary,
          activities
        );
        console.log("this is the output : ", res);
        setLoading(false);
        onClose();
        changeState(false);
        // reFetch((prev) => !prev);
        window.location.reload();
      } catch (error) {
        console.log("this is the output : ", error);
        setLoading(false);
        onClose();
        changeState(false);
      }
    }
  };

  return (
    <Modal size={"full"} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent w="100%" h="100%" bg="transparent" position={"relative"}>
        {console.log("data here is : ", data)}
        <Box
          w="900px"
          maxH={"90vh"}
          bg="#fff"
          position={"absolute"}
          top="50%"
          left="50%"
          transform="translate(-50%,-50%)"
          borderRadius={"10px"}
          py="20px"
          px="30px"
          overflow={"scroll"}
        >
          <Box
            display={"flex"}
            alignItems="center"
            justifyContent={"space-between"}
          >
            <Text fontSize={25} fontWeight={600}>
              Edit Requested Package
            </Text>
            <CloseIcon
              cursor={"pointer"}
              onClick={() => {
                changeState(false);
                onClose();
              }}
            />
          </Box>
          {/* this will contain the editable fields */}
          <Box
            display={"grid"}
            pt="20px"
            gridTemplateColumns={"1fr 1fr"}
            gap="20px 20px"
          >
            <Box display={"flex"} flexDir="column" alignItems="flex-start">
              <Text>CheckIn</Text>
              <Input
                type="date"
                value={startDate}
                onChange={(e) => {
                  setStartDate(e.target.value);
                }}
              />
            </Box>
            <Box display={"flex"} flexDir="column" alignItems="flex-start">
              <Text>CheckOut</Text>
              <Input
                type="date"
                value={endDate}
                onChange={(e) => {
                  setEndDate(e.target.value);
                }}
              />
            </Box>
            <Box display={"flex"} flexDir="column" alignItems="flex-start">
              <Text whiteSpace={"nowrap"}>Number of travelers</Text>
              <Input
                type="number"
                value={numberOfPeople}
                onChange={(e) => {
                  setNumberOfPeople(e.target.value);
                }}
              />
            </Box>
            <Box display={"flex"} flexDir="column" alignItems="flex-start">
              <Text whiteSpace={"nowrap"}>Room Type</Text>
              <Input
                type="text"
                value={roomType}
                onChange={(e) => {
                  setRoomType(e.target.value);
                }}
              />
            </Box>
            <Box display={"flex"} flexDir="column" alignItems="flex-start">
              <Text whiteSpace={"nowrap"}>Meal Plan</Text>
              <Input
                type="text"
                value={mealPlan}
                onChange={(e) => {
                  setMealPlan(e.target.value);
                }}
              />
            </Box>
            <Box display={"flex"} flexDir="column" alignItems="flex-start">
              <Text>Transfers</Text>
              <Input
                type="text"
                value={transfers}
                onChange={(e) => {
                  setTransfers(e.target.value);
                }}
              />
            </Box>
            <Box display={"flex"} flexDir="column" alignItems="flex-start">
              <Text>Itinerary</Text>
              <ReactQuill
              placeholder="create new itinerary"
              onChange={setItinerary}
              value={itinerary}
            />
          

            </Box>
            <Box display={"flex"} flexDir="column" alignItems="flex-start">
              <Text>Price (Including All Taxes)</Text>
              <Input
                type="text"
                value={price}
                onChange={(e) => {
                  setPrice(e.target.value);
                }}
              />
            </Box>
            <Box display={"flex"} flexDir="column" alignItems="flex-start" mt={"10%"}>
              <Text whiteSpace={"nowrap"}>Flight details</Text>
              <Input
                type="text"
                value={flight}
                onChange={(e) => {
                  setFlight(e.target.value);
                }}
              />
            </Box>
            <Box display={"flex"} flexDir="column" mt={"10%"}>
              <Text>Resorts</Text>
              <Box display={"flex"} gap="10px">
                <Input
                  type="text"
                  value={resortText}
                  onChange={(e) => {
                    setResortText(e.target.value);
                  }}
                />
                <Button
                  bg="gray.300"
                  w="100px"
                  isDisabled={resorts.length === 5 ? true : false}
                  onClick={() => {
                    setResorts((prev) => {
                      prev.push(resortText);
                      return [...prev];
                    });
                    setResortText("");
                  }}
                >
                  Add
                </Button>
              </Box>
              <Box>
                {resorts.map((data, index) => {
                  return (
                    <Box
                      mt="10px"
                      key={index}
                      display="inline-flex"
                      alignItems={"center"}
                      gap="10px"
                      px="10px"
                      bg="rgba(0,0,0,.2)"
                      ml="10px"
                      borderRadius={"5px"}
                    >
                      <Text>{data}</Text>
                      <CloseIcon
                        fontSize={"12px"}
                        cursor="pointer"
                        color="gray.100"
                        onClick={() => {
                          setResorts((prev) => {
                            prev.splice(index, 1);
                            return [...prev];
                          });
                        }}
                      />
                    </Box>
                  );
                })}
              </Box>
            </Box>

           

            <Box display={"flex"} flexDir="column" alignItems={"flex-start"}>
            <Box display={"flex"} flexDir="column" alignItems="flex-start">
              <Text>Total Price</Text>
              <Input
              disabled
                  type="number"
                  placeholder="amount"
                  value={price*numberOfPeople}
                />
            </Box>
              <Text>Part Payment</Text>
              <Box display={"flex"} gap="10px" alignItems={"center"}>
                <Input
                  type="number"
                  placeholder="amount"
                  value={amount}
                  onChange={(e) => {
                    setAmount(e.target.value);
                  }}
                />
                <Button
                  bg="gray.300"
                  w="100px"
                  onClick={() => {
                    setParts((prev) => {
                      prev.push(`${amount}`);
                      return [...prev];
                    });
                    setAmount("");
                    setInstalment("");
                  }}
                >
                  Add
                </Button>
              </Box>
              <Box>
                {parts.map((data, index) => {
                  return (
                    <Box
                      mt="10px"
                      key={index}
                      display="flex"
                      alignItems={"center"}
                      gap="10px"
                      px="10px"
                      py="5px"
                      bg="rgba(0,0,0,.1)"
                      ml="10px"
                      borderRadius={"5px"}
                      justifyContent="space-between"
                    >
                      <Text>
                        {index + 1}. {data}
                      </Text>
                      <CloseIcon
                        fontSize={"12px"}
                        cursor="pointer"
                        color="black"
                        onClick={() => {
                          setParts((prev) => {
                            prev.splice(index, 1);
                            return [...prev];
                          });
                        }}
                      />
                    </Box>
                  );
                })}
              </Box>
            </Box>
            <Box
              // gridColumnStart={1}
              // gridColumnEnd={3}
              display={"flex"}
              flexDir="column"
              gap="10px"
            >
              <Text>Inclusions</Text>
              <Box display={"grid"} gridTemplateColumns={"repeat(2,1fr)"}>
                <Checkbox
                  value="Hotel"
                  isChecked={opt1 === "Hotel" ? true : false}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setOpt1(e.target.value);
                    } else {
                      setOpt1("");
                    }
                  }}
                >
                  Hotel
                </Checkbox>
                <Checkbox
                  value="Transfers"
                  isChecked={opt2 === "Transfers" ? true : false}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setOpt2(e.target.value);
                    } else {
                      setOpt2("");
                    }
                  }}
                >
                  Transfers
                </Checkbox>
                <Box>
                  <Checkbox
                    value="Meal"
                    isChecked={opt3 === "Meal" ? true : false}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setOpt3(e.target.value);
                      } else {
                        setOpt3("");
                        setMealVal("");
                      }
                    }}
                  >
                    Meal
                  </Checkbox>
                  {opt3 === "Meal" ? (
                    <RadioGroup onChange={setMealVal} value={mealVal}>
                      <Stack position={"relative"} left="20px" mb="10px">
                        <Radio value="-BB">BB</Radio>
                        <Radio value="-HB">HB</Radio>
                        <Radio value="-FB">FB</Radio>
                        <Radio value="-AI">AI</Radio>
                      </Stack>
                    </RadioGroup>
                  ) : (
                    <></>
                  )}
                </Box>
                <Checkbox
                  value="Flights"
                  isChecked={opt4 === "Flights" ? true : false}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setOpt4(e.target.value);
                    } else {
                      setOpt4("");
                    }
                  }}
                >
                  Flights
                </Checkbox>
                <Box
                  gridColumnStart={opt5 === "Activities" ? 1 : ""}
                  gridColumnEnd={opt5 === "Activities" ? 3 : ""}
                  display="flex"
                  gap="5px"
                  mt={opt5 === "Activities" ? "5px" : "0"}
                  mb={opt5 === "Activities" ? "5px" : "0"}
                >
                  <Checkbox
                    value="Activities"
                    isChecked={opt5 === "Activities" ? true : false}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setOpt5(e.target.value);
                      } else {
                        setOpt5("");
                        set_activities("");
                      }
                    }}
                  >
                    Activities
                  </Checkbox>
                  {opt5 === "Activities" ? (
                    <Input
                      type={"text"}
                      value={activities}
                      onChange={(e) => {
                        set_activities(e.target.value);
                      }}
                    />
                  ) : (
                    <></>
                  )}
                </Box>
                <Box
                  gridColumnStart={opt6 === "Complimentary" ? 1 : ""}
                  gridColumnEnd={opt6 === "Complimentary" ? 3 : ""}
                  display="flex"
                  gap="5px"
                  mt={opt6 === "Complimentary" ? "5px" : "0"}
                  mb={opt6 === "Complimentary" ? "5px" : "0"}
                >
                  <Checkbox
                    value="Complimentary"
                    isChecked={opt6 === "Complimentary" ? true : false}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setOpt6(e.target.value);
                      } else {
                        setOpt6("");
                        setComplimentary("");
                      }
                    }}
                  >
                    Complimentary
                  </Checkbox>
                  {opt6 === "Complimentary" ? (
                    <Input
                      type={"text"}
                      value={complimentary}
                      onChange={(e) => {
                        setComplimentary(e.target.value);
                      }}
                    />
                  ) : (
                    <></>
                  )}
                </Box>
              </Box>
            </Box>
          </Box>
          <Box display={"flex"} justifyContent="space-between" pt="30px">
            <Button onClick={closeEditModal}>Cancel</Button>
            <Button
              colorScheme={"green"}
              onClick={handleSubmit}
              isLoading={loading}
            >
              Save
            </Button>
          </Box>
        </Box>
      </ModalContent>
    </Modal>
  );
};
export default RequestsEditModal;
