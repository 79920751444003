import { useState, useRef } from 'react';
import {
	useDisclosure,
	AlertDialog,
	AlertDialogOverlay,
	AlertDialogContent,
	AlertDialogHeader,
	AlertDialogBody,
	AlertDialogFooter,
	Button,
	Tr,
	Td,
	Box,
} from '@chakra-ui/react';
import EditBrand from './EditBrand';
import { DeleteIcon, EditIcon } from '@chakra-ui/icons';
import deleteBrand from '../../api/deleteBrand';

const SingleBrand = ({ data, changeState }) => {
	const [state, setState] = useState();
	const [loading, setLoading] = useState(false);
	const { isOpen, onOpen, onClose } = useDisclosure();
	const cancleRef = useRef();

	const handleDelete = async () => {
		setLoading(true);
		try {
			const res = await deleteBrand(data._id);
			console.log('response is ', res);
			setLoading(false);
			changeState((prev) => !prev);
			onClose();
		} catch (error) {
			console.log('error : ', error);
		}
	};

	return (
		<>
			<EditBrand state={state} setState={setState} data={data} />
			<AlertDialog
				isOpen={isOpen}
				onClose={onClose}
				leastDestructiveRef={cancleRef}
			>
				<AlertDialogOverlay>
					<AlertDialogContent>
						<AlertDialogHeader fontSize={'lg'} fontWeight={'bold'}>
							Delete Agent
						</AlertDialogHeader>
						<AlertDialogBody>
							Are you sure? You can't undo this action afterwards.
						</AlertDialogBody>
						<AlertDialogFooter>
							<Button ref={cancleRef} onClick={onClose}>
								Cancel
							</Button>
							<Button
								colorScheme='red'
								onClick={handleDelete}
								ml={3}
								isLoading={loading}
							>
								Delete
							</Button>
						</AlertDialogFooter>
					</AlertDialogContent>
				</AlertDialogOverlay>
			</AlertDialog>
			<Tr>
				<Td textAlign={'center'}>{data.firstName}</Td>
				<Td textAlign={'center'}>{data.lastName}</Td>
				<Td textAlign={'center'}>{data.email}</Td>
				<Td textAlign={'center'}>{data.mobileNumber}</Td>
				<Td textAlign={'center'}>{data.companyName}</Td>
				<Td textAlign={'center'}>{data.companyWebsite}</Td>
				<Td textAlign={'center'}>{data.businessType}</Td>
				<Td textAlign={'center'}>
					<Box>
						{/* <i className='icofont-ui-delete' color='red'></i> */}
						<EditIcon
							cursor='pointer'
							onClick={() => {
								setState(true);
							}}
						/>
					</Box>
				</Td>
				<Td textAlign={'center'}>
					<Box>
						{/* <i className='icofont-ui-delete' color='red'></i> */}
						<DeleteIcon
							color={'red'}
							cursor='pointer'
							onClick={() => {
								onOpen();
							}}
						/>
					</Box>
				</Td>
			</Tr>
		</>
	);
};

export default SingleBrand;
