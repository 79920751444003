import { CloseIcon, DeleteIcon, EditIcon } from '@chakra-ui/icons';
import {
	Box,
	Text,
	Button,
	Icon,
	TableContainer,
	Table,
	Tr,
	Th,
	Thead,
	Tbody,
	Menu,
	MenuButton,
	MenuList,
	MenuItem,
	useDisclosure,
	Modal,
	ModalOverlay,
	ModalContent,
	useToast,
	Input,
	Td,
} from '@chakra-ui/react';
import { upload } from '@testing-library/user-event/dist/upload';
import { useEffect, useRef, useState } from 'react';
import { HiUpload } from 'react-icons/hi';
import { IoMdAdd, IoMdThermometer } from 'react-icons/io';
import { useNavigate, useSearchParams } from 'react-router-dom';
import GetPackagesList from '../../api/GetPackagesList';
import DeletePackageApi from '../../api/DeletePackageApi';
import './packages.css';
import Pkg from './Pkg';
import ActivePackages from './ActivePackages';
import ArchivedPackages from './ArchivedPackages';

const Packages = () => {
	const [selected, set_selected] = useState('Active');

	return (
		<Box h={'89vh'} mx={{ base: 1, lg: '10px' }} borderRadius='md'>
			<Box display={'inline-flex'} bg='transparent' h='50px'>
				<Text
					fontSize={'20px'}
					fontWeight={600}
					bg={selected === 'Active' ? 'white' : 'gray.200'}
					color={selected === 'Active' ? 'black' : 'gray.400'}
					display={'flex'}
					alignItems='center'
					justifyContent={'center'}
					px='20px'
					borderRadius={'15px 15px 0 0'}
					cursor='pointer'
					onClick={() => {
						set_selected('Active');
					}}
					position='relative'
					_after={
						selected === 'Active'
							? {
									content: '""',
									position: 'absolute',
									height: '0px',
									width: '0px',
									left: '100%',
									bottom: '0',
									borderBottom: '5px solid white',
									borderRight: '5px solid transparent',
									zIndex: '1000',
							  }
							: {}
					}
				>
					Live
				</Text>
				<Text
					fontSize={'20px'}
					fontWeight={600}
					bg={selected === 'Archived' ? 'white' : 'gray.200'}
					color={selected === 'Archived' ? 'black' : 'gray.400'}
					display={'flex'}
					alignItems='center'
					justifyContent={'center'}
					px='20px'
					borderRadius={'15px 15px 0 0'}
					cursor='pointer'
					onClick={() => {
						set_selected('Archived');
					}}
					position='relative'
					_after={
						selected === 'Archived'
							? {
									content: '""',
									position: 'absolute',
									height: '0px',
									width: '0px',
									left: '100%',
									bottom: '0',
									borderBottom: '5px solid white',
									borderRight: '5px solid transparent',
									zIndex: '1000',
							  }
							: {}
					}
				>
					Archived
				</Text>
			</Box>
			{selected === 'Active' ? <ActivePackages /> : <></>}
			{selected === 'Archived' ? (
				<ArchivedPackages></ArchivedPackages>
			) : (
				<></>
			)}
		</Box>
	);
};

// const Packages = () => {
// 	const navigate = useNavigate();
// 	const selectFile = useRef();
// 	const toast = useToast();
// 	const [packages, setPackages] = useState([]);
// 	const [loading, setLoading] = useState(true);
// 	const { isOpen, onOpen, onClose } = useDisclosure();
// 	const [state, setState] = useState(true);

// 	useEffect(() => {
// 		const getPackages = async () => {
// 			const count = 10;
// 			const page = 1;

// 			try {
// 				const response = await GetPackagesList(count, page);
// 				setPackages(response.data.packages);
// 				setLoading(false);
// 			} catch (error) {
// 				console.log(error);
// 				setLoading(false);
// 			}
// 		};

// 		getPackages();
// 	}, [state]);

// 	const handleUploadBtn = () => {
// 		onOpen();
// 	};

// 	const handleSelectedFile = (e) => {
// 		console.log(e.target.files);
// 	};

// 	const uploadFile = (file) => {
// 		const name = file.name.split('.');
// 		if (name[name.length - 1] !== 'xls') {
// 			console.log('wrong file');
// 			toast({
// 				position: 'top-right',
// 				title: 'Error',
// 				description: 'please upload excel files only',
// 				status: 'error',
// 				duration: 6000,
// 				isClosable: true,
// 			});
// 		} else {
// 			console.log('right file time to upload');
// 		}
// 	};

// 	const handleDropedFile = (e) => {
// 		let dt = e.dataTransfer;
// 		let files = dt.files;
// 		[...files].forEach(uploadFile);
// 	};

// 	return (
// 		<>
// 			{/* modal to upload the excel file */}
// 			<Modal isOpen={isOpen} onClose={onClose} size='full'>
// 				<ModalOverlay />
// 				<ModalContent
// 					bg='transparent'
// 					display={'flex'}
// 					justifyContent='center'
// 					alignItems='center'
// 				>
// 					<Box
// 						w='500px'
// 						h='fit-content'
// 						bg='#f5f5f5'
// 						// top='50%'
// 						// left='50%'
// 						// transform={'translate(-50%,-50%)'}
// 						borderRadius='xl'
// 						pt={'30px'}
// 						pl='20px'
// 						pb='20px'
// 						pr='20px'
// 						position={'relative'}
// 					>
// 						{/* close button */}
// 						<Box
// 							position={'absolute'}
// 							right={'30px'}
// 							display='inline-block'
// 							cursor={'pointer'}
// 							onClick={() => {
// 								onClose();
// 							}}
// 						>
// 							<CloseIcon />
// 						</Box>
// 						{/* heading */}
// 						<Text fontSize={20} textAlign='center' mb='20px'>
// 							{' '}
// 							Upload Excel
// 						</Text>
// 						<Box></Box>
// 						<Box
// 							w='100%'
// 							h='300px'
// 							bg='lightblue'
// 							display={'flex'}
// 							alignItems='center'
// 							justifyContent={'center'}
// 							borderRadius='md'
// 							color={'gray.600'}
// 							cursor='pointer'
// 							onDragEnter={(e) => {
// 								e.preventDefault();
// 								e.target.classList.add('highlight');
// 							}}
// 							onDragLeave={(e) => {
// 								e.preventDefault();
// 								e.target.classList.remove('highlight');
// 							}}
// 							onDragOver={(e) => {
// 								e.preventDefault();
// 								e.target.classList.add('highlight');
// 							}}
// 							onDrop={(e) => {
// 								e.preventDefault();
// 								e.target.classList.remove('highlight');
// 								handleDropedFile(e);
// 							}}
// 							onClick={(e) => {
// 								selectFile.current.click();
// 							}}
// 						>
// 							<Input
// 								type='file'
// 								display={'none'}
// 								ref={selectFile}
// 								onChange={handleSelectedFile}
// 							/>
// 							Drop file here or click to browse
// 						</Box>
// 					</Box>
// 				</ModalContent>
// 			</Modal>
// 			<Box
// 				// w={{ base: '100%', lg: '86vw' }}
// 				h={'89vh'}
// 				bg='white'
// 				mx={{ base: 1, lg: '10px' }}
// 				borderRadius='md'
// 				boxShadow={'rgba(0, 0, 0, 0.24) 0px 3px 8px'}
// 				overflowY='scroll'
// 				pl={5}
// 				pr={5}
// 				pt={5}
// 			>
// 				<Box
// 					display={'flex'}
// 					justifyContent='space-between'
// 					alignItems='center'
// 					mb={10}
// 				>
// 					<Text fontSize={30} fontWeight={500}></Text>
// 					<Button display={'flex'} gap={3} color='gray.600'>
// 						<Menu>
// 							<MenuButton> + Add Packages </MenuButton>
// 							<MenuList>
// 								<MenuItem
// 									display={'flex'}
// 									gap={2}
// 									onClick={handleUploadBtn}
// 								>
// 									<Icon as={HiUpload} /> Upload
// 								</MenuItem>
// 								<MenuItem
// 									display={'flex'}
// 									gap={2}
// 									onClick={() => {
// 										navigate('/dashboard/packages/create');
// 									}}
// 								>
// 									<Icon as={IoMdAdd} /> Create
// 								</MenuItem>
// 							</MenuList>
// 						</Menu>
// 					</Button>
// 				</Box>

// 				<TableContainer variant={'striped'} overflow='scroll' mt={3}>
// 					<Table>
// 						<Thead>
// 							<Tr>
// 								<Th textAlign={'center'} fontSize={'1.1rem'}>
// 									Package Id
// 								</Th>
// 								<Th textAlign={'center'} fontSize={'1.1rem'}>
// 									Title
// 								</Th>
// 								<Th
// 									textAlign={'center'}
// 									fontSize={'1.1rem'}
// 								></Th>
// 								<Th
// 									textAlign={'center'}
// 									fontSize={'1.1rem'}
// 								></Th>
// 							</Tr>
// 						</Thead>
// 						{loading ? (
// 							<></>
// 						) : (
// 							<Tbody>
// 								{packages.map((item, index) => {
// 									return (
// 										<Pkg
// 											item={item}
// 											key={index}
// 											changeState={setState}
// 										/>
// 									);
// 								})}
// 							</Tbody>
// 						)}
// 					</Table>
// 				</TableContainer>
// 			</Box>
// 		</>
// 	);
// };

export default Packages;
