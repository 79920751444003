import React from 'react';
import AllRoutes from './routes/Routes';

const App = () => {
	return (
		<>
			<AllRoutes />
		</>
	);
};

export default App;
