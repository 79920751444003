import {
  Box,
  Text,
  Input,
  Select,
  Textarea,
  Checkbox,
  Image,
  Button,
  toast,
  RadioGroup,
  Stack,
  Radio,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Popover,
  useDisclosure,
} from "@chakra-ui/react";
import { CloseIcon, AddIcon, Icon } from "@chakra-ui/icons";
import ReactQuill from "react-quill";
import { useRef, useState } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import UpdatePackageApi from "../../../api/UpdatePackageApi";
import { BsFillPlayFill } from "react-icons/bs";
import { AiFillStar } from "react-icons/ai";

const UpdatePackage = () => {
  const navigate = useNavigate();
  const data = useLocation().state;
  console.log("data is", data);

  const [packageTitle, setPackageTitle] = useState(data.packageTitle);
  const [category, setCategory] = useState(data.category[0]);
  const [packageDetail1, setPackageDetail1] = useState(data.packageDetail1);
  const [packageDetail2, setPackageDetail2] = useState(data.packageDetail2);
  const [packageDetail3, setPackageDetail3] = useState(data.packageDetail3);
  const [packageDetail4, setPackageDetail4] = useState(data.packageDetail4);
  const [destination, setDestination] = useState(data.destination);
  const [star, setStar] = useState(
    data.star === undefined || data.star === "" ? "" : data.star
  );
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [roomType, setRoomType] = useState(data.roomType);
  const [itinerary, setItineary] = useState(data.itinerary);
  const [flight, setFlight] = useState(
    data.flightDetails === undefined ? "" : data.flightDetails.details
  );
  const [activity, setActivity] = useState(
    data.activity === undefined ? "" : data.activity
  );
  const [transfer, setTransfer] = useState(
    data.transferVal === undefined ? "" : data.transferVal
  );

  const [bpStart, setBPStart] = useState(data.bookingPeriod.start);
  const [bpEnd, setBPEnd] = useState(data.bookingPeriod.end);

  const [spStart, setSPStart] = useState(data.stayPeriod[0]);
  const [spEnd, setSPEnd] = useState(data.stayPeriod[1]);

  const [resort, setResort] = useState(
    data.resorts.values ? data.resorts.values : []
  );
  const [resortText, setResortText] = useState("");

  const [imageObject, setImageObject] = useState([]);
  const packageid = data._id;

  const [opt1, setOpt1] = useState(
    data.inclusion === true || data.inclusion === false
      ? ""
      : data.inclusion.split(",")[0]
  );

  const [opt2, setOpt2] = useState(
    data.inclusion === true || data.inclusion === false
      ? ""
      : data.inclusion.split(",")[1]
  );

  const [mealVal, setMealVal] = useState(
    data.inclusion.split(",")[2].split("-")[0] === "Meal"
      ? "-" + data.inclusion.split(",")[2].split("-")[1]
      : ""
  );

  const [opt3, setOpt3] = useState(
    data.inclusion === true || data.inclusion === false
      ? ""
      : data.inclusion.split(",")[2].split("-")[0]
  );

  const [opt4, setOpt4] = useState(
    data.inclusion === true || data.inclusion === false
      ? ""
      : data.inclusion.split(",")[3]
  );

  const [opt5, setOpt5] = useState(
    data.inclusion === true || data.inclusion === false
      ? ""
      : data.inclusion.split(",")[4]
  );

  const [startingPrice, setStartingPrice] = useState(data.startingPrice);
  const [isImageSelected, setIsImageSelected] = useState(true);
  const [uploadedImage, setUploadedImage] = useState(
    data.image.map((item) => {
      return {
        url: item,
        type: item.resource_type === "video" ? false : true,
      };
    })
  );

  const imageRef = useRef();
  const [newImageblob, setNewImageBlob] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleImageSubmit = (e) => {
    let fileList = [...e.target.files];
    if (fileList.length + uploadedImage.length + newImageblob.length > 4) {
      toast({
        title: "Error",
        description: "4 image are allowed at max",
        status: "error",
      });
    } else {
      setNewImageBlob((prev) => {
        let val = fileList.map((item) => {
          return {
            url: URL.createObjectURL(item),
            type: item.type.split("/")[0] === "video" ? false : true,
          };
        });
        prev.push(...val);
        console.log("prev is ", prev);
        return [...prev];
      });

      setImageObject((prev) => {
        prev.push(...fileList);
        return [...prev];
      });
      setIsImageSelected(true);
    }
  };

  const handleImageRemoval = (index) => {
    if (uploadedImage.length === 1) {
      setUploadedImage([]);
    }

    setUploadedImage((prev) => {
      prev.splice(index, 1);
      return [...prev];
    });
  };

  const handleSecondImageRemoval = (index) => {
    if (newImageblob.length === 1) {
      setNewImageBlob([]);
    }

    setNewImageBlob((prev) => {
      prev.splice(index, 1);
      return [...prev];
    });
  };

  const uploadImageToCloud = async () => {
    console.log("I am in image upload Function");

    const imageLinks = [];

    for (let i = 0; i < imageObject.length; i++) {
      try {
        const file = imageObject[i];
        const formData = new FormData();
        formData.append("file", file);
        formData.append(
          "upload_preset",
          file.type.split("/")[0] === "video"
            ? process.env.REACT_APP_CLOUD_PRESET_VIDEO
            : process.env.REACT_APP_CLOUD_PRESET_IMAGE
        );
        formData.append("cloud_name", process.env.REACT_APP_CLOUD_NAME);
        try {
          let response = await fetch(
            `https://api.cloudinary.com/v1_1/${process.env.REACT_APP_CLOUD_NAME}/upload`,
            {
              method: "post",
              body: formData,
            }
          );

          response = await response.json();
          console.log("cloud object we got : ", response);
          imageLinks.push(response);
          // .then((response) => response.json())
          // .then((data) => {
          // 	console.log(data);
          // 	imageLinks.append(data);
          // });
        } catch (error) {
          console.log("something went wrong");
          console.log(error);
        }
        // imageLinks.push(response.data);
      } catch (error) {
        console.log(error);
      }
    }
    return imageLinks;
  };

  const handleUpdate = async () => {
    setLoading(true);
    if (
      packageTitle &&
      category &&
      packageDetail1 &&
      startingPrice &&
      uploadedImage
    ) {
      // first let upload the images :
      let imagelinklist = await uploadImageToCloud();
      console.log("image links we get : ", imagelinklist);
      const main_image_list = uploadedImage.map((item) => item.url);
      main_image_list.push(...imagelinklist);
      console.log("we got image link list : ", uploadedImage);
      try {
        const response = await UpdatePackageApi(
          data.packageId,
          packageTitle,
          destination,
          category,
          packageDetail1,
          packageDetail2,
          packageDetail3,
          packageDetail4,
          { start: bpStart, end: bpEnd },
          { start: spStart, end: spEnd },
          opt1 + "," + opt2 + "," + opt3 + mealVal + "," + opt4 + "," + opt5,
          startingPrice,
          main_image_list,
          resort,
          roomType,
          itinerary,
          flight,
          star,
          activity,
          transfer
        );

        console.log("response is : ", response);
        setLoading(false);
        navigate("/dashboard/packages/");
      } catch (error) {
        console.log("error is :", error);
        setLoading(false);
      }
    }
  };

  return (
    <>
      <Box
        // w={{ base: '100%', lg: '86vw' }}
        h={"89vh"}
        bg="white"
        mx={{ base: 1, lg: "10px" }}
        borderRadius="md"
        boxShadow={"rgba(0, 0, 0, 0.24) 0px 3px 8px"}
        overflowY="scroll"
        pl={5}
        pr={5}
        pt={5}
        pb={10}
      >
        {console.log("added video list is : ", uploadedImage)}
        <Box
          display={"flex"}
          justifyContent="space-between"
          alignItems="center"
          mb={10}
        >
          <Text fontSize={30} fontWeight={500}>
            Edit Packages
          </Text>
        </Box>
        <Box
          pl="40px"
          pr="50px"
          display={"grid"}
          gridTemplateColumns="repeat(2,1fr)"
          gap="0px 40px"
        >
          <Box mb="20px">
            <Text fontSize={20}>Destination </Text>
            <Input
              value={destination}
              type="text"
              w="400px"
              ml={"10px"}
              onChange={(e) => setDestination(e.target.value)}
            />
          </Box>
          <Box></Box>
          <Box>
            <Text fontSize={20}>Title : </Text>
            <Input
              value={packageTitle}
              type="text"
              w="400px"
              ml={"10px"}
              onChange={(e) => setPackageTitle(e.target.value)}
            />
          </Box>
          <Box>
            <Text fontSize={20} display="flex">
              Category :
            </Text>
            <Select
              placeholder="Select option"
              ml={"10px"}
              w="300px"
              value={category}
              onChange={(e) => {
                setCategory(e.target.value);
              }}
            >
              <option value="Leisure">Leisure</option>
              <option value="Adventure">Adventure</option>
            </Select>
          </Box>
          <Box mt="20px" mb="20px">
            <Text fontSize={20}>Package Detail 1 : </Text>
            <Textarea
              ml={"10px"}
              value={packageDetail1}
              onChange={(e) => setPackageDetail1(e.target.value)}
            />
          </Box>
          <Box mt="20px" mb="20px">
            <Text fontSize={20}>Package Detail 2 : </Text>
            <Textarea
              ml={"10px"}
              value={packageDetail2}
              onChange={(e) => setPackageDetail2(e.target.value)}
            />
          </Box>
          <Box mt="20px" mb="20px">
            <Text fontSize={20}>Package Detail 3 : </Text>
            <Textarea
              ml={"10px"}
              value={packageDetail3}
              onChange={(e) => setPackageDetail3(e.target.value)}
            />
          </Box>
          <Box mt="20px" mb="20px">
            <Text fontSize={20}>Package Detail 4 : </Text>
            <Textarea
              ml={"10px"}
              value={packageDetail4}
              onChange={(e) => setPackageDetail4(e.target.value)}
            />
          </Box>
          <Box mt="20px" mb="20px">
            <Text fontSize={20}>Booking Period : </Text>
            <Box
              display={"flex"}
              alignItems="center"
              gap="10px"
              position={"relative"}
              left="20px"
              mb="20px"
              w="300px"
            >
              <Text whiteSpace={"nowrap"}>Start Date</Text>
              <Input
                w="200px"
                type="date"
                value={bpStart}
                onChange={(e) => {
                  setBPStart(e.target.value);
                }}
              />
            </Box>
            <Box
              display={"flex"}
              alignItems="center"
              gap="10px"
              position={"relative"}
              left="20px"
            >
              <Text whiteSpace={"nowrap"}>End Date</Text>
              <Input
                w="200px"
                type="date"
                value={bpEnd}
                onChange={(e) => {
                  setBPEnd(e.target.value);
                }}
              />
            </Box>
          </Box>
          <Box mt="20px" mb="20px">
            <Text fontSize={20}>Stay Period : </Text>
            <Box
              display={"flex"}
              alignItems="center"
              gap="10px"
              position={"relative"}
              left="20px"
              mb="20px"
              w="300px"
            >
              <Text whiteSpace={"nowrap"}>Start Date</Text>
              <Input
                w="200px"
                type="date"
                value={spStart}
                onChange={(e) => {
                  setSPStart(e.target.value);
                }}
              />
            </Box>
            <Box
              display={"flex"}
              alignItems="center"
              gap="10px"
              position={"relative"}
              left="20px"
            >
              <Text whiteSpace={"nowrap"}>End Date</Text>
              <Input
                w="200px"
                type="date"
                value={spEnd}
                onChange={(e) => {
                  setSPEnd(e.target.value);
                }}
              />
            </Box>
          </Box>
          <Box mt="30px" mb="20px">
            <Text fontSize={20}>Stars</Text>
            <Popover isOpen={isOpen} onClose={onClose}>
              <PopoverTrigger>
                <Text
                  w="400px"
                  h="2.5rem"
                  pl="10px"
                  border={"1px solid rgba(0,0,0,.1)"}
                  borderRadius="5px"
                  display={"flex"}
                  alignItems="center"
                  cursor="pointer"
                  onClick={onOpen}
                >
                  {star === ""
                    ? "Select Option"
                    : [
                        ...Array(isNaN(parseInt(star)) ? 0 : parseInt(star)),
                      ].map((e, i) => {
                        return (
                          <Icon
                            display={"inline-block"}
                            as={AiFillStar}
                            fill="gold"
                          />
                        );
                      })}
                </Text>
              </PopoverTrigger>
              <PopoverContent w="400px">
                <PopoverBody p={0}>
                  <Box
                    px="10px"
                    py="3px"
                    _hover={{
                      background: "rgba(0,0,0,.1)",
                    }}
                    cursor="pointer"
                    onClick={() => {
                      setStar(1);
                      onClose();
                    }}
                  >
                    <Icon as={AiFillStar} fill="gold" />
                  </Box>
                  <Box
                    px="10px"
                    py="3px"
                    _hover={{
                      background: "rgba(0,0,0,.1)",
                    }}
                    cursor="pointer"
                    onClick={() => {
                      setStar(2);
                      onClose();
                    }}
                  >
                    <Icon as={AiFillStar} fill="gold" />
                    <Icon as={AiFillStar} fill="gold" />
                  </Box>
                  <Box
                    px="10px"
                    py="3px"
                    _hover={{
                      background: "rgba(0,0,0,.1)",
                    }}
                    cursor="pointer"
                    onClick={() => {
                      setStar(3);
                      onClose();
                    }}
                  >
                    <Icon as={AiFillStar} fill="gold" />
                    <Icon as={AiFillStar} fill="gold" />
                    <Icon as={AiFillStar} fill="gold" />
                  </Box>
                  <Box
                    px="10px"
                    py="3px"
                    _hover={{
                      background: "rgba(0,0,0,.1)",
                    }}
                    cursor="pointer"
                    onClick={() => {
                      setStar(4);
                      onClose();
                    }}
                  >
                    <Icon as={AiFillStar} fill="gold" />
                    <Icon as={AiFillStar} fill="gold" />
                    <Icon as={AiFillStar} fill="gold" />
                    <Icon as={AiFillStar} fill="gold" />
                  </Box>
                  <Box
                    px="10px"
                    py="3px"
                    _hover={{
                      background: "rgba(0,0,0,.1)",
                    }}
                    cursor="pointer"
                    onClick={() => {
                      setStar(5);
                      onClose();
                    }}
                  >
                    <Icon as={AiFillStar} fill="gold" />
                    <Icon as={AiFillStar} fill="gold" />
                    <Icon as={AiFillStar} fill="gold" />
                    <Icon as={AiFillStar} fill="gold" />
                    <Icon as={AiFillStar} fill="gold" />
                  </Box>
                </PopoverBody>
              </PopoverContent>
            </Popover>
          </Box>

          <Box mt="20px" mb="20px">
            <Text fontSize={20}>Starting Price : </Text>
            <Input
              type="number"
              w="200px"
              ml={"10px"}
              value={startingPrice}
              onChange={(e) => setStartingPrice(e.target.value)}
            />
          </Box>
        </Box>
        <Box
          display="flex-column"
          justifyContent="space-between"
          alignItems="center"
          pl="40px"
        >
          <Box mt="40px" mb="20px">
            <Text as="div" fontSize={20}>
              Inclusion
              <Box display={"flex"} flexDir="column">
                <Checkbox
                  value="Hotel"
                  onChange={(e) => {
                    if (e.target.checked) {
                      setOpt1(e.target.value);
                    } else {
                      setOpt1("");
                    }
                  }}
                  defaultChecked={opt1 === "Hotel" ? true : false}
                >
                  Hotel/Resort
                </Checkbox>
                {opt1 === "Hotel" ? (
                  <Box
                    pl="40px"
                    pr="50px"
                    display={"grid"}
                    gridTemplateColumns="repeat(2,1fr)"
                    gap="0px "
                  >
                    <Box mt="30px" mb="20px">
                      <Text fontSize={20}>Hotel/Resorts</Text>
                      <Box display={"flex"} gap="10px">
                        <Input
                          type="text"
                          w="250px"
                          value={resortText}
                          onChange={(e) => {
                            setResortText(e.target.value);
                          }}
                        />
                      </Box>
                      <Box>
                        {resort.map((data, index) => {
                          return (
                            <Box
                              mt="10px"
                              key={index}
                              display="inline-flex"
                              alignItems={"center"}
                              gap="10px"
                              px="10px"
                              bg="rgba(0,0,0,.2)"
                              ml="10px"
                              borderRadius={"5px"}
                            >
                              <Text>{data}</Text>
                              <CloseIcon
                                fontSize={"12px"}
                                cursor="pointer"
                                color="gray.100"
                                onClick={() => {
                                  setResort((prev) => {
                                    prev.splice(index, 1);
                                    return [...prev];
                                  });
                                }}
                              />
                            </Box>
                          );
                        })}
                      </Box>
                    </Box>

                    <Box mt="30px" mb="20px" gridColumn="2">
                      <Text fontSize={20}>Room Type</Text>
                      <Box display="flex">
                        <Input
                          type="text"
                          ml={"10px"}
                          w="250px"
                          onChange={(e) => {
                            setRoomType(e.target.value);
                          }}
                          value={roomType}
                        />
                        <Button
                          ml="10px"
                          isDisabled={resort.length === 5 ? true : false}
                          onClick={() => {
                            if (resortText !== "") {
                              setResort((prev) => {
                                prev.push(resortText);
                                return [...prev];
                              });
                              setResortText("");
                            }
                          }}
                        >
                          Add
                        </Button>
                      </Box>
                    </Box>
                  </Box>
                ) : (
                  <></>
                )}
                <Checkbox
                  value="Transfers"
                  onChange={(e) => {
                    if (e.target.checked) {
                      setOpt2(e.target.value);
                    } else {
                      setOpt2("");
                    }
                  }}
                  defaultChecked={opt2 === "Transfers" ? true : false}
                >
                  Transfers
                </Checkbox>
                {opt2 === "Transfers" ? (
                  <>
                    <Box mt="30px" mb="20px">
                      <Text fontSize={20}>Transfer</Text>
                      <Input
                        type="text"
                        ml={"10px"}
                        w="400px"
                        onChange={(e) => {
                          setTransfer(e.target.value);
                        }}
                        value={transfer}
                      />
                    </Box>
                  </>
                ) : (
                  <></>
                )}
                <Checkbox
                  value="Meal"
                  onChange={(e) => {
                    if (e.target.checked) {
                      setOpt3(e.target.value);
                    } else {
                      setOpt3("");
                    }
                  }}
                  defaultChecked={opt3 === "Meal" ? true : false}
                >
                  Meal
                </Checkbox>
                {opt3 === "Meal" ? (
                  <RadioGroup onChange={setMealVal} value={mealVal}>
                    <Stack position={"relative"} left="20px" mb="10px">
                      <Radio value="-BB">BB</Radio>
                      <Radio value="-HB">HB</Radio>
                      <Radio value="-FB">FB</Radio>
                      <Radio value="-AI">AI</Radio>
                    </Stack>
                  </RadioGroup>
                ) : (
                  <></>
                )}
                <Checkbox
                  value="Flights"
                  onChange={(e) => {
                    if (e.target.checked) {
                      setOpt4(e.target.value);
                    } else {
                      setOpt4("");
                    }
                  }}
                  defaultChecked={opt4 === "Flights" ? true : false}
                >
                  Flights
                </Checkbox>
                {opt4 === "Flights" ? (
                  <>
                    <Box mt="30px" mb="20px">
                      <Text fontSize={20}>Flight Details</Text>
                      <Input
                        type="text"
                        ml={"10px"}
                        w="400px"
                        onChange={(e) => {
                          setFlight(e.target.value);
                        }}
                        value={flight}
                      />
                    </Box>
                  </>
                ) : (
                  <></>
                )}
                <Checkbox
                  value="Activities"
                  onChange={(e) => {
                    if (e.target.checked) {
                      setOpt5(e.target.value);
                    } else {
                      setOpt5("");
                    }
                  }}
                  defaultChecked={opt5 === "Activities" ? true : false}
                >
                  Activities
                </Checkbox>
                {opt5 === "Activities" ? (
                  <>
                    <Box mt="30px" mb="20px" width={"500px"}>
                      <Text fontSize={20}>Activity</Text>
                      <ReactQuill
                        placeholder="create new activity"
                        onChange={setActivity}
                        value={activity}
                      />
                    </Box>
                  </>
                ) : (
                  <></>
                )}
              </Box>
            </Text>
          </Box>

          {/* resort */}

          <Box mt="30px" mb="20px" width="500px" gridColumn="1">
            <Text fontSize={20}>Itinerary</Text>
            <ReactQuill
              placeholder="create new itinerary"
              onChange={setItineary}
              value={itinerary}
            />
          </Box>

          <Box mt="30px" mb="20px" gridColumnStart={1} gridColumnEnd={3}>
            <Text fontSize={20}> Upload Image </Text>
            {!isImageSelected ? (
              <Box
                bg="lightblue"
                display={"inline-block"}
                ml="10px"
                color="gray.600"
                px="10px"
                py="5px"
                borderRadius="md"
                cursor={"pointer"}
                onClick={() => {
                  imageRef.current.click();
                }}
              >
                <Input
                  type="file"
                  display={"none"}
                  ref={imageRef}
                  accept="image/*, video/*"
                  multiple
                  onChange={handleImageSubmit}
                />
                Upload Image
              </Box>
            ) : (
              <></>
            )}
            {isImageSelected ? (
              <Box display={"flex"} alignItems="end" gap="10px">
                {uploadedImage.map((item, index) => {
                  return (
                    <Box
                      position={"relative"}
                      display={"inline-flex"}
                      bg="lightgray"
                      ml="20px"
                      mt="20px"
                      alignItems={"center"}
                      gap={3}
                      key={item.url.url}
                    >
                      {item.type ? (
                        <Image src={item.url.url} height="100px" />
                      ) : (
                        <Box h={"100px"} w="150px" position={"relative"}>
                          <Icon
                            position={"absolute"}
                            as={BsFillPlayFill}
                            fontSize="50px"
                            top="25px"
                            left="50px"
                            fill="rgba(0,0,0,.5)"
                          />
                          <video
                            style={{
                              height: "100px",
                              width: "150px",
                              objectFit: "cover",
                            }}
                          >
                            <source src={item.url.url} />
                          </video>
                        </Box>
                      )}

                      <CloseIcon
                        p="10px"
                        h="30px"
                        w="30px"
                        position={"absolute"}
                        top={0}
                        right={0}
                        transform={"translate(+50%, -50%)"}
                        bg="#32bac9"
                        borderRadius={"full"}
                        cursor="pointer"
                        color="white"
                        onClick={() => {
                          handleImageRemoval(index);
                        }}
                      />
                    </Box>
                  );
                })}
                {newImageblob.map((item, index) => {
                  return (
                    <Box
                      position={"relative"}
                      display={"inline-flex"}
                      bg="lightgray"
                      ml="20px"
                      mt="20px"
                      alignItems={"center"}
                      gap={3}
                      key={item.url}
                    >
                      {item.type ? (
                        <Image src={item.url} height="100px" />
                      ) : (
                        <Box w="150px" h="100px" position={"relative"}>
                          <Icon
                            position={"absolute"}
                            as={BsFillPlayFill}
                            fontSize="50px"
                            top="25px"
                            left="50px"
                            fill="rgba(0,0,0,.5)"
                          />
                          <video
                            style={{
                              height: "100px",
                              width: "150px",
                              objectFit: "cover",
                            }}
                          >
                            <source src={item.url} />
                          </video>
                        </Box>
                      )}
                      <CloseIcon
                        p="10px"
                        h="30px"
                        w="30px"
                        position={"absolute"}
                        top={0}
                        right={0}
                        transform={"translate(+50%, -50%)"}
                        bg="#32bac9"
                        borderRadius={"full"}
                        cursor="pointer"
                        color="white"
                        onClick={() => {
                          handleSecondImageRemoval(index);
                        }}
                      />
                    </Box>
                  );
                })}
                {uploadedImage.length + newImageblob.length < 4 ? (
                  <Box
                    h="50px"
                    w="50px"
                    bg="lightgray"
                    display={"flex"}
                    justifyContent="center"
                    alignItems={"center"}
                    cursor="pointer"
                    onClick={() => {
                      imageRef.current.click();
                    }}
                  >
                    <Input
                      type="file"
                      display={"none"}
                      ref={imageRef}
                      accept="image/*, video/*"
                      multiple
                      onChange={handleImageSubmit}
                    />
                    <AddIcon color="gray.600" />
                  </Box>
                ) : (
                  <></>
                )}
              </Box>
            ) : (
              <></>
            )}
          </Box>
        </Box>

        {/* update and cancle button */}
        <Box display={"flex"} px="40px" py="40px" gap="40px">
          <Box flexGrow={2}></Box>
          <Button
            colorScheme={"red"}
            onClick={() => {
              navigate("/dashboard/packages");
            }}
          >
            Cancel
          </Button>
          <Button
            colorScheme={"green"}
            onClick={handleUpdate}
            isLoading={loading}
          >
            Update
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default UpdatePackage;
