import axios from 'axios';

const DeletePackageRequest = (packageRequestId) => {
	return axios.delete(
		'https://backend.planmyleisure.com/package/delete-package-request',
		{ data: { packageRequestId: packageRequestId } }
	);
};

export default DeletePackageRequest;
