import { unescape } from 'lodash';
import { Cloudinary } from '@cloudinary/url-gen';

const dataUriToBlob = (dataURI) => {
	// convert base64/URLEncoded data component to raw binary data held in a string
	var byteString;

	if (dataURI.split(',')[0].indexOf('base64') >= 0)
		byteString = atob(dataURI.split(',')[1]);
	else byteString = unescape(dataURI.split(',')[1]);

	//separate out the mime component
	var mimeString = dataURI
		.split(',')[0]
		.split(':')[1]
		.split(';')[0];

	var ia = new Uint8Array(byteString.length);
	for (var i = 0; i < byteString.length; i++) {
		ia[i] = byteString.charCodeAt(i);
	}
	return new Blob([ia], { type: 'application/pdf' });
};

const uploadToCloud = async (imageObject) => {
	const imageLinks = [];

	try {
		const file = imageObject;
		const formData = new FormData();
		formData.append('file', file);
		// formData.append('upload_preset', 'oaykdomk');
		// formData.append('cloud_name', 'dqxu3gkbd');
		formData.append(
			'upload_preset',
			process.env.REACT_APP_CLOUD_PRESET_DOC
		);
		formData.append('cloud_name', process.env.REACT_APP_CLOUD_NAME);
		try {
			let response = await fetch(
				`https://api.cloudinary.com/v1_1/${process.env.REACT_APP_CLOUD_NAME}/upload`,
				{
					method: 'post',
					body: formData,
				}
			);

			response = await response.json();
			console.log('url is ', response.url);
			imageLinks.push(response.url);
		} catch (error) {
			console.log('something went wrong');
			console.log(error);
		}
		// imageLinks.push(response.data);
	} catch (error) {
		console.log(error);
	}

	return imageLinks;
};

export { uploadToCloud };
