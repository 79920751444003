import ReactDOM from 'react-dom';
import React from 'react';
import App from './App';
import { ChakraProvider } from '@chakra-ui/react';
import { BrowserRouter } from 'react-router-dom';
import { AuthContextProvider } from './context/authContext';
import './assets/icofont/icofont.min.css';

ReactDOM.render(
	<BrowserRouter>
		<AuthContextProvider>
			<ChakraProvider>
				<App />
			</ChakraProvider>
		</AuthContextProvider>
	</BrowserRouter>,
	document.getElementById('root')
);
