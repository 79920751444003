import axios from 'axios';
import { AddIcon } from '@chakra-ui/icons';
import { Box, Text, Button, Input, Image, useToast } from '@chakra-ui/react';
import React, { useState, useRef } from 'react';
// import JoditEditor from 'jodit-react';
// import RichTextEditor from 'react-rte';
import { CheckIcon, CloseIcon } from '@chakra-ui/icons';
import ReactQuill from 'react-quill';
import '../../../node_modules/react-quill/dist/quill.snow.css';
import NewBlogApi from '../../api/NewBlogApi';
import { useNavigate } from 'react-router-dom';

const Posts = () => {
	const [blogBody, setBlogBody] = useState('');
	const [addImage, setAddImage] = useState(false);
	const [blogHeading, setBlogHeading] = useState('');
	const [checkBlogHeading, setCheckBlogHeading] = useState(false);
	const [blogCategory, setBlogCategory] = useState('');
	// const [imageUrl, setImageUrl] = useState('');
	const [checkImageUrl, setCheckImageUrl] = useState(false);
	const [gettingImage, setGettingImage] = useState(false);
	const [showImage, setShowImage] = useState();
	const [blogSubmit, setBlogSubmit] = useState(false);
	const [isImageSelected, setIsImageSelected] = useState(false);
	const [uploadedImage, setUploadedImage] = useState([]);
	const [imageObject, setImageObject] = useState([]);
	const [loading, setLoading] = useState(false);

	const imageRef = useRef();
	const toast = useToast();
	const navigate = useNavigate();

	const handleBody = (e) => {
		console.log(e);
		setBlogBody(e);
	};

	const handleImageSubmit = (e) => {
		let fileList = [...e.target.files];

		if (fileList.length + uploadedImage.length > 1) {
			toast({
				title: 'Error',
				description: '1 image are allowed at max',
				status: 'error',
			});
		} else {
			setUploadedImage((prev) => {
				let val = fileList.map((item) => {
					return URL.createObjectURL(item);
				});
				prev.push(...val);
				return [...prev];
			});
			setImageObject((prev) => {
				prev.push(...fileList);
				return [...prev];
			});

			setIsImageSelected(true);
		}
	};

	const handleImageRemoval = (index) => {
		let temp = uploadedImage;
		let temp1 = imageObject;
		temp.splice(index, 1);
		temp1.splice(index, 1);
		setUploadedImage((prev) => [...temp]);
		setImageObject((prev) => [...temp1]);
		if (uploadedImage.length === 0) {
			setIsImageSelected(false);
		}
	};

	const uploadImageToCloud = async () => {
		const imageLinks = [];

		for (let i = 0; i < imageObject.length; i++) {
			try {
				const file = imageObject[i];
				const formData = new FormData();
				formData.append('file', file);
				formData.append(
					'upload_preset',
					process.env.REACT_APP_CLOUD_PRESET_IMAGE
				);
				formData.append('cloud_name', process.env.REACT_APP_CLOUD_NAME);

				try {
					let response = await fetch(
						`https://api.cloudinary.com/v1_1/${process.env.REACT_APP_CLOUD_NAME}/upload`,
						{
							method: 'post',
							body: formData,
						}
					);

					response = await response.json();
					console.log('cloud object we got : ', response);
					imageLinks.push(response.url);
					console.log('image links value is ', imageLinks);
					// .then((response) => response.json())
					// .then((data) => {
					// 	console.log(data);
					// 	imageLinks.append(data);
					// });
				} catch (error) {
					console.log('something went wrong');
					console.log(error);
				}
				// imageLinks.push(response.data);
			} catch (error) {
				console.log(error);
			}
		}
		return imageLinks;
	};

	const handleBlogSubmit = async () => {
		setLoading(true);
		if (blogHeading === '') {
			setCheckBlogHeading(true);
			setBlogBody(false);
		} else {
			let imagelist = await uploadImageToCloud();
			let imageUrl = imagelist[0];

			try {
				const response = await NewBlogApi(
					blogHeading,
					blogBody,
					'3 min',
					blogCategory,
					imageUrl
				);
				console.log(response);
				toast({
					title: response.data.message,
					status: 'success',
					isClosable: true,
				});
				navigate('/dashboard/blog');

				setLoading(false);
				setBlogBody('');
				setBlogHeading('');
				setBlogCategory('');
				setAddImage(false);
			} catch (error) {
				console.log(error);
				setLoading(false);
				toast({
					title: error.message,
					status: 'error',
					isClosable: true,
				});
			}
			setBlogBody(false);
		}
	};

	// const getImage = () => {
	// 	setGettingImage(true);
	// 	if (imageUrl === '') {
	// 		setCheckImageUrl(true);
	// 		setGettingImage(false);
	// 	} else {
	// 		setShowImage(true);
	// 		setGettingImage(false);
	// 	}
	// };

	return (
		<>
			<Box
				// w={{ base: '100%', lg: '86vw' }}
				h={'89vh'}
				bg='white'
				mx={{ base: 1, lg: '10px' }}
				borderRadius='md'
				boxShadow={'rgba(0, 0, 0, 0.24) 0px 3px 8px'}
				overflowY='scroll'
				pl={5}
				pr={5}
				pt={5}
			>
				<Text fontSize={30} fontWeight={500} pb={10}>
					Create Blog Post
				</Text>
				<Box>
					<Text>Heading</Text>
					<Input
						isInvalid={checkBlogHeading}
						type='text'
						mb={5}
						value={blogHeading}
						onChange={(e) => {
							setBlogHeading(e.target.value);
						}}
					/>
					<Box mt='30px' mb='20px'>
						<Text fontSize={20}> Upload Image </Text>
						{!isImageSelected ? (
							<Box
								bg='lightblue'
								display={'inline-block'}
								ml='10px'
								color='gray.600'
								px='10px'
								py='5px'
								borderRadius='md'
								cursor={'pointer'}
								onClick={() => {
									imageRef.current.click();
								}}
							>
								<Input
									type='file'
									display={'none'}
									ref={imageRef}
									accept='image/*'
									onChange={handleImageSubmit}
								/>
								Upload Image
							</Box>
						) : (
							<></>
						)}
						{isImageSelected ? (
							<Box display={'flex'} alignItems='end' gap='10px'>
								{uploadedImage.map((item, index) => {
									return (
										<Box
											position={'relative'}
											display={'inline-flex'}
											bg='lightgray'
											ml='20px'
											mt='20px'
											alignItems={'center'}
											gap={3}
											key={item}
										>
											<Image src={item} height='100px' />
											<CloseIcon
												p='10px'
												h='30px'
												w='30px'
												position={'absolute'}
												top={0}
												right={0}
												transform={
													'translate(+50%, -50%)'
												}
												bg='#32bac9'
												borderRadius={'full'}
												cursor='pointer'
												color='white'
												onClick={() => {
													handleImageRemoval(index);
												}}
											/>
										</Box>
									);
								})}
							</Box>
						) : (
							<></>
						)}
					</Box>
					<Text>Category</Text>
					<Input
						type='text'
						mb={5}
						value={blogCategory}
						onChange={(e) => {
							setBlogCategory(e.target.value);
						}}
					/>
					<Text>Description</Text>
					<ReactQuill
						value={blogBody}
						placeholder='create new post'
						modules={Posts.modules}
						formats={Posts.formate}
						onChange={handleBody}
					/>
				</Box>
				<Box
					display={'flex'}
					justifyContent='space-between'
					mt={5}
					px={3}
				>
					<Button
						colorScheme={'red'}
						onClick={() => {
							navigate('/dashboard/blog');
						}}
					>
						Cancel
					</Button>
					<Button
						isLoading={loading}
						colorScheme={'green'}
						onClick={handleBlogSubmit}
					>
						Save
					</Button>
				</Box>
			</Box>
		</>
	);
};

Posts.modules = {
	toolbar: [
		[
			{ header: '1' },
			{ header: '2' },
			{ header: [3, 4, 5, 6] },
			{ font: [] },
		],
		// [{ size: [] }],
		['bold', 'italic', 'underline', 'strike', 'blockquote'],
		[{ list: 'ordered' }, { list: 'bullet' }],
		['link', 'image'],
		// ['clean'],
		// ['code-block'],
	],
};

Posts.formate = [
	'header',
	'font',
	'size',
	'bold',
	'italic',
	'underline',
	'strike',
	'blockquote',
	'list',
	'bullet',
	'link',
	'image',
	'video',
	'code-block',
];

// const Posts = () => {
// 	const editor = useRef(null);
// 	const [value, setValue] = useState('');
// 	console.log('value is : ', value);
// 	return (
// 		<Box
// 			w={{ base: '100%', lg: '90vw' }}
// 			h={'98%'}
// 			bg='white'
// 			mx={{ base: 1, lg: 10 }}
// 			borderRadius='2xl'
// 			boxShadow={'2xl'}
// 			overflowY='scroll'
// 			pl={5}
// 			pr={5}
// 			pt={5}
// 		>
// 			<Text fontSize={30} fontWeight={500} pb={10}>
// 				Create Blog
// 			</Text>
// 			<Box>
// 				<Text>Heading</Text>
// 				<Input type='text' mb={5} />
// 				<Text>Content</Text>
// 				<JoditEditor
// 					ref={editor}
// 					onChange={(content) => {
// 						setValue(content);
// 						console.log(value);
// 					}}
// 				/>
// 			</Box>
// 			<Box display={'flex'} justifyContent='space-between' mt={5} px={3}>
// 				<Button colorScheme='red'>Cancle</Button>
// 				<Button colorScheme={'green'}>Save</Button>
// 			</Box>
// 		</Box>
// 	);
// };

// class Posts extends React.Component {
// 	constructor(props) {
// 		super(props);

// 		this.state = {
// 			value: RichTextEditor.createEmptyValue(),
// 		};
// 	}

// 	handleChange = (value) => {
// 		this.setState({ value });
// 	};

// 	render() {
// 		const toolbarConfig = {
// 			display: ['INLINE_STYLE_BUTTONS', 'BLOCK_TYPE_DROPDOWN'],
// 			INLINE_STYLE_BUTTONS: [
// 				{ label: 'Bold', style: 'BOLD', className: 'custom-css-class' },
// 				{ label: 'Italic', style: 'ITALIC' },
// 				{ label: 'Underline', style: 'UNDERLINE' },
// 			],
// 			BLOCK_TYPE_DROPDOWN: [
// 				{ label: 'Normal', style: 'unstyled' },
// 				{
// 					label: 'Heading Large',
// 					style: 'header-one',
// 					className: 'heading',
// 				},
// 				{ label: 'Heading Medium', style: 'header-two' },
// 				{ label: 'Heading Small', style: 'header-three' },
// 			],
// 		};

// 		return (
// 			<Box
// 				w={{ base: '100%', lg: '90vw' }}
// 				h={'98%'}
// 				bg='white'
// 				mx={{ base: 1, lg: 10 }}
// 				borderRadius='2xl'
// 				boxShadow={'2xl'}
// 				overflowY='scroll'
// 				pl={5}
// 				pr={5}
// 				pt={5}
// 			>
// 				<Text fontSize={30} fontWeight={500} pb={10}>
// 					Create Blog
// 				</Text>
// 				<Box>
// 					<Text>Heading</Text>
// 					<Input type='text' mb={5} />
// 					<Text mb={5}>Add Image + </Text>
// 					<Text>Category</Text>
// 					<Input type='text' mb={5} />
// 					<Text>Description</Text>
// 					<Input type='text' mb={5} />
// 					<Text>Content</Text>
// 					<RichTextEditor
// 						toolbarConfig={toolbarConfig}
// 						value={this.state.value}
// 						onChange={this.handleChange}
// 						className='heading'
// 					/>
// 				</Box>
// 				<Box
// 					display={'flex'}
// 					justifyContent='space-between'
// 					mt={5}
// 					px={3}
// 				>
// 					<Button colorScheme='red'>Cancle</Button>
// 					<Button colorScheme={'green'}>Save</Button>
// 				</Box>
// 			</Box>
// 		);
// 	}
// }

export default Posts;
