import React, { useState, useEffect } from 'react';
import {
	Box,
	Text,
	Table,
	TableContainer,
	Thead,
	Tbody,
	Tr,
	Th,
	Button,
	Td,
	Icon,
	Input,
	Menu,
	MenuButton,
	MenuList,
	MenuItem,
	useDisclosure,
	AlertDialog,
	AlertDialogOverlay,
	AlertDialogContent,
	AlertDialogHeader,
	AlertDialogBody,
	AlertDialogFooter,
	FormControl,
	FormLabel,
	Select,
	useToast,
} from '@chakra-ui/react';
import { DeleteIcon, ChevronDownIcon, SettingsIcon } from '@chakra-ui/icons';
import { FaPen, FaSave } from 'react-icons/fa';
import RemarksApi from '../../api/RemarksApi';
import DeleteLeadApi from '../../api/DeleteLeadApi';
import UpdateStatusApi from '../../api/UpdateStatusApi';
import GetLeadsApi from '../../api/GetLeadsApi';

const Remarks = ({ id, remarks }) => {
	console.log('remarks : ', remarks);
	const [edit, setEdit] = useState(false);
	const [remark, setRemark] = useState(remarks === undefined ? '' : remarks);
	const [temp, setTemp] = useState(remark);
	const toast = useToast();

	const handleSaveButton = async () => {
		setEdit(false);
		try {
			const response = await RemarksApi(id, remark);
			console.log(response);
			toast({
				title: 'Remark Updated Sucessfully!',
				status: 'success',
				isClosable: true,
			});
			setRemark(temp);
		} catch (error) {
			console.log(error);
			toast({
				title: 'Somethign Went Wrong!',
				status: 'error',
				isClosable: true,
			});
		}
	};

	return (
		<Td
			textAlign={'center'}
			display='flexbox'
			flexDir={'row'}
			justifyContent={'center'}
			alignItems={'center'}
		>
			{edit ? (
				<Input
					value={temp}
					onChange={(e) => {
						setTemp(e.target.value);
					}}
				/>
			) : (
				<Text display={'inline-block'}>{remark}</Text>
			)}
			<Icon
				as={FaPen}
				color='gray.500'
				mx={1}
				cursor='pointer'
				onClick={() => {
					setEdit(true);
				}}
			/>
			<Icon
				as={FaSave}
				color='gray.500'
				mx={1}
				cursor='pointer'
				onClick={() => {
					handleSaveButton();
				}}
			/>
		</Td>
	);
};

const Delete = ({ id, setUpdate }) => {
	const { isOpen, onOpen, onClose } = useDisclosure();
	const toast = useToast();

	const cancelRef = React.useRef();

	const deleteTheUser = async () => {
		onClose();
		try {
			const response = await DeleteLeadApi({ id });
			console.log(response);
			setUpdate((pre) => !pre);
			toast({
				title: 'User Deleted Successfully!',
				status: 'success',
				isClosable: true,
			});
		} catch (err) {
			console.log(err);
			toast({
				title: 'Something Went Wrong!',
				status: 'error',
				isClosable: true,
			});
		}
	};

	return (
		<Td textAlign={'center'}>
			<DeleteIcon color='red' cursor={'pointer'} onClick={onOpen} />
			<AlertDialog
				isOpen={isOpen}
				leastDestructiveRef={cancelRef}
				onClose={onClose}
			>
				<AlertDialogOverlay>
					<AlertDialogContent>
						<AlertDialogHeader fontSize='lg' fontWeight='bold'>
							Delete User
						</AlertDialogHeader>

						<AlertDialogBody>
							Are you sure? You can't undo this action afterwards.
						</AlertDialogBody>

						<AlertDialogFooter>
							<Button ref={cancelRef} onClick={onClose}>
								Cancel
							</Button>
							<Button
								colorScheme='red'
								onClick={deleteTheUser}
								ml={3}
							>
								Delete
							</Button>
						</AlertDialogFooter>
					</AlertDialogContent>
				</AlertDialogOverlay>
			</AlertDialog>
		</Td>
	);
};

const Status = ({ id, status }) => {
	const toast = useToast();

	const [value, setValue] = useState(
		status === 'Contacted' ? 'Contacted' : 'Contact Pending'
	);

	const handleStatus = async (event) => {
		setValue(event.target.value);
		try {
			const response = await UpdateStatusApi(id, event.target.value);
			console.log('response : ', response);
			toast({
				title: response.data.message,
				status: 'success',
				isClosable: true,
			});
		} catch (error) {
			console.log(error);
			toast({
				title: error.message,
				status: 'error',
				isClosable: true,
			});
		}
		console.log(event.target.value);
	};

	return (
		<Td>
			<FormControl>
				<Select
					id='country'
					// defaultValue={
					// 	status === 'Contacted' ? 'Contacted' : 'Contact Pending'
					// }
					onChange={handleStatus}
					value={value}
					w='170px'
				>
					<option value='Contact Pending'>Contact Pending</option>
					<option value='Contacted'>Contacted</option>
				</Select>
			</FormControl>
		</Td>
	);
};

const Leads = () => {
	const [leads, setLeads] = useState([]);
	const [edit, setEdit] = useState(false);
	const [update, setUpdate] = useState(true);

	const getLeads = async () => {
		try {
			const response = await GetLeadsApi();
			setLeads(response.data);
		} catch (err) {}
	};

	useEffect(() => {
		setLeads([]);
		getLeads();
	}, [update]);

	return (
		<Box
			// w={{ base: '100%', lg: '86vw' }}
			h={'89vh'}
			bg='white'
			mx={{ base: 1, lg: '10px' }}
			borderRadius='md'
			boxShadow={'rgba(0, 0, 0, 0.24) 0px 3px 8px'}
			overflowX='hidden'
			overflowY='scroll'
		>
			{/* <Text
				p={4}
				textAlign={'center'}
				fontSize={25}
				fontWeight={600}
				bg='#32bac9'
				color='white'
			>
				Contact
			</Text> */}
			<TableContainer>
				<Table variant={'striped'} overflow='scroll' mt={3}>
					<Thead>
						<Tr>
							<Th textAlign={'center'} fontSize={'1.1rem'}>
								Name
							</Th>
							<Th textAlign={'center'} fontSize={'1.1rem'}>
								Email
							</Th>
							<Th textAlign={'center'} fontSize={'1.1rem'}>
								Phone No.
							</Th>
							<Th textAlign={'center'} fontSize={'1.1rem'}>
								Date
							</Th>
							<Th textAlign={'center'} fontSize={'1.1rem'}>
								Source
							</Th>
							<Th textAlign={'center'} fontSize={'1.1rem'}>
								Status
							</Th>
							<Th textAlign={'center'} fontSize={'1.1rem'}>
								Remarks
							</Th>
							<Th textAlign={'center'} fontSize={'1.1rem'}></Th>
						</Tr>
					</Thead>
					<Tbody>
						{leads.map((item, index) => {
							return (
								<Tr key={index}>
									<Td textAlign={'center'}>{item.name}</Td>
									<Td textAlign={'center'}>{item.email}</Td>
									<Td textAlign={'center'}>{item.phone}</Td>
									<Td textAlign={'center'}>{item.date}</Td>
									<Td textAlign={'center'}>{item.source}</Td>
									<Status
										id={item._id}
										status={item.status}
										setUpdate={setUpdate}
									/>
									<Remarks
										id={item._id}
										remarks={item.remark}
										setUpdate={setUpdate}
									/>
									<Delete
										id={item._id}
										setUpdate={setUpdate}
									/>
								</Tr>
							);
						})}
					</Tbody>
				</Table>
			</TableContainer>
		</Box>
	);
};

export default Leads;
