import axios from 'axios';

const newHeroImage = (title, image, city, location) => {
	console.log('image link we got ', image);
	console.log('image title : ', title);
	return axios.post('https://backend.planmyleisure.com/blog/hero-image', {
		title,
		imageUrl: [image],
		city,
		location,
	});
};

export default newHeroImage;
