import { DeleteIcon, EditIcon } from '@chakra-ui/icons';
import {
	AlertDialog,
	AlertDialogBody,
	AlertDialogContent,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogOverlay,
	Button,
	Icon,
	Td,
	Tr,
	useDisclosure,
} from '@chakra-ui/react';
import { useRef, useState } from 'react';
import { MdArchive } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import addToArchiveApi from '../../api/addToArchiveApi';
import DeletePackageApi from '../../api/DeletePackageApi';

const Pkg = ({ item, changeState }) => {
	const navigate = useNavigate();
	const { isOpen, onOpen, onClose } = useDisclosure();
	const cancelRef = useRef();
	const [loading, setLoading] = useState(false);

	const handleArchive = async () => {
		setLoading(true);
		try {
			const response = await addToArchiveApi(item.packageId, true);
			console.log(response);
			setLoading(false);
			onClose();
			changeState((prev) => !prev);
		} catch (error) {
			console.log('error is ', error);
			setLoading(false);
			onClose();
			changeState((prev) => !prev);
		}
	};

	return (
		<>
			<AlertDialog
				isOpen={isOpen}
				leastDestructiveRef={cancelRef}
				onClose={onClose}
			>
				<AlertDialogOverlay>
					<AlertDialogContent>
						<AlertDialogHeader fontSize='lg' fontWeight='bold'>
							Archive Package
						</AlertDialogHeader>

						<AlertDialogBody>Are you sure?</AlertDialogBody>

						<AlertDialogFooter>
							<Button ref={cancelRef} onClick={onClose}>
								Cancel
							</Button>
							<Button
								colorScheme='red'
								onClick={handleArchive}
								ml={3}
								isLoading={loading}
							>
								Confirm
							</Button>
						</AlertDialogFooter>
					</AlertDialogContent>
				</AlertDialogOverlay>
			</AlertDialog>
			<Tr>
				<Td textAlign={'center'}>{item.packageId}</Td>
				<Td textAlign={'center'}>{item.destination}</Td>
				<Td textAlign={'center'}>{item.packageTitle}</Td>
				<Td>
					<EditIcon
						cursor={'pointer'}
						onClick={() => {
							navigate('/dashboard/packages/update', {
								state: item,
							});
						}}
					/>
				</Td>
				<Td>
					<Icon
						fontSize={'22px'}
						color='gray.600'
						as={MdArchive}
						p={0}
						cursor='pointer'
						onClick={() => {
							onOpen();
						}}
					/>
				</Td>
			</Tr>
		</>
	);
};

export default Pkg;
