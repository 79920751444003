import { AddIcon, CloseIcon, Icon } from '@chakra-ui/icons';
import {
	Box,
	Button,
	Image,
	Input,
	Modal,
	ModalContent,
	ModalOverlay,
	Text,
	useDisclosure,
} from '@chakra-ui/react';
import { useEffect, useRef, useState } from 'react';
import updateImgSlider from '../../api/imageSliderApi/updateImgSlider';
import { BsFillPlayFill } from 'react-icons/bs';

const EditImageSlider = ({ data, setState, state }) => {
	const { isOpen, onOpen, onClose } = useDisclosure();
	const [loading, setLoading] = useState(false);
	const [quote, setQuote] = useState(data.title);
	const [imageSelected, setImageSelected] = useState(true);
	const [imageObject, setImageObject] = useState(null);
	const [localImageLink, setLocalImageLink] = useState(data.imageUrl[0].url);
	const [city, setCity] = useState(data.city);
	const [location, setLocation] = useState(data.location);
	const [isImage, setIsImage] = useState(
		data.imageUrl[0].resource_type === 'video' ? false : true
	);
	const inputRef = useRef();

	const handleClick = () => {
		inputRef.current.click();
	};

	useEffect(() => {
		if (state) {
			onOpen();
		}
	}, [state]);

	const handleImageSelect = (e) => {
		setIsImage(
			e.target.files[0].type.split('/')[0] === 'video' ? false : true
		);
		setImageObject((prev) => e.target.files[0]);
		setLocalImageLink((prev) => URL.createObjectURL(e.target.files[0]));
		setImageSelected((prev) => true);
	};

	const handleCloseButton = () => {
		setImageSelected((prev) => false);
	};

	const handleSubmit = async () => {
		setLoading(true);
		if (localImageLink.substring(0, 4) === 'blob') {
			// means i have to upload the image to cloud
			try {
				const imagelink = await createImagelink();
				const response = await updateImgSlider({
					id: data._id,
					title: quote,
					city,
					location,
					image: imagelink,
				});
				console.log(response);
				setLoading(false);
				onClose();
				setState((prev) => !prev);
				setLocation('');
				setCity('');
				setQuote('');
				setImageSelected(false);
				setImageObject(null);
				setLocalImageLink(null);
				window.location.reload();
			} catch (error) {
				console.log('something went wrong : ', error);
				setLoading(false);
			}
		} else {
			// image is not change
			try {
				const response = await updateImgSlider({
					id: data._id,
					title: quote,
					city,
					location,
					image: data.imageUrl[0],
				});
				setLoading(false);
				onClose();
				setState((prev) => !prev);
				setLocation('');
				setCity('');
				setQuote('');
				setImageSelected(false);
				setImageObject(null);
				setLocalImageLink(null);
				window.location.reload();
			} catch (error) {
				console.log('error is ', error);
				setLoading(false);
			}
		}
	};

	const createImagelink = async () => {
		try {
			const formData = new FormData();
			formData.append('file', imageObject);
			// formData.append('upload_preset', 'oaykdomk');
			// formData.append('cloud_name', 'dqxu3gkbd');
			formData.append(
				'upload_preset',
				imageObject.type.split('/')[0] === 'video'
					? process.env.REACT_APP_CLOUD_PRESET_VIDEO
					: process.env.REACT_APP_CLOUD_PRESET_IMAGE
			);
			formData.append('cloud_name', 'dqimeuotl');
			formData.append('manifest_transformation', 'q_auto:eco');

			let response = await fetch(
				`https://api.cloudinary.com/v1_1/${process.env.REACT_APP_CLOUD_NAME}/upload`,
				{ method: 'post', body: formData }
			);

			response = await response.json();
			return response;
		} catch (error) {
			console.log('error occurred ', error);
		}
	};

	return (
		<Modal size={'full'} isOpen={isOpen} onClose={onClose}>
			<ModalOverlay />
			<ModalContent bg='transparent' position={'relative'}>
				<Box
					width={'600px'}
					height='fit-content'
					bg='#fff'
					px='25px'
					py='20px'
					position={'absolute'}
					top='50%'
					left='50%'
					transform={'translate(-50%,-50%)'}
					borderRadius='10px'
				>
					<Box
						display={'flex'}
						justifyContent='space-between'
						alignItems={'center'}
					>
						<Text fontSize={20} fontWeight={600}>
							Add new image slider
						</Text>
						<CloseIcon
							onClick={() => {
								setState(false);
								onClose();
							}}
							cursor='pointer'
						/>
					</Box>
					<Box pt='30px'>
						<Box
							display={'flex'}
							alignItems='center'
							gap='10px'
							mb='20px'
						>
							<Text>Quote</Text>
							<Input
								value={quote}
								type={'text'}
								onChange={(e) => {
									setQuote(e.target.value);
								}}
							/>
						</Box>
						<Box
							display={'flex'}
							alignItems='center'
							gap='10px'
							mb='20px'
						>
							<Text>City</Text>
							<Input
								value={city}
								type={'text'}
								onChange={(e) => {
									setCity(e.target.value);
								}}
							/>
						</Box>
						<Box
							display={'flex'}
							alignItems='center'
							gap='10px'
							mb='20px'
						>
							<Text>Location</Text>
							<Input
								value={location}
								type={'text'}
								onChange={(e) => {
									setLocation(e.target.value);
								}}
							/>
						</Box>
						{imageSelected ? (
							<Box
								display={'inline-block'}
								position='relative'
								zIndex={2}
								mt='25px'
							>
								{!isImage ? (
									<Box
										w='150px'
										h='100px'
										position={'relative'}
									>
										<Icon
											position={'absolute'}
											fontSize='50px'
											left={'50px'}
											top='16px'
											fill='rgba(0,0,0,.5)'
											as={BsFillPlayFill}
										/>
										<video>
											<source
												src={localImageLink}
											></source>
										</video>
									</Box>
								) : (
									<Image
										src={localImageLink}
										height='100px'
									/>
								)}

								<Box
									position={'absolute'}
									top='0'
									right={0}
									zIndex={3}
									bg='lightblue'
									transform={'translate(50%,-50%)'}
									borderRadius='full'
									h='25px'
									w='25px'
									display={'flex'}
									justifyContent='center'
									alignItems={'center'}
									onClick={handleCloseButton}
									cursor='pointer'
								>
									<CloseIcon
										color='rgba(0,0,0,.5)'
										fontSize={10}
									/>
								</Box>
							</Box>
						) : (
							<Box
								display={'inline-flex'}
								alignItems='center'
								gap='10px'
								bg='lightblue'
								px='15px'
								py='5px'
								borderRadius={'5px'}
								mt='25px'
								cursor='pointer'
								onClick={handleClick}
							>
								<AddIcon color='rgba(0,0,0,.5)' />{' '}
								<Text color='rgba(0,0,0,.5)'>Add Image</Text>
								<Input
									type='file'
									accept='image/*, video/*'
									display={'none'}
									ref={inputRef}
									onChange={handleImageSelect}
								/>
							</Box>
						)}
					</Box>
					<Box display={'flex'} justifyContent='center' pt='20px'>
						<Button
							colorScheme={'green'}
							onClick={handleSubmit}
							isLoading={loading}
						>
							Save
						</Button>
					</Box>
				</Box>
			</ModalContent>
		</Modal>
	);
};

export default EditImageSlider;
