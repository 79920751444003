import {
	AlertDialog,
	AlertDialogBody,
	AlertDialogContent,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogOverlay,
	Button,
	useDisclosure,
} from '@chakra-ui/react';
import { useEffect, useRef, useState } from 'react';
import DeletePackageApi from '../../api/DeletePackageApi';

const ArcPkgDelete = ({ state, changeState, item, reload }) => {
	const { isOpen, onOpen, onClose } = useDisclosure();
	const cancelRef = useRef();
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		if (state) {
			onOpen();
		}
	}, [state]);

	const handlePackageDelete = async () => {
		setLoading(true);
		try {
			const response = await DeletePackageApi(item.packageId);
			console.log(response);
			setLoading(false);
			onClose();
			reload((prev) => !prev);
			changeState(false);
		} catch (error) {
			console.log('error is ', error);
			setLoading(false);
			onClose();
			reload((prev) => !prev);
			changeState(false);
		}
	};

	return (
		<AlertDialog
			isOpen={isOpen}
			leastDestructiveRef={cancelRef}
			onClose={onClose}
		>
			<AlertDialogOverlay>
				<AlertDialogContent>
					<AlertDialogHeader fontSize='lg' fontWeight='bold'>
						Delete Package
					</AlertDialogHeader>

					<AlertDialogBody>
						Are you sure? You can't undo this action afterwards.
					</AlertDialogBody>

					<AlertDialogFooter>
						<Button
							ref={cancelRef}
							onClick={() => {
								changeState(false);
								onClose();
							}}
						>
							Cancel
						</Button>
						<Button
							colorScheme='red'
							onClick={handlePackageDelete}
							ml={3}
							isLoading={loading}
						>
							Delete
						</Button>
					</AlertDialogFooter>
				</AlertDialogContent>
			</AlertDialogOverlay>
		</AlertDialog>
	);
};

export default ArcPkgDelete;
