import axios from 'axios';

const getAgents = () => {
	const token = localStorage.getItem('token');

	return axios.get(
		'https://backend.planmyleisure.com/agent/agent-requests',
		{
			headers: { Authorization: token },
		}
	);
};
export default getAgents;
