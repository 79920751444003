import axios from 'axios';

const updateRequestPackage = (
	packageId,
	startDate,
	endDate,
	numberOfPeople,
	location,
	resorts,
	roomType,
	mealPlan,
	transfers,
	inclusion,
	itinerary,
	price,
	flightDetails,
	paymentStatus,
	paymentType,
	complimentary,
	activities
) => {
	// console.log('payment type is : ', paymentType);
	console.log('inclusion value to update to : ', complimentary, activities);

	return axios.patch(
		'https://backend.planmyleisure.com/package/update-request-package',
		{
			packageId,
			startDate,
			endDate,
			numberOfPeople,
			location,
			resorts: { values: resorts },
			roomType,
			mealPlan,
			transfers,
			inclusion,
			itinerary,
			price,
			flightDetails: [flightDetails],
			paymentStatus,
			paymentType,
			pdf: '',
			complimentory: complimentary,
			activities,
		}
	);
};
export default updateRequestPackage;
