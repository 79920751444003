import axios from 'axios';

const deleteBrand = (brandId) => {
	const token = localStorage.getItem('token');
	return axios.delete(
		'https://backend.planmyleisure.com/brand/delete-brand-by-id',
		{ data: { brandId: brandId }, headers: { Authorization: token } }
	);
};

export default deleteBrand;
