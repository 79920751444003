import axios from 'axios';

const GetUserInfo = (userid) => {
	let token = localStorage.getItem('token');

	return axios.get(
		`https://backend.planmyleisure.com/user/user-info/${userid}`,
		{
			headers: { Authorization: token },
		}
	);
};
export default GetUserInfo;
