import { Box, Text } from '@chakra-ui/react';
import { useState } from 'react';
import BannerImage from './BannerImage';
import FactsImage from './FactsImage';
import ImageSlider from './ImageSlider';

const Image = () => {
	const [image_slider, set_image_slider] = useState(true);
	const [facts_image, set_facts_image] = useState(false);
	const [banner_image, set_banner_image] = useState(false);
	return (
		<>
			<Box h={'89vh'} mx={{ base: 1, lg: '10px' }} borderRadius='md'>
				<Box display={'inline-flex'} bg='transparent' h='50px'>
					<Text
						fontSize={'20px'}
						fontWeight={600}
						bg={image_slider ? 'white' : 'gray.200'}
						color={image_slider ? 'black' : 'gray.400'}
						display={'flex'}
						alignItems='center'
						justifyContent={'center'}
						px='20px'
						borderRadius={'15px 15px 0 0'}
						cursor='pointer'
						onClick={() => {
							set_banner_image(false);
							set_facts_image(false);
							set_image_slider(true);
						}}
						position='relative'
						_after={
							image_slider
								? {
										content: '""',
										position: 'absolute',
										height: '0px',
										width: '0px',
										left: '100%',
										bottom: '0',
										borderBottom: '5px solid white',
										borderRight: '5px solid transparent',
										zIndex: '1000',
								  }
								: {}
						}
					>
						Image Slider
					</Text>
					<Text
						fontSize={'20px'}
						fontWeight={600}
						bg={banner_image ? 'white' : 'gray.200'}
						color={banner_image ? 'black' : 'gray.400'}
						display={'flex'}
						alignItems='center'
						justifyContent={'center'}
						px='20px'
						borderRadius={'15px 15px 0 0'}
						cursor='pointer'
						onClick={() => {
							set_banner_image(true);
							set_facts_image(false);
							set_image_slider(false);
						}}
						position='relative'
						_after={
							banner_image
								? {
										content: '""',
										position: 'absolute',
										height: '0px',
										width: '0px',
										// background: 'green',
										left: '100%',
										bottom: '0',
										borderBottom: '5px solid white',
										borderRight: '5px solid transparent',
								  }
								: {}
						}
						_before={
							banner_image
								? {
										content: '""',
										position: 'absolute',
										height: '0px',
										width: '0px',
										// background: 'green',
										right: '100%',
										bottom: '0',
										borderBottom: '5px solid white',
										borderLeft: '5px solid transparent',
								  }
								: {}
						}
					>
						Banner Image
					</Text>
					<Text
						fontSize={'20px'}
						fontWeight={600}
						bg={facts_image ? 'white' : 'gray.200'}
						color={facts_image ? 'black' : 'gray.400'}
						display={'flex'}
						alignItems='center'
						justifyContent={'center'}
						px='20px'
						borderRadius={'15px 15px 0 0'}
						cursor='pointer'
						onClick={() => {
							set_banner_image(false);
							set_facts_image(true);
							set_image_slider(false);
						}}
						position='relative'
						_after={
							facts_image
								? {
										content: '""',
										position: 'absolute',
										height: '0px',
										width: '0px',
										// background: 'green',
										left: '100%',
										bottom: '0',
										borderBottom: '5px solid white',
										borderRight: '5px solid transparent',
								  }
								: {}
						}
						_before={
							facts_image
								? {
										content: '""',
										position: 'absolute',
										height: '0px',
										width: '0px',
										// background: 'green',
										right: '100%',
										bottom: '0',
										borderBottom: '5px solid white',
										borderLeft: '5px solid transparent',
								  }
								: {}
						}
					>
						Facts Image
					</Text>
				</Box>
				{image_slider ? <ImageSlider /> : <></>}
				{banner_image ? <BannerImage /> : <></>}
				{facts_image ? <FactsImage /> : <></>}
			</Box>
		</>
	);
};

export default Image;
