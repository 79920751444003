import {
	Box,
	Table,
	TableContainer,
	Tbody,
	Text,
	Th,
	Thead,
	Tr,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import getAgents from '../../api/getAgents';
import SingleAgent from './SingleAgent';

const Agents = () => {
	const [agentList, setAgentList] = useState([]);

	useEffect(() => {
		const agents = async () => {
			const response = await getAgents();
			setAgentList(response.data.agentRequests);
		};

		agents();
	});

	return (
		<>
			<Box
				// w={{ base: '100%', lg: '86vw' }}
				h={'89vh'}
				bg='white'
				mx={{ base: 1, lg: '10px' }}
				borderRadius='md'
				boxShadow={'rgba(0, 0, 0, 0.24) 0px 3px 8px'}
				overflowX='hidden'
				overflowY='scroll'
			>
				<TableContainer>
					<Table variant={'striped'} overflow='scroll' mt={3}>
						<Thead>
							<Tr>
								<Th textAlign={'center'} fontSize={'1.1rem'}>
									First Name
								</Th>
								<Th textAlign={'center'} fontSize={'1.1rem'}>
									Last Name
								</Th>
								<Th textAlign={'center'} fontSize={'1.1rem'}>
									Email
								</Th>
								<Th textAlign={'center'} fontSize={'1.1rem'}>
									Mobile no.
								</Th>
								<Th
									textAlign={'center'}
									fontSize={'1.1rem'}
								></Th>
								<Th
									textAlign={'center'}
									fontSize={'1.1rem'}
								></Th>
							</Tr>
						</Thead>
						<Tbody>
							{agentList.map((data, index) => {
								return <SingleAgent data={data} key={index} />;
							})}
						</Tbody>
					</Table>
				</TableContainer>
			</Box>
		</>
	);
};

export default Agents;
