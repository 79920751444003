import axios from 'axios';

const updateBannerApi = (bannerId, quote, imageUrl) => {
	return axios.patch(
		'https://backend.planmyleisure.com/blog/update-banner',
		{
			bannerId,
			quote,
			imageUrl,
		}
	);
};

export default updateBannerApi;
