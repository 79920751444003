import { DeleteIcon, EditIcon, Icon } from '@chakra-ui/icons';
import {
	Alert,
	AlertDialog,
	AlertDialogBody,
	AlertDialogContent,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogOverlay,
	Box,
	Td,
	Tr,
	useDisclosure,
	Button,
} from '@chakra-ui/react';
import { useRef, useState } from 'react';
import deleteimg from '../../api/imageSliderApi/deleteimg';
import EditImageSlider from './EditImageSlider';
import { BsFillPlayFill } from 'react-icons/bs';

const SingleImageSliderElement = ({ data, state, setState }) => {
	const { isOpen, onOpen, onClose } = useDisclosure();
	const cancleRef = useRef();
	const [loading, setLoading] = useState(false);
	const [State, SetState] = useState(false);

	const handleDelete = async () => {
		setLoading(true);
		try {
			const response = await deleteimg(data._id);
			console.log(response);
			setLoading(false);
			onClose();
			setState((prev) => !prev);
		} catch (error) {
			console.log('error is ', error);
			setLoading(false);
		}
	};

	return (
		<>
			<AlertDialog
				isOpen={isOpen}
				leastDestructiveRef={cancleRef}
				onClose={onClose}
			>
				<AlertDialogOverlay>
					<AlertDialogContent>
						<AlertDialogHeader fontSize='lg' fontWeight='bold'>
							Delete Customer
						</AlertDialogHeader>

						<AlertDialogBody>
							Are you sure? You can't undo this action afterwards.
						</AlertDialogBody>

						<AlertDialogFooter>
							<Button ref={cancleRef} onClick={onClose}>
								Cancel
							</Button>
							<Button
								colorScheme='red'
								onClick={handleDelete}
								ml={3}
								isLoading={loading}
							>
								Delete
							</Button>
						</AlertDialogFooter>
					</AlertDialogContent>
				</AlertDialogOverlay>
			</AlertDialog>
			<EditImageSlider data={data} setState={SetState} state={State} />
			<Tr>
				<Td>
					{data.imageUrl[0].resource_type === 'video' ? (
						<Box
							w='250px'
							h='150px'
							mx='auto'
							position={'relative'}
						>
							<Icon
								position={'absolute'}
								fontSize='90px'
								top='50%'
								left='50%'
								transform={'translate(-50%,-50%)'}
								fill='rgba(0,0,0,.3)'
								as={BsFillPlayFill}
							/>
							<video>
								<source src={data.imageUrl[0].url} />
							</video>
						</Box>
					) : (
						<Box
							w='250px'
							h='150px'
							bg='green.600'
							mx='auto'
							bgImage={data.imageUrl[0].url}
							bgSize='cover'
							bgPos={'50% 50%'}
						></Box>
					)}
				</Td>
				<Td textAlign={'center'}>{data.title}</Td>
				<Td textAlign={'center'}>
					<EditIcon
						cursor={'pointer'}
						onClick={() => {
							SetState(true);
						}}
					/>
				</Td>
				<Td textAlign={'center'}>
					<DeleteIcon
						color='red.400'
						cursor='pointer'
						onClick={onOpen}
					/>
				</Td>
			</Tr>
		</>
	);
};
export default SingleImageSliderElement;
