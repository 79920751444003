import axios from 'axios';

const DeletePackageApi = (packageId) => {
	return axios.delete(
		'https://backend.planmyleisure.com/package/delete-package',
		{
			data: {
				packageId,
			},
		}
	);
};

export default DeletePackageApi;
