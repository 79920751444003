import axios from 'axios';

const RemarksApi = (id, remark) => {
	return axios.patch('https://backend.planmyleisure.com/lead/add-remark', {
		id,
		remark,
	});
};

export default RemarksApi;
