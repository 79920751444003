import axios from 'axios';

const deleteimg = (heroId) => {
	return axios.delete(
		'https://backend.planmyleisure.com/blog/delete-hero-Image',
		{
			data: { heroId: heroId },
		}
	);
};

export default deleteimg;
