import { CheckIcon } from '@chakra-ui/icons';
import {
	AlertDialog,
	AlertDialogBody,
	AlertDialogContent,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogOverlay,
	Button,
	Td,
	Tr,
	useDisclosure,
} from '@chakra-ui/react';
import axios from 'axios';
import { useEffect, useRef, useState } from 'react';
import GetPackageById from '../../api/GetPackageById';
import GetUserInfo from '../../api/GetUserInfo';

const ConfirmCancellation = ({ data, changeState }) => {
	const [name, setName] = useState(null);
	const [email, setEmail] = useState(null);
	const [phone, setPhone] = useState(null);
	const [package_data, set_package_data] = useState(null);
	const [price, setPrice] = useState(null);
	const cancelRef = useRef();
	const { isOpen, onOpen, onClose } = useDisclosure();
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		const getUserDetails = async () => {
			try {
				const response = await GetUserInfo(data.RequestedBy);
				setName(response.data.message.name);
				setEmail(response.data.message.email);
				setPhone(response.data.message.phone);
				const packageData = await GetPackageById(data.packageId);
				setPrice(
					packageData.data.package[0].startingPrice *
					parseInt(data.numberOfPeople)
				);
			} catch (error) {
				console.log('error occured ', error);
			}
		};

		getUserDetails();
	}, []);

	const handleCancellation = async () => {
		setLoading(true);
		try {
			const res = await axios.patch(
				'https://backend.planmyleisure.com/package/update-request-package',
				{
					packageId: data._id,
					status: 'Cancelled',
				}
			);
			setLoading(false);
			onClose();
			console.log(res);
			changeState((prev) => !prev);
		} catch (error) {
			setLoading(false);
			console.log('error occuerred');
		}
	};

	return (
		<>
			<AlertDialog
				isOpen={isOpen}
				leastDestructiveRef={cancelRef}
				onClose={onClose}
			>
				<AlertDialogOverlay>
					<AlertDialogContent>
						<AlertDialogHeader fontSize='lg' fontWeight='bold'>
							Cancel Request
						</AlertDialogHeader>

						<AlertDialogBody>Are you sure?</AlertDialogBody>

						<AlertDialogFooter>
							<Button ref={cancelRef} onClick={onClose}>
								Cancel
							</Button>
							<Button
								colorScheme='red'
								onClick={handleCancellation}
								ml={3}
								isLoading={loading}
							>
								Confirm
							</Button>
						</AlertDialogFooter>
					</AlertDialogContent>
				</AlertDialogOverlay>
			</AlertDialog>
			<Tr>
				<Td textAlign={'center'}> {name === '' ? '-' : name} </Td>
				<Td textAlign={'center'}> {email === '' ? '-' : email}</Td>
				<Td textAlign={'center'}> {phone === '' ? '-' : phone}</Td>
				<Td textAlign={'center'}> {data.packageId ? data.packageId : '-'} </Td>
				<Td textAlign={'center'}>
					{
						console.log('paymentType', data)
					}
					{
						data.paymentStatus === 'Done'
							? price
							: '-'
						// : 
						// (data.paymentType
						// 		.split(',')[0]
						// 		.split(':')[1]
						// 		.split('-')
						// 		.filter((dataaaa) => {
						// 			return dataaaa.split('_').length === 2;
						// 		})
						// 		.map((val) => val.split('_')[0])
						// 		.join(', '))

					}
				</Td>
				<Td textAlign={'center'}>
					<CheckIcon
						h='25px'
						w='25px'
						cursor='pointer'
						p='5px'
						borderRadius={'5px'}
						bg='red.500'
						color='white'
						onClick={onOpen}
					/>
				</Td>
			</Tr>
		</>
	);
};

export default ConfirmCancellation;
