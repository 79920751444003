import React, { useState } from "react";
import LoginApi from "../api/LoginApi";
import LogoutApi from "../api/LogoutApi";
import { Box, Text, FormLabel, Input, Button, Image } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { AccessAuthContext } from "../context/authContext";
import logo from "../logo.png";

const Login = () => {
  const [email, setEmail] = useState("");
  const [checkEmail, setCheckEmail] = useState(false);
  const [password, setPassword] = useState("");
  const [checkPassword, setCheckPassword] = useState(false);
  const navigate = useNavigate();
  const { setLoginStatus, setToken } = AccessAuthContext();
  const [loading, setLoading] = useState(false);

  const handleForm = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await LoginApi(email, password);
      console.log(response);
      setLoginStatus(true);
      setToken(response.data.token);
      navigate("/dashboard");
      setTimeout(LogoutApi, 7200000); //auto logout user after 2hr from login
      setLoading(false);
    } catch (err) {
      setCheckEmail(true);
      setCheckPassword(true);
      setLoading(false);
      console.log("login error is : ", err);
    }
  };

  return (
    <Box w="100vw" h="100vh" position={"relative"} bg="rgba(243,245,249)">
      <Box
        h="90px"
        display={"flex"}
        justifyContent="start"
        pl={10}
        alignItems="center"
        borderBottom="1px solid rgba(0,0,0,.3)"
        boxShadow={"lg"}
        bg="rgb(55,61,71)"
      >
        <Image src={logo} h="100%" />
      </Box>

      <Box
        position={"absolute"}
        top="50%"
        left="50%"
        transform={"translate(-50%,-50%)"}
        w="600px"
        borderRadius={"2xl"}
        boxShadow="2xl"
        display={"flex"}
        flexDir="column"
        // bg='rgb(249,248,248)'
        bg="rgba(243,245,249)"
        overflow={"hidden"}
      >
        {/* heading */}
        <Text
          fontSize={35}
          fontWeight={500}
          color="white"
          textAlign={"center"}
          py={2}
          pl={5}
          bg="rgb(55,61,71)"
          display={"flex"}
          justifyContent="start"
          alignItems="center"
        >
          Admin Login
        </Text>
        <Box px={10} pt={10} display="flex" flexDir={"column"} pb={10}>
          <FormLabel>Email</FormLabel>
          <Input
            bg="white"
            isInvalid={checkEmail}
            type="email"
            placeholder="xyz@email.com"
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            mb={3}
          />
          <FormLabel>Password</FormLabel>
          <Input
            bg="white"
            isInvalid={checkPassword}
            type="password"
            placeholder="********"
            onChange={(e) => {
              setPassword(e.target.value);
            }}
          />
          <Button
            isLoading={loading}
            type="submit"
            bg="rgb(55,61,71)"
            w="100px"
            mx="auto"
            mt={5}
            color={"white"}
            onClick={handleForm}
            _hover={{
              background: "rgb(55,61,71)",
            }}
          >
            Login
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default Login;
