import { DeleteIcon, EditIcon } from '@chakra-ui/icons';
import {
	AlertDialog,
	AlertDialogBody,
	AlertDialogContent,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogOverlay,
	Box,
	Td,
	Tr,
	useDisclosure,
	Button,
} from '@chakra-ui/react';
import { useRef, useState } from 'react';
import deleteAgent from '../../api/deleteAgent';
import './agents.css';
import EditAgent from './EditAgent';

const SingleAgent = ({ data }) => {
	const [state, setState] = useState();
	const { isOpen, onOpen, onClose } = useDisclosure();
	const cancleRef = useRef();
	const [loading, setLoading] = useState(false);

	const handleDelete = async () => {
		setLoading(true);
		try {
			const res = await deleteAgent(data._id);
			console.log('res is res ', res);
			setLoading(false);
			onClose();
		} catch (error) {
			setLoading(false);
			onClose();
		}
	};

	return (
		<>
			<EditAgent state={state} setState={setState} data={data} />
			<AlertDialog
				isOpen={isOpen}
				onClose={onClose}
				leastDestructiveRef={cancleRef}
			>
				<AlertDialogOverlay>
					<AlertDialogContent>
						<AlertDialogHeader fontSize={'lg'} fontWeight={'bold'}>
							Delete Agent
						</AlertDialogHeader>
						<AlertDialogBody>
							Are you sure? You can't undo this action afterwards.
						</AlertDialogBody>
						<AlertDialogFooter>
							<Button ref={cancleRef} onClick={onClose}>
								Cancel
							</Button>
							<Button
								colorScheme='red'
								onClick={handleDelete}
								ml={3}
								isLoading={loading}
							>
								Delete
							</Button>
						</AlertDialogFooter>
					</AlertDialogContent>
				</AlertDialogOverlay>
			</AlertDialog>
			<Tr>
				<Td textAlign={'center'}>{data.firstName}</Td>
				<Td textAlign={'center'}>{data.lastName}</Td>
				<Td textAlign={'center'}>{data.email}</Td>
				<Td textAlign={'center'}>{data.mobileNumber}</Td>
				<Td textAlign={'center'}>
					<Box>
						{/* <i className='icofont-ui-delete' color='red'></i> */}
						<EditIcon
							cursor={'pointer'}
							onClick={() => {
								setState(true);
							}}
						/>
					</Box>
				</Td>
				<Td textAlign={'center'}>
					<DeleteIcon
						color='red'
						cursor={'pointer'}
						onClick={() => {
							onOpen();
						}}
					/>
				</Td>
			</Tr>
		</>
	);
};
export default SingleAgent;
