import axios from 'axios';

const LoginApi = (email, password) => {
	return axios.post('https://backend.planmyleisure.com/user/login', {
		email,
		password,
	});
};

export default LoginApi;
