import React, { useEffect, useState } from "react";
import {
  Box,
  Text,
  Button,
  Icon,
  Menu,
  MenuButton,
  MenuList,
  Image,
  MenuItem,
} from "@chakra-ui/react";
import logout from "../api/LogoutApi";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { CgProfile } from "react-icons/cg";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import logo from "../logo.png";

const Dashboard = () => {
  const navigate = useNavigate();
  const path = useLocation().pathname.split("/")[2];
  console.log("path is : ", path);

  return (
    <Box w="100vw" h="100vh" display={"flex"}>
      <Box w="18vw" flexShrink={0} bg="rgb(55,61,71)">
        <Image src={logo} mb="30px" />
        <Box
          display={"flex"}
          flexDir="column"
          alignItems={"center"}
          w="100%"
          gap="20px"
          pl="35px"
        >
          <Text
            bg={path === "" || path === undefined ? "" : "none"}
            color={
              path === "" || path === undefined
                ? "white"
                : "rgba(255,255,255,.5)"
            }
            w="100%"
            textAlign={"center"}
            cursor={"pointer"}
            onClick={() => {
              navigate("/dashboard/");
            }}
            display="flex"
            alignItems={"center"}
            gap="10px"
            fontSize={"18px"}
          >
            <i className="icofont-contacts" fontSize="18px"></i>
            <Text>Contact</Text>
          </Text>
          <Text
            w="100%"
            bg={path === "users" ? "none" : "none"}
            color={path === "users" ? "white" : "rgba(255,255,255,.5)"}
            cursor={"pointer"}
            textAlign="center"
            onClick={() => {
              navigate("/dashboard/users");
            }}
            display="flex"
            alignItems={"center"}
            gap="10px"
            fontSize={"18px"}
          >
            <i className="icofont-users-alt-3" fontSize="18px"></i>
            Users
          </Text>
          <Text
            w="100%"
            bg={path === "requests" ? "none" : "none"}
            color={path === "requests" ? "white" : "rgba(255,255,255,.5)"}
            cursor={"pointer"}
            textAlign="center"
            onClick={() => {
              navigate("/dashboard/requests");
            }}
            display="flex"
            alignItems={"center"}
            gap="10px"
            fontSize={"18px"}
          >
            <i className="icofont-external"></i>
            Bookings
          </Text>
          <Text
            w="100%"
            bg={path === "agents" ? "none" : "none"}
            color={path === "agents" ? "white" : "rgba(255,255,255,.5)"}
            cursor={"pointer"}
            textAlign="center"
            onClick={() => {
              navigate("/dashboard/agents");
            }}
            display="flex"
            alignItems={"center"}
            gap="10px"
            fontSize={"18px"}
          >
            <i className="icofont-users-alt-1"></i>
            Agents
          </Text>
          <Text
            w="100%"
            bg={path === "brands" ? "none" : "none"}
            color={path === "brands" ? "white" : "rgba(255,255,255,.5)"}
            cursor={"pointer"}
            textAlign="center"
            onClick={() => {
              navigate("/dashboard/brands");
            }}
            display="flex"
            alignItems={"center"}
            gap="10px"
            fontSize={"18px"}
          >
            <i className="icofont-bullhorn"></i>
            Brands
          </Text>
          <Text
            w="100%"
            bg={path === "packages" ? "none" : "none"}
            color={path === "packages" ? "white" : "rgba(255,255,255,.5)"}
            cursor={"pointer"}
            textAlign="center"
            onClick={() => {
              navigate("/dashboard/packages");
            }}
            display="flex"
            alignItems={"center"}
            gap="10px"
            fontSize={"18px"}
          >
            <i className="icofont-package"></i>
            Packages
          </Text>
          <Text
            w="100%"
            bg={path === "blog" ? "none" : "none"}
            color={path === "blog" ? "white" : "rgba(255,255,255,.5)"}
            cursor={"pointer"}
            textAlign="center"
            onClick={() => {
              navigate("/dashboard/blog");
            }}
            display="flex"
            alignItems={"center"}
            gap="10px"
            fontSize={"18px"}
          >
            <i className="icofont-blogger"></i>
            Blogs
          </Text>

          <Text
            w="100%"
            bg={path === "image-slider" ? "none" : "none"}
            color={path === "image-slider" ? "white" : "rgba(255,255,255,.5)"}
            cursor={"pointer"}
            textAlign="center"
            onClick={() => {
              navigate("/dashboard/image-slider");
            }}
            display="flex"
            alignItems={"center"}
            gap="10px"
            fontSize={"18px"}
          >
            <i className="icofont-ui-image"></i>
            Content
          </Text>
        </Box>
      </Box>
      <Box display={"flex"} flexDir="column" flexGrow={1} overflow="hidden">
        <Box
          h="10vh"
          flexShrink={0}
          display="flex"
          alignItems={"center"}
          px="20px"
          bg="rgba(243,245,249)"
        >
          <Text
            fontSize={"32px"}
            fontWeight={600}
            color="gray.600"
            textTransform={"capitalize"}
          >
            {path === "" ? "Contacts" : path === "requests" ? "Bookings" : path}
          </Text>
          <Box flexGrow={1}></Box>
          <Menu>
            <MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
              <Icon as={CgProfile} fontSize={20} />
            </MenuButton>
            <MenuList>
              <MenuItem>Profile</MenuItem>
              <MenuItem
                onClick={() => {
                  logout();
                }}
              >
                Logout
              </MenuItem>
            </MenuList>
          </Menu>
        </Box>
        <Box flexGrow={1} w="100%" bg="rgba(243,245,249)">
          <Outlet />
        </Box>
      </Box>
    </Box>
  );

  // return (
  // 	<Box
  // 		w='100vw'
  // 		h='100vh'
  // 		overflowX={'hidden'}
  // 		overflowY='hidden'
  // 		bg='#fefefe'
  // 	>
  // 		{/* heading */}
  // 		<Box
  // 			h='80px'
  // 			display={'flex'}
  // 			justifyContent={'space-between'}
  // 			alignItems={'center'}
  // 			px={10}
  // 			boxShadow={'rgba(0, 0, 0, 0.24) 0px 3px 8px'}
  // 			mb='5px'
  // 			bg='rgba(50, 186, 201,.8)'
  // 		>
  // 			<Image src={logo} h='100%' />
  // 			<Menu>
  // 				<MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
  // 					<Icon as={CgProfile} fontSize={20} />
  // 				</MenuButton>
  // 				<MenuList>
  // 					<MenuItem>Profile</MenuItem>
  // 					<MenuItem
  // 						onClick={() => {
  // 							logout();
  // 						}}
  // 					>
  // 						Logout
  // 					</MenuItem>
  // 				</MenuList>
  // 			</Menu>
  // 		</Box>
  // 		<Box w='100vw' h='calc(100vh - 80px)' display={'flex'}>
  // 			<Box
  // 				h={'98%'}
  // 				w={{ lg: '14vw' }}
  // 				display='flex'
  // 				flexDir={'column'}
  // 				alignItems='center'
  // 				gap={'30px'}
  // 				pl={0}
  // 				pt='10px'
  // 				borderRadius={'sm'}
  // 				boxShadow='rgba(0, 0, 0, 0.24) 0px 3px 8px'
  // 			>
  // 				<Text
  // 					py='10px'
  // 					px='20px'
  // 					bg={path === '' ? '#32bac9' : 'none'}
  // 					color={path === '' ? 'white' : 'black'}
  // 					borderRadius={'md'}
  // 					w='90%'
  // 					textAlign={'center'}
  // 					boxShadow={path === '' ? '2xl' : 'none'}
  // 					cursor={'pointer'}
  // 					requests
  // 					onClick={() => {
  // 						navigate('/dashboard/');
  // 					}}
  // 					display='flex'
  // 					alignItems={'center'}
  // 					justifyContent='start'
  // 					gap='10px'
  // 				>
  // 					<i className='icofont-contacts'></i>
  // 					<Text>Contact</Text>
  // 				</Text>
  // 				<Text
  // 					py='10px'
  // 					px='20px'
  // 					w='90%'
  // 					bg={path === 'requests' ? '#32bac9' : 'none'}
  // 					color={path === 'requests' ? 'white' : 'black'}
  // 					boxShadow={path === 'requests' ? '2xl' : 'none'}
  // 					borderRadius={'md'}
  // 					cursor={'pointer'}
  // 					textAlign='center'
  // 					onClick={() => {
  // 						navigate('/dashboard/requests');
  // 					}}
  // 					display='flex'
  // 					alignItems={'center'}
  // 					justifyContent='start'
  // 					gap='10px'
  // 				>
  // 					<i className='icofont-external'></i>
  // 					Requests
  // 				</Text>
  // 				<Text
  // 					py='10px'
  // 					px='20px'
  // 					w='90%'
  // 					bg={path === 'users' ? '#32bac9' : 'none'}
  // 					color={path === 'users' ? 'white' : 'black'}
  // 					boxShadow={path === 'users' ? '2xl' : 'none'}
  // 					borderRadius={'md'}
  // 					cursor={'pointer'}
  // 					textAlign='center'
  // 					onClick={() => {
  // 						navigate('/dashboard/users');
  // 					}}
  // 					display='flex'
  // 					alignItems={'center'}
  // 					justifyContent='start'
  // 					gap='10px'
  // 				>
  // 					<i className='icofont-users-alt-3'></i>
  // 					Users
  // 				</Text>
  // 				<Text
  // 					py='10px'
  // 					px='20px'
  // 					bg={path === 'agents' ? '#32bac9' : 'none'}
  // 					color={path === 'agents' ? 'white' : 'black'}
  // 					boxShadow={path === 'agents' ? '2xl' : 'none'}
  // 					borderRadius={'md'}
  // 					cursor={'pointer'}
  // 					textAlign='center'
  // 					w='90%'
  // 					onClick={() => {
  // 						navigate('/dashboard/agents');
  // 					}}
  // 					display='flex'
  // 					alignItems={'center'}
  // 					justifyContent='start'
  // 					gap='10px'
  // 				>
  // 					<i className='icofont-users-alt-1'></i>
  // 					Agents
  // 				</Text>
  // 				<Text
  // 					py='10px'
  // 					px='20px'
  // 					bg={path === 'brands' ? '#32bac9' : 'none'}
  // 					color={path === 'brands' ? 'white' : 'black'}
  // 					boxShadow={path === 'brands' ? '2xl' : 'none'}
  // 					borderRadius={'md'}
  // 					cursor={'pointer'}
  // 					textAlign='center'
  // 					w='90%'
  // 					onClick={() => {
  // 						navigate('/dashboard/brands');
  // 					}}
  // 					display='flex'
  // 					alignItems={'center'}
  // 					justifyContent='start'
  // 					gap='10px'
  // 				>
  // 					<i className='icofont-bullhorn'></i>
  // 					Brands
  // 				</Text>
  // 				<Text
  // 					py='10px'
  // 					px='20px'
  // 					w='90%'
  // 					bg={path === 'packages' ? '#32bac9' : 'none'}
  // 					color={path === 'packages' ? 'white' : 'black'}
  // 					boxShadow={path === 'packages' ? '2xl' : 'none'}
  // 					borderRadius={'md'}
  // 					cursor={'pointer'}
  // 					textAlign='center'
  // 					onClick={() => {
  // 						navigate('/dashboard/packages');
  // 					}}
  // 					display='flex'
  // 					alignItems={'center'}
  // 					justifyContent='start'
  // 					gap='10px'
  // 				>
  // 					<i className='icofont-package'></i>
  // 					Packages
  // 				</Text>
  // 				<Text
  // 					py='10px'
  // 					px='20px'
  // 					bg={path === 'blog' ? '#32bac9' : 'none'}
  // 					color={path === 'blog' ? 'white' : 'black'}
  // 					boxShadow={path === 'blog' ? '2xl' : 'none'}
  // 					borderRadius={'md'}
  // 					cursor={'pointer'}
  // 					textAlign='center'
  // 					w='90%'
  // 					onClick={() => {
  // 						navigate('/dashboard/blog');
  // 					}}
  // 					display='flex'
  // 					alignItems={'center'}
  // 					justifyContent='start'
  // 					gap='10px'
  // 				>
  // 					<i className='icofont-blogger'></i>
  // 					Blog
  // 				</Text>
  // 				<Text
  // 					py='10px'
  // 					px='20px'
  // 					bg={path === 'image-slider' ? '#32bac9' : 'none'}
  // 					color={path === 'image-slider' ? 'white' : 'black'}
  // 					boxShadow={path === 'image-slider' ? '2xl' : 'none'}
  // 					borderRadius={'md'}
  // 					cursor={'pointer'}
  // 					textAlign='center'
  // 					w='90%'
  // 					onClick={() => {
  // 						navigate('/dashboard/image-slider');
  // 					}}
  // 					display='flex'
  // 					alignItems={'center'}
  // 					justifyContent='start'
  // 					gap='10px'
  // 				>
  // 					<i className='icofont-ui-image'></i>
  // 					Image Slider
  // 				</Text>

  // 				<Box flexGrow={2}></Box>
  // 			</Box>
  // 			<Outlet />
  // 		</Box>
  // 	</Box>
  // );
};

export default Dashboard;
