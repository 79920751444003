import axios from 'axios';

const addToArchiveApi = (packageId, what) => {
	return axios.post(
		'https://backend.planmyleisure.com/package/add-to-archive',
		{
			packageId,
			isArchived: what,
		}
	);
};

export default addToArchiveApi;
