import axios from 'axios';

const GetPackageById = (id) => {
	console.log('getting package for id : ', id);
	return axios.get(
		`https://backend.planmyleisure.com/package/get-package/${id}`
	);
};

export default GetPackageById;
