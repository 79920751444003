import axios from "axios";

const CreateSinglePackageApi = (
  packageId,
  destination,
  packageTitle,
  category,
  packageDetail1,
  packageDetail2,
  packageDetail3,
  packageDetail4,
  bookingPeriod,
  stayPeriod,
  inclusion,
  startingPrice,
  imagelinklist,
  resorts,
  roomType,
  itinerary,
  flight,
  star,
  activity,
  transferVal
) => {
  let image = imagelinklist;

  console.log("booking period is :  ", resorts);
  return axios.post(
    "https://backend.planmyleisure.com/package/create-package",
    {
      packageId,
      packageTitle,
      category: category,
      packageDetail1,
      packageDetail2,
      packageDetail3,
      packageDetail4,
      bookingPeriod: {
        start: bookingPeriod.start,
        end: bookingPeriod.end,
      },
      stayPeriod: [stayPeriod.start, stayPeriod.end],
      inclusion,
      startingPrice,
      image,
      resorts: { values: resorts },
      roomType,
      itinerary,
      flightDetails: { details: flight },
      destination,
      star: parseInt(star),
      activity: activity,
      transferVal,
    }
  );
};
export default CreateSinglePackageApi;
