import { CloseIcon } from '@chakra-ui/icons';
import {
	Box,
	Modal,
	ModalContent,
	ModalOverlay,
	Text,
	useDisclosure,
} from '@chakra-ui/react';
import { useEffect } from 'react';

const ShowUserData = ({ data, state, changeState }) => {
	const { isOpen, onOpen, onClose } = useDisclosure();

	useEffect(() => {
		if (state) {
			onOpen();
		}
	}, [state]);

	return (
		<Modal size={'full'} isOpen={isOpen} onClose={onClose}>
			<ModalOverlay />
			<ModalContent
				w='100%'
				h='100%'
				bg='transparent'
				position={'relative'}
			>
				<Box
					w='400px'
					maxH={'90vh'}
					bg='#fff'
					position={'absolute'}
					top='50%'
					left='50%'
					transform='translate(-50%,-50%)'
					borderRadius={'10px'}
					py='20px'
					px='30px'
					overflow={'scroll'}
				>
					<Box
						display={'flex'}
						alignItems='center'
						justifyContent={'space-between'}
					>
						<Text fontSize={25} fontWeight={600}>
							User Details
						</Text>
						<CloseIcon
							cursor={'pointer'}
							onClick={() => {
								onClose();
								changeState(false);
							}}
						/>
					</Box>
					<Box
						pt={5}
						display='flex'
						flexDir={'column'}
						gap='20px'
						pl='10px'
					>
						<Text>Name : {data.name}</Text>
						<Text>Email : {data.email}</Text>
						<Text>
							Phone :{' '}
							{data.phone === undefined
								? 'undefined'
								: data.phone}
						</Text>
						<Text>
							DOB :{' '}
							{data.dob === undefined ? 'undefined' : data.dob}{' '}
						</Text>
						<Text>
							Address :{' '}
							{data.address === undefined
								? 'undefined'
								: `${data.address.houseNumber}, ${data.address.city}, ${data.address.state}, ${data.address.pincode}`}
						</Text>
					</Box>
				</Box>
			</ModalContent>
		</Modal>
	);
};

export default ShowUserData;
