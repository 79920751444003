import {
	Box,
	Text,
	TableContainer,
	Table,
	Thead,
	Tr,
	Th,
	Tbody,
	Td,
	AlertDialog,
	AlertDialogOverlay,
	AlertDialogContent,
	AlertDialogHeader,
	AlertDialogBody,
	AlertDialogFooter,
	Button,
	useDisclosure,
	useToast,
} from '@chakra-ui/react';
import { DeleteIcon, EditIcon, AddIcon } from '@chakra-ui/icons';
import { useEffect, useState, useRef } from 'react';
import GetBlogListApi from '../../../api/GetBlogListApi';
import DeleteBlogApi from '../../../api/DeleteBlogApi';
import { useNavigate } from 'react-router-dom';

const TableData = ({ data, setFetchData }) => {
	const [loading, setLoading] = useState(false);
	const { isOpen, onOpen, onClose } = useDisclosure();
	const da = new Date(data.createdAt);
	const cancelRef = useRef();
	const toast = useToast();
	const navigate = useNavigate();

	const deleteTheBlog = async () => {
		setLoading(true);

		try {
			const response = await DeleteBlogApi(data._id);
			console.log(response);
			setFetchData((prev) => !prev);
			toast({
				title: 'Blog Deleted Successfully!',
				status: 'success',
				isClosable: true,
			});
			setLoading(false);
			onClose();
		} catch (err) {
			console.log(err);
			toast({
				title: 'Something Went Wrong!',
				status: 'error',
				isClosable: true,
			});
			setLoading(false);
			onClose();
		}
	};

	return (
		<Tr>
			<Td textAlign={'center'}>{data.blogHeading}</Td>
			<Td textAlign={'center'}>
				{da.getDate()}
				{'/'}
				{da.getMonth()}
				{'/'}
				{da.getFullYear()}
			</Td>
			<Td textAlign={'center'}>
				<EditIcon
					cursor={'pointer'}
					onClick={() => {
						navigate('/dashboard/blog/edit', { state: data });
					}}
				/>
			</Td>
			<Td textAlign={'center'}>
				<DeleteIcon color='red' cursor={'pointer'} onClick={onOpen} />
				<AlertDialog
					isOpen={isOpen}
					leastDestructiveRef={cancelRef}
					onClose={onClose}
				>
					<AlertDialogOverlay>
						<AlertDialogContent>
							<AlertDialogHeader fontSize='lg' fontWeight='bold'>
								Delete Blog
							</AlertDialogHeader>

							<AlertDialogBody>
								Are you sure? You can't undo this action
								afterwards.
							</AlertDialogBody>

							<AlertDialogFooter>
								<Button ref={cancelRef} onClick={onClose}>
									Cancel
								</Button>
								<Button
									colorScheme='red'
									onClick={deleteTheBlog}
									ml={3}
									isLoading={loading}
								>
									Delete
								</Button>
							</AlertDialogFooter>
						</AlertDialogContent>
					</AlertDialogOverlay>
				</AlertDialog>
			</Td>
		</Tr>
	);
};

const PostList = () => {
	const [blogList, setBlogList] = useState([]);
	const [fetchData, setFetchData] = useState(false);
	const navigate = useNavigate();

	useEffect(() => {
		const getBlogList = async () => {
			try {
				const response = await GetBlogListApi();
				console.log('response : ', response.data);
				setBlogList(response.data);
			} catch (error) {
				console.log(error);
			}
		};
		getBlogList();
	}, [fetchData]);

	return (
		<>
			<Box
				// w={{ base: '100%', lg: '86vw' }}
				h={'89vh'}
				bg='white'
				mx={{ base: 1, lg: '10px' }}
				borderRadius='md'
				boxShadow={'rgba(0, 0, 0, 0.24) 0px 3px 8px'}
				overflowY='scroll'
				pl={5}
				pr={5}
				pt={5}
			>
				<Box
					display={'flex'}
					justifyContent='space-between'
					alignItems='center'
					mb={10}
				>
					<Text fontSize={30} fontWeight={500}></Text>
					<Button
						display={'flex'}
						gap={3}
						color='gray.600'
						onClick={() => {
							navigate('/dashboard/blog/new');
						}}
					>
						<AddIcon /> <Text>New</Text>
					</Button>
				</Box>
				<TableContainer>
					<Table variant={'striped'} overflow='scroll' mt={3}>
						<Thead>
							<Tr>
								<Th textAlign={'center'} fontSize={'1.1rem'}>
									Heading
								</Th>
								<Th textAlign={'center'} fontSize={'1.1rem'}>
									Published Date
								</Th>
								{/* edit */}
								<Th
									textAlign={'center'}
									fontSize={'1.1rem'}
								></Th>
								{/* delete */}
								<Th
									textAlign={'center'}
									fontSize={'1.1rem'}
								></Th>
							</Tr>
						</Thead>
						<Tbody>
							{blogList.map((data, index) => {
								return (
									<TableData
										data={data}
										setFetchData={setFetchData}
										key={index}
									/>
								);
							})}
						</Tbody>
					</Table>
				</TableContainer>
			</Box>
		</>
	);
};

export default PostList;
