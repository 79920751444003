import {
	Box,
	Button,
	Table,
	TableContainer,
	Tbody,
	Text,
	Th,
	Thead,
	Tr,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import getArchivePackagesApi from '../../api/getArchivePackagesApi';
import ArcPkg from './ArcPkg';

const ArchivedPackages = () => {
	const [packages, setPackages] = useState([]);
	const [state, setState] = useState(true);

	const getArcPkgs = async () => {
		try {
			const res = await getArchivePackagesApi();
			setPackages(res.data.package);
		} catch (error) {
			console.log('something went wrong');
		}
	};

	useEffect(() => {
		getArcPkgs();
	}, [state]);

	return (
		<>
			<Box
				borderRadius='0 15px 15px 15px'
				bg='white'
				overflowX='hidden'
				overflowY='scroll'
				h='calc(89vh - 50px)'
				pt={5}
			>
				<Box
					display={'flex'}
					justifyContent='space-between'
					alignItems='center'
					mb={10}
					mx={5}
				>
					<Text fontSize={30} fontWeight={500}></Text>
				</Box>

				<TableContainer variant={'striped'} overflow='scroll' mt={3}>
					<Table>
						<Thead>
							<Tr>
								<Th textAlign={'center'} fontSize={'1.1rem'}>
									Package Id
								</Th>
								<Th textAlign={'center'} fontSize={'1.1rem'}>
									Title
								</Th>
								<Th
									textAlign={'center'}
									fontSize={'1.1rem'}
								></Th>
								<Th
									textAlign={'center'}
									fontSize={'1.1rem'}
								></Th>
							</Tr>
						</Thead>
						{false ? (
							<></>
						) : (
							<Tbody>
								{packages.map((item, index) => {
									return (
										<ArcPkg
											item={item}
											key={index}
											changeState={setState}
										/>
									);
								})}
							</Tbody>
						)}
					</Table>
				</TableContainer>
			</Box>
		</>
	);
};

export default ArchivedPackages;
